import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import ConfirmClosePositionModal from '../modals/confirmClosePositionModal'
import { useTalentApi } from '../../store/mainContext'

const Container = styled.div`
  /* border: solid 1px red; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0px;
  margin-bottom: 10px;
`

const B = styled.div`
  text-align: center;
  margin-right: 20px;
  min-width: 55px;

  &:last-child {
    margin-right: 0px;
  }
`

const Label = styled.div`
  margin-top: 5px;
`

const PositionActions = ({ position, onChangeSuccess }) => {
  const [showConfirm, setShowConfirm] = useState(false)

  const history = useHistory()
  const api = useTalentApi()

  const handleClose = async () => {
    await api.patch(`positions/${position._id}`, {
      status: 'closed',
    })

    history.push(`/positions`)
  }

  const handleTooglePause = async () => {
    await api.patch(`positions/${position._id}`, {
      isPaused: !position.isPaused,
    })

    onChangeSuccess('isPaused', !position.isPaused)
  }

  return (
    <React.Fragment>
      <Container>
        <B>
          <Button
            color="red"
            circular
            icon="stop"
            onClick={() => setShowConfirm(true)}
          ></Button>
          <Label>Close</Label>
        </B>

        {!position.isPaused && (
          <B>
            <Button
              basic
              circular
              icon="pause"
              onClick={handleTooglePause}
            ></Button>
            <Label>Pause</Label>
          </B>
        )}

        {position.isPaused && (
          <B>
            <Button
              color="yellow"
              circular
              icon="play"
              onClick={handleTooglePause}
            ></Button>
            <Label>Resume</Label>
          </B>
        )}

        <B>
          <Button
            basic
            circular
            icon="mail"
            onClick={() => alert('Not ready yet. Sorry.')}
          ></Button>
          <Label>Mail</Label>
        </B>

        <B>
          <Button
            color="black"
            circular
            icon="plus"
            onClick={() => alert('Not ready yet. Sorry.')}
          ></Button>
          <Label>Log</Label>
        </B>
      </Container>

      <ConfirmClosePositionModal
        show={showConfirm}
        positionTitle={`${position.number} - ${position.title}`}
        onConfirm={handleClose}
        onCancel={() => setShowConfirm(false)}
      />
    </React.Fragment>
  )
}

export default PositionActions
