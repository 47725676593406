import React, { ReactNode, useState } from 'react'
import { Icon, SemanticICONS } from 'semantic-ui-react'
import styled from 'styled-components'

import Caret from '../caret'
import './sidepanel.css'

const SectionTitle = styled.div`
  margin: 25px 20px 10px 30px !important;
  display: flex;
  justify-content: space-between;
`

const Title = styled.h3`
  color: var(--primary) !important;
  font-weight: bold !important;
  cursor: pointer;
  margin: 0px;
`

const SubTitle = styled.h3`
  color: var(--primary) !important;
  margin: 0px;
`

const SidePanel = ({ children }: { children: ReactNode }) => (
  <table className="fx candidate-sidepanel">{children}</table>
)

const Divider = styled.div`
  border-bottom: dotted 1px var(--border-grey);
  height: 1px;
  margin: 10px 35px;
`

interface Props {
  subsection?: boolean
  expanded?: boolean
  children: ReactNode
  divider?: boolean
  title: string
  menu?: ReactNode
  icon: SemanticICONS
}

const Section = ({
  children,
  expanded = true,
  title,
  menu,
  icon,
  divider = true,
  subsection = false,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(expanded)

  return (
    <React.Fragment>
      <SectionTitle>
        {subsection ? (
          <SubTitle>
            <Icon name={icon} />
            &nbsp;{title}
          </SubTitle>
        ) : (
          <Title onClick={() => setIsExpanded(!isExpanded)}>
            <Caret show={isExpanded} />
            <Icon name={icon} />
            &nbsp;{title}
          </Title>
        )}

        {menu}
      </SectionTitle>

      {isExpanded && children}

      {divider && <Divider />}
    </React.Fragment>
  )
}

const Subtitle = ({
  subtitle,
  icon,
}: {
  subtitle: string
  icon: SemanticICONS
}) => (
  <SectionTitle>
    <Title>
      <Icon name={icon} />
      {subtitle}
    </Title>
  </SectionTitle>
)

export default SidePanel

export { Divider, Section, Subtitle }
