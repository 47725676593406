import useSWR from 'swr'

import { Candidate, candidateSchema } from '../types'
import { validateResponse } from '../utils'
import { useTalentApi } from '../store/mainContext'

export default function useCandidate(candidateId: Candidate['_id']) {
  const api = useTalentApi()
  return useSWR<Candidate, Error>(['candidate', candidateId], () =>
    api
      .get(`candidates/${candidateId}`)
      .then(validateResponse(candidateSchema)),
  )
}
