import React, { useEffect, useState } from 'react'
import { Button, Icon, Label } from 'semantic-ui-react'
import { useTalentApi } from '../../store/mainContext'

import CompanySelect from '../form/companySelect.tsx'
import TrEditButton from './trEditButton'
import TrCopyButton from './trCopyButton'

const TrCompanySelect = ({
  name,
  label,
  placeholder,
  value,
  onSuccess,
  url,
  render,

  divider,
  color,

  allowAdditions,
  fluid,
}) => {
  const api = useTalentApi()
  const [showEdit, setShowEdit] = useState(false)

  const [editMode, setEditMode] = useState(false)
  const [val, setVal] = useState(value)
  const [showAdd, setShowAdd] = useState(false)

  const [displayValue, setDisplayValue] = useState()

  const [isLoading, setIsLoading] = useState(false)

  const [, setCancelOnBlur] = useState(false)

  render = render || ((x) => x.text)

  const refreshDisplayValue = React.useCallback(
    async (newvalue) => {
      if (!newvalue) {
        setDisplayValue(null)
        return
      }

      if (Array.isArray(newvalue)) {
        const { data } = await api.get('companies')

        setDisplayValue(
          (newvalue || []).map((v) =>
            render((data.filter((d) => d._id === v) || [])[0] || {}),
          ),
        )

        return
      }

      const { data } = await api.get(`companies/${newvalue}`)
      setDisplayValue(render(data))
    },
    [api, render],
  )

  useEffect(() => {
    setVal(value)
    refreshDisplayValue(value)
  }, [refreshDisplayValue, value])

  const handleSave = async () => {
    if (val === value) {
      handleCancel()
      return
    }

    setCancelOnBlur(false)
    setIsLoading(true)

    const obj = {}
    obj[name] = val

    if (!val || val === '' || val.length === 0) {
      await api.delete(`${url}/${name}`)
    } else {
      await api.patch(url, obj)
    }

    await refreshDisplayValue(val)

    setIsLoading(false)
    setEditMode(false)
    onSuccess && onSuccess(name, val)
  }

  const handleCancel = () => {
    setCancelOnBlur(false)
    setVal(value)
    setEditMode(false)
    setIsLoading(false)
  }

  if (editMode) {
    return (
      <tr
      // className="solidBackground"
      // onBlur={(x) => !cancelOnBlur && handleCancel()}
      >
        <th className={divider && 'solidBorderTop'}>{label}</th>
        <td
          className={divider && 'solidBorderTop'}
          style={{
            paddingTop: 4,
            textAlign: `right`,
          }}
        >
          <CompanySelect
            allowAdditions={allowAdditions}
            onChange={(e, t) => setVal(t.value)}
            name={name}
            fluid={fluid}
            placeholder={placeholder}
            value={val}
            disabled={isLoading}
          />

          <div style={{ marginTop: 10 }}>
            <Button
              content="Cancel"
              size="tiny"
              onClick={handleCancel}
              style={{ padding: `9px 13px` }}
              disabled={isLoading}
              onMouseOver={() => setCancelOnBlur(true)}
              onMouseOut={() => setCancelOnBlur(false)}
            />

            <Button
              color="black"
              content="Save"
              size="tiny"
              style={{ padding: `9px 20px`, marginRight: 0 }}
              onClick={handleSave}
              loading={isLoading}
              onMouseOver={() => setCancelOnBlur(true)}
              onMouseOut={() => setCancelOnBlur(false)}
            />
          </div>
        </td>
      </tr>
    )
  }

  if (!val || val === '') {
    return (
      <tr
        onClick={() => setEditMode(true)}
        onMouseEnter={() => setShowAdd(true)}
        onMouseLeave={() => setShowAdd(false)}
        style={{ cursor: 'pointer' }}
      >
        <th>{label}</th>
        <td>
          {showAdd && (
            <span style={{ color: 'var(--primary)' }}>+ Add value</span>
          )}
        </td>
      </tr>
    )
  }

  if (Array.isArray(displayValue)) {
    return (
      <tr
        onMouseEnter={() => setShowEdit(true)}
        onMouseLeave={() => setShowEdit(false)}
      >
        <th>{label}</th>
        <td style={{ position: 'relative' }}>
          {displayValue.map((dv) => (
            <Label
              key={dv}
              color={color}
              style={{ margin: 0, marginBottom: 7, marginRight: 5 }}
            >
              {dv}
            </Label>
          ))}

          <TrCopyButton visible={showEdit} value={displayValue.join(', ')} />
          <TrEditButton onClick={() => setEditMode(true)} visible={showEdit} />
        </td>
      </tr>
    )
  }

  return (
    <tr
      onMouseEnter={() => setShowEdit(true)}
      onMouseLeave={() => setShowEdit(false)}
    >
      <th>{label}</th>
      <td style={{ position: 'relative' }}>
        <a rel="noreferrer" href={`/companies/${value}`} target="_blank">
          <Icon name="building outline" /> {displayValue}
        </a>
        <TrCopyButton visible={showEdit} value={displayValue} />
        <TrEditButton onClick={() => setEditMode(true)} visible={showEdit} />
      </td>
    </tr>
  )
}

export default TrCompanySelect
