import React, { useEffect, useState } from 'react'
// import { Icon, Label } from "semantic-ui-react";
import styled from 'styled-components'
import DataTable from './dataTable/dataTable'
import DateFormat from './dateFormat'
import PageLoader from './pageLoader'
import RemoteValue from './remoteValues/remoteValue'
import UserName from './remoteValues/userName'
import RequirePowers from './requirePowers'
import POWERS from '../powers'
import { useContractsApi, useTalentApi } from '../store/mainContext'
import { useUser } from '../pages/session/hooks'
import NotFound from './notFound'

const Container = styled.div`
  margin-right: 40px;
`

const Section = styled.div`
  margin-top: 40px;
`

const SectionTitle = styled.h2`
  margin-top: 0 !important;
  margin-bottom: 5px !important;
`

const Sub = styled.p`
  color: #666;
`

// const Title = styled.h2`
//   color: var(--grey) !important;
// `;

const TechLabel = styled.div`
  border: solid 1px var(--border-grey);
  background-color: var(--bg-grey);

  padding: 3px 7px;
  margin: 3px;
  float: left;
  border-radius: 4px;
  font-size: 12px;
`

const Dash = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 40px;
`

const Widget = styled.div`
  border: dotted 1px var(--border-grey);
  padding: 10px;
  min-width: 120px;
  border-radius: 4px;
  text-align: center;
  background-color: var(--bg-grey);
`

const ContractsDetail = ({ contractsFilter, submissionsFilter }) => {
  const user = useUser()
  const [isLoading, setIsLoading] = useState(true)
  const contractsApi = useContractsApi()
  const talentApi = useTalentApi()

  const [activeContracts, setActiveContracts] = useState([])
  const [pastContracts, setPastContracts] = useState([])

  const [submissions, setSubmissions] = useState([])

  const [widgets, setWidgets] = useState({})

  const getData = React.useCallback(async () => {
    const [{ data: allContracts }, { data: allSubmissions }] =
      await Promise.all([
        contractsApi.get(`contracts`, {
          params: contractsFilter,
        }),
        talentApi.get('submissions', {
          params: submissionsFilter,
        }),
      ])

    const [{ data: allCandidates }, { data: allDeals }] = await Promise.all([
      talentApi.get(`candidates/in`, {
        params: {
          _id: [
            ...allContracts.map((x) => x.candidateId),
            ...allSubmissions.map((x) => x.candidateId),
          ],
        },
      }),

      contractsApi.get(`deals/in`, {
        params: { contractId: allContracts.map((x) => x._id) },
      }),
    ])

    let allCustomerSOWs = []

    if (user.hasPower(POWERS.customerSOWs)) {
      const { data } = await contractsApi.get(`customerSOWs/in`, {
        params: { _id: allDeals.map((x) => x.customerSOWId) },
      })

      allCustomerSOWs = data
    }

    const rows = allContracts.map((contract) => {
      const candidate = allCandidates.find(
        (x) => x._id === contract.candidateId,
      )

      const lastDeal = allDeals
        .filter((x) => x.contractId === contract._id)
        .sort((a, b) => b.effectiveDate - a.effectiveDate)[0]

      const customerSOW = allCustomerSOWs.find(
        (x) => x._id === lastDeal.customerSOWId,
      )

      const row = {
        contractId: contract._id,
        startingDate: contract.startingDate,
        endDate: contract.endDate,

        candidateId: candidate && candidate._id,
        candidateName: candidate && candidate.name,
        candidateProfiles: candidate && (candidate.profiles || []),
        candidateYearsInTheRole: candidate && candidate.yearsInTheRole,
        candidateYearsInTheIndustry: candidate && candidate.yearsInTheIndustry,

        dealEffectiveDate: lastDeal && lastDeal.effectiveDate,
        dealEndDate: lastDeal && lastDeal.endDate,
        position: lastDeal && lastDeal.position,
        monthlySalary: lastDeal && lastDeal.monthlySalary,

        monthlyRate: customerSOW && customerSOW.monthlyRate,
      }

      return row
    })

    return {
      activeContracts: rows.filter((x) => !x.endDate),
      pastContracts: rows.filter((x) => x.endDate),
      submissions: allSubmissions.map((s) => {
        s.candidate = allCandidates.find((x) => x._id === s.candidateId)
        return s
      }),
    }
  }, [contractsApi, contractsFilter, talentApi, submissionsFilter, user])

  useEffect(() => {
    getData()
      .then(
        ({
          activeContracts: active,
          pastContracts: past,
          submissions: subs,
        }) => {
          setActiveContracts(active)
          setPastContracts(past)
          setSubmissions(subs)

          const teamSize = active.length
          const totalRate =
            active.length === 0
              ? 0
              : active.reduce((p, c) => p + c.monthlyRate, 0)

          const totalSalary =
            active.length === 0
              ? 0
              : active.reduce((p, c) => p + c.monthlySalary, 0)

          setWidgets({
            teamSize,
            totalRate,
            averageRate: totalRate / teamSize,

            totalDGM: totalRate - totalSalary,
            averageDGM: (totalRate - totalSalary) / teamSize,
          })
        },
      )
      .finally(() => setIsLoading(false))
  }, [getData])

  if (isLoading) {
    return <PageLoader />
  }

  if (
    activeContracts.length + pastContracts.length + submissions.length ===
    0
  ) {
    return (
      <Container>
        <NotFound
          style={{ marginTop: 60 }}
          message="No Contracts or Submissions yet."
          sub="What are you waiting for?"
        />
      </Container>
    )
  }

  return (
    <Container>
      <RequirePowers powers={[POWERS.customerSOWs]}>
        {widgets.teamSize > 0 && <Dashboard widgets={widgets} />}
      </RequirePowers>
      {activeContracts.length > 0 && (
        <Section>
          <SectionTitle>Active Contracts</SectionTitle>

          <Sub>Candidates that are currently working with this contact.</Sub>
          <CandidatesTable data={activeContracts} />
        </Section>
      )}

      {pastContracts.length > 0 && (
        <Section>
          <SectionTitle>Past Contracts</SectionTitle>

          <Sub>
            Candidates that worked with this contact but were canceled for some
            reason.
          </Sub>
          <CandidatesTable data={pastContracts} />
        </Section>
      )}

      {submissions.length > 0 && (
        <Section>
          <SectionTitle>Candidate Submissions</SectionTitle>
          <Sub>
            Candidates we presented to this contact. It includes candidates
            accepted and rejected too.
          </Sub>
          <SubmissionsTable data={submissions} />
        </Section>
      )}
    </Container>
  )
}

const Dashboard = ({ widgets }) => (
  <Dash>
    <Widget>
      <h1>{widgets.teamSize}</h1>
      <span>Team Size</span>
    </Widget>

    <Widget>
      <h1>$ {(widgets.totalRate / 1000).toFixed(1)}k</h1>
      <span>Total Rate</span>
    </Widget>

    <Widget>
      <h1>$ {(widgets.averageRate / 1000).toFixed(1)}k</h1>
      <span>Average Rate</span>
    </Widget>

    <Widget>
      <h1>$ {(widgets.totalDGM / 1000).toFixed(1)}k</h1>
      <span>Total DGM</span>
    </Widget>

    <Widget>
      <h1>$ {(widgets.averageDGM / 1000).toFixed(1)}k</h1>
      <span>Average DGM</span>
    </Widget>
  </Dash>
)

const SubmissionsTable = ({ data }) => (
  <DataTable>
    <thead>
      <tr>
        <DataTable.Th>Candidate</DataTable.Th>
        <DataTable.Th align="center">Profiles</DataTable.Th>
        <DataTable.Th>Experience</DataTable.Th>
        <DataTable.Th>Submitted As</DataTable.Th>
        <DataTable.Th>Submitted On</DataTable.Th>
        <RequirePowers powers={[POWERS.customerSOWs]}>
          <DataTable.Th>Rate</DataTable.Th>
        </RequirePowers>
        <DataTable.Th>Submitted By</DataTable.Th>
      </tr>
    </thead>

    <tbody>
      {data.map((s) => (
        <tr
          key={s._id}
          onClick={() => window.open(`/candidates/${s.candidate._id}`)}
        >
          <DataTable.Td>{s.candidate?.name}</DataTable.Td>
          <DataTable.Td>
            {' '}
            {(s.candidate?.profiles || []).map((p) => (
              <TechLabel key={p}>
                <RemoteValue collection="profiles" id={p} />
              </TechLabel>
            ))}
          </DataTable.Td>
          <DataTable.Td>
            {s.candidate?.yearsInTheIndustry} | {s.candidate?.yearsInTheRole}
          </DataTable.Td>
          <DataTable.Td>
            <RemoteValue collection="seniority" id={s.submittedAs} />
          </DataTable.Td>
          <DataTable.Td>
            <DateFormat date={s.submittedOn} />
          </DataTable.Td>
          <RequirePowers powers={[POWERS.customerSOWs]}>
            <DataTable.Td>$ {s.rate}</DataTable.Td>
          </RequirePowers>
          <DataTable.Td>
            <UserName id={s.submittedBy} />
          </DataTable.Td>
        </tr>
      ))}
    </tbody>
  </DataTable>
)

const CandidatesTable = ({ data }) => (
  <DataTable>
    <thead>
      <tr>
        <DataTable.Th>Candidate</DataTable.Th>
        <DataTable.Th>Profiles</DataTable.Th>
        <DataTable.Th align="center">Experience</DataTable.Th>
        <DataTable.Th align="center">Position</DataTable.Th>

        <DataTable.Th align="center">Starting Date</DataTable.Th>
        {data.some((x) => x.endDate) && (
          <DataTable.Th align="center">End Date</DataTable.Th>
        )}
        <DataTable.Th align="center">Fee</DataTable.Th>
        <RequirePowers powers={[POWERS.customerSOWs]}>
          <DataTable.Th align="center">Rate</DataTable.Th>
        </RequirePowers>
      </tr>
    </thead>

    <tbody>
      {data.map((x) => (
        <tr
          key={x.candidateId}
          onClick={() => window.open(`/candidates/${x.candidateId}`)}
        >
          <DataTable.Td>{x.candidateName}</DataTable.Td>

          <DataTable.Td>
            {(x.candidateProfiles || []).map((p) => (
              <TechLabel key={p}>
                <RemoteValue collection="profiles" id={p} />
              </TechLabel>
            ))}
          </DataTable.Td>

          <DataTable.Td align="center">
            {x.candidateYearsInTheIndustry} | {x.candidateYearsInTheRole}
          </DataTable.Td>

          <DataTable.Td align="center">{x.position}</DataTable.Td>

          <DataTable.Td align="center">
            <DateFormat date={x.startingDate} />
          </DataTable.Td>

          {x.endDate && (
            <DataTable.Td align="center">
              <DateFormat date={x.endDate} />
            </DataTable.Td>
          )}

          <DataTable.Td align="center">$ {x.monthlySalary}</DataTable.Td>

          <RequirePowers powers={[POWERS.customerSOWs]}>
            <DataTable.Td align="center">$ {x.monthlyRate}</DataTable.Td>
          </RequirePowers>
        </tr>
      ))}
    </tbody>
  </DataTable>
)

export default ContractsDetail
