import { addMonths, format, startOfMonth } from 'date-fns'
import { Button, Popup } from 'semantic-ui-react'
import { useHistory } from 'react-router'
import { AxiosError } from 'axios'
import { Helmet } from 'react-helmet'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { classNames, validateResponse } from '../../utils'
import { Payroll, payrollSchema } from '../../types'
import PagedTable, { Column } from '../../components/dataTable/pagedTable'
import { useContractsApi } from '../../store/mainContext'
import PayrollStatusLabel from '../../components/payrolls/PayrollStatusLabel'
import { useToasts } from '../../components/toasts/ToastsProvider'
import PageHeader from '../../components/pageHeader/pageHeader'

const columns: Column[] = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Status',
    align: 'center',
    width: '1%',
    field: 'status',
    render: (_, payroll: Payroll) => <PayrollStatusLabel payroll={payroll} />,
  },
  {
    title: 'Period',
    width: '1%',
    field: 'periodStart',
    align: 'center',
    render: (_, payroll: Payroll) => (
      <span className="whitespace-nowrap">
        {payroll.periodStart ? format(payroll.periodStart, 'dd/MM/yyyy') : ''}{' '}
        &mdash;{' '}
        {payroll.periodEnd ? format(payroll.periodEnd, 'dd/MM/yyyy') : ''}
      </span>
    ),
  },
  {
    title: 'Due Date',
    width: '1%',
    field: 'dueDate',
    align: 'center',
    render: (date: Payroll['dueDate']) =>
      date ? format(date, 'dd/MM/yyyy') : '',
  },
]

export default function Payrolls() {
  const contractsApi = useContractsApi()
  const { addToast } = useToasts()
  const history = useHistory()

  const [isCreating, setIsCreating] = React.useState(false)

  const { data, isLoading } = useSWR('payrolls', () =>
    contractsApi.get('payrolls').then(validateResponse(z.array(payrollSchema))),
  )

  const canCreatePayroll =
    !isLoading &&
    (data || []).every((payroll) => payroll.status === 'Completed')

  const handleNewPayroll = async () => {
    setIsCreating(true)

    const latestPayroll = (data || []).sort(
      (a, b) => b.periodEnd - a.periodEnd,
    )?.[0]

    const body = latestPayroll
      ? {
          date: format(
            startOfMonth(addMonths(latestPayroll.periodEnd, 1)),
            'yyyy-MM-dd',
          ),
        }
      : {}

    return contractsApi
      .post<Payroll>('/payrolls', body)
      .then((res) => history.push(`/payrolls/${res.data._id}`))
      .catch((err: AxiosError) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
      .finally(() => setIsCreating(false))
  }

  return (
    <>
      <Helmet>
        <title>Payrolls</title>
      </Helmet>

      <PageHeader
        title="Payrolls"
        sub="One screen to pay them all."
        breadcrumb={[{ text: 'Dashboard', link: '/' }, { text: 'Payrolls' }]}
        actions={
          <Popup
            content="Cannot create a new payroll while one is in progress"
            position="left center"
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
            offset={[0, -15]}
            disabled={canCreatePayroll}
            trigger={
              <div
                className={classNames(
                  !canCreatePayroll && 'cursor-not-allowed',
                )}
              >
                <Button
                  onClick={handleNewPayroll}
                  disabled={!canCreatePayroll || isCreating}
                  loading={isCreating}
                  content="New Payroll"
                  icon="add"
                  basic
                />
              </div>
            }
          />
        }
      />

      <div className="mx-14 mb-14">
        <PagedTable
          injectedApi={contractsApi}
          defaultSort="lastUpdateOn"
          collection="payrolls"
          onRowClick={(row: Payroll) => history.push(`/payrolls/${row._id}`)}
          columns={columns}
          footer
          pro={false}
        />
      </div>
    </>
  )
}
