import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { useTalentApi } from '../../store/mainContext'
import TrEditButton from './trEditButton'
import TrCopyButton from './trCopyButton'
import DateSelect from '../form/dateSelect'

const TrDate = ({
  name,
  label,
  placeholder = '',
  errors = [],
  value,
  onSuccess,
  onBlur = () => {},
  render = (val) => val && new Date(val).toLocaleDateString('en-US'),
  url,
  number = false,
}) => {
  const api = useTalentApi()
  const [showEdit, setShowEdit] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [, setCancelOnBlur] = useState(false)
  const [showAdd, setShowAdd] = useState(false)
  const [val, setVal] = useState(value)

  const handleSave = async () => {
    if (val === value) {
      handleCancel()
      return
    }

    setCancelOnBlur(false)
    setIsLoading(true)

    const obj = {}

    if (number) {
      obj[name] = Number(val)
    } else {
      obj[name] = val
    }

    await api.patch(url, obj)

    setIsLoading(false)
    setEditMode(false)
    setShowAdd(false)

    onSuccess && onSuccess(name, val)
  }

  const handleCancel = () => {
    setCancelOnBlur(false)
    setVal(value)
    setShowEdit(false)
    setEditMode(false)
    setIsLoading(false)
  }

  const handleKeyPress = async (e) => {
    if (e.which === 13) {
      e.preventDefault()
      await handleSave()
    }
  }

  if (editMode) {
    return (
      <tr>
        <th>{label}</th>
        <td
          style={{
            paddingTop: 4,
            textAlign: `right`,
          }}
        >
          <DateSelect
            name={name}
            error={
              (errors || []).indexOf(name) !== -1 && 'This field is required.'
            }
            pointing="top left"
            fluid
            onChange={(e, target) => setVal(target.value)}
            value={val && new Date(val)}
            disabled={isLoading}
            placeholder={placeholder}
            autoFocus={true}
            onBlur={onBlur}
            // style={{ width: "100%" }}

            onFocus={(e) => e.target.select()}
            onKeyPress={handleKeyPress}
          />

          <div style={{ marginTop: 10 }}>
            <Button
              content="Cancel"
              size="tiny"
              onClick={handleCancel}
              style={{ padding: `9px 13px` }}
              disabled={isLoading}
              onMouseOver={() => setCancelOnBlur(true)}
              onMouseOut={() => setCancelOnBlur(false)}
            />

            <Button
              color="black"
              content="Save"
              size="tiny"
              style={{ padding: `9px 20px`, marginRight: 0 }}
              onClick={handleSave}
              loading={isLoading}
              onMouseOver={() => setCancelOnBlur(true)}
              onMouseOut={() => setCancelOnBlur(false)}
            />
          </div>
        </td>
      </tr>
    )
  }

  if (!val || val === '') {
    return (
      <tr
        onClick={() => setEditMode(true)}
        onMouseEnter={() => setShowAdd(true)}
        onMouseLeave={() => setShowAdd(false)}
      >
        <th>{label}</th>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <td>{showAdd && <a>+ Add value</a>}&nbsp;</td>
      </tr>
    )
  }

  return (
    <React.Fragment>
      <tr
        onMouseEnter={() => setShowEdit(true)}
        onMouseLeave={() => setShowEdit(false)}
      >
        <th>{label}</th>
        <td style={{ position: 'relative' }}>
          {render(val)}

          <TrCopyButton
            visible={showEdit}
            value={val && new Date(val).toLocaleDateString('en-US')}
          />
          <TrEditButton onClick={() => setEditMode(true)} visible={showEdit} />
        </td>
      </tr>
    </React.Fragment>
  )
}

export default TrDate
