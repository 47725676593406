import { Button, Dropdown, DropdownItem, Icon } from 'semantic-ui-react'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import ConfirmRemoveModal from '../modals/confirmRemoveModal'
import { useTalentApi } from '../../store/mainContext'
import AddAccountModal from '../modals/addAccountModal'
import RequirePowers from '../requirePowers'
import POWERS from '../../powers'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
`

const B = styled.div`
  text-align: center;
  margin-right: 20px;
  min-width: 55px;

  &:last-child {
    margin-right: 0px;
  }
`

const Label = styled.div`
  margin-top: 5px;
`

const CandidateActions = ({ candidate, contract, onSuccess }) => {
  const [showConfirmRemove, setShowConfirmRemove] = useState(false)
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false)
  const [showConfirmRemoveAccount, setShowConfirmRemoveAccount] =
    useState(false)

  const history = useHistory()
  const api = useTalentApi()

  const handleRemove = async () => {
    await api.delete(`candidates/${candidate._id}`)

    setShowConfirmRemove(false)

    history.push(`/candidates`)
  }

  const handleAccountCreated = (data) => {
    setShowCreateAccountModal(false)
    onSuccess && onSuccess('southEmail', data.southEmail)
  }

  const handleRemoveAccount = async () => {
    await api.delete(`/user-accounts/${candidate._id}`)
    setShowConfirmRemoveAccount(false)
    onSuccess && onSuccess('southEmail', null)
  }

  return (
    <React.Fragment>
      <Container>
        <B>
          <Button
            color="red"
            circular
            icon="trash alternate outline"
            onClick={() => setShowConfirmRemove(true)}
          ></Button>
          <Label>Remove</Label>
        </B>

        <B>
          <Button
            basic
            circular
            icon="mail"
            onClick={() =>
              (document.location.href = `mailto:${candidate.email}`)
            }
          ></Button>
          <Label>Mail</Label>
        </B>

        <B>
          <Button
            basic
            circular
            icon="share"
            onClick={() => alert('Not ready yet. Sorry.')}
          ></Button>
          <Label>Share</Label>
        </B>

        <B>
          <Dropdown
            pointing="top right"
            icon={null}
            trigger={
              <Button
                color="black"
                circular
                icon="ellipsis horizontal"
              ></Button>
            }
          >
            <Dropdown.Menu>
              <RequirePowers powers={[POWERS.userAccounts]}>
                {!candidate.southEmail && (
                  <DropdownItem
                    disabled={
                      !candidate.email ||
                      candidate.southEmail ||
                      !contract ||
                      !contract.active
                    }
                    onClick={() => setShowCreateAccountModal(true)}
                  >
                    <Icon name="add" /> Create Account
                  </DropdownItem>
                )}

                {candidate.southEmail && (
                  <DropdownItem
                    disabled={contract && contract.active}
                    onClick={() => setShowConfirmRemoveAccount(true)}
                  >
                    <div style={{ color: 'var(--red)' }}>
                      <Icon name="trash alternate outline" /> Remove Account
                    </div>
                  </DropdownItem>
                )}
              </RequirePowers>
            </Dropdown.Menu>
          </Dropdown>

          <Label>More</Label>
        </B>
      </Container>

      <ConfirmRemoveModal
        onConfirm={handleRemove}
        onCancel={() => setShowConfirmRemove(false)}
        show={showConfirmRemove}
        validationValue={candidate.name}
        header="Hey... Removing this candidate?"
        content={
          <React.Fragment>
            <p>
              You are about to remove{' '}
              <b className="highlight">{candidate.name}</b>.{' '}
              <b>This action cannot be undone.</b> Are you completely sure?
            </p>
            <br />
            <p>
              <b>Enter candidate&apos;s name to confirm.</b>
            </p>
          </React.Fragment>
        }
      />

      <AddAccountModal
        show={showCreateAccountModal}
        candidate={candidate}
        onCancel={() => setShowCreateAccountModal(false)}
        onSuccess={handleAccountCreated}
      />

      <ConfirmRemoveModal
        show={showConfirmRemoveAccount}
        validationValue={candidate.southEmail}
        content={
          <React.Fragment>
            <p>
              You are about to remove{' '}
              <b className="highlight">{candidate.southEmail}</b>.{' '}
              <b>This action cannot be undone.</b> Are you completely sure?
            </p>
            <br />
            <p>
              <b>Enter email address to confirm.</b>
            </p>
          </React.Fragment>
        }
        header="Hey... Removing this account?"
        onCancel={() => setShowConfirmRemoveAccount(false)}
        onConfirm={handleRemoveAccount}
      />
    </React.Fragment>
  )
}

export default CandidateActions
