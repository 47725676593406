import { AxiosError } from 'axios'
import * as React from 'react'
import styled from 'styled-components'
import useSWR from 'swr'
import { z } from 'zod'

import { Account, Candidate, accountSchema } from '../../types'
import { validateResponse } from '../../utils'
import { useContractsApi } from '../../store/mainContext'
import { Button, Icon } from 'semantic-ui-react'
import PayoneerLogo from '../PayoneerLogo'
import UsaBankIcon from '../UsaBankIcon'

const Container = styled.div`
  margin: 0px 30px 30px 30px;
  border: dotted 1px #e9c776;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff8db;
  color: #b58105;
`

const Row = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: dotted 1px #e9c776;
  border-top: dotted 1px #e9c776;
  margin-bottom: -1px;
  padding: 5px;
`

interface Props {
  onReviewClick(account: Account): void
  candidateId: Candidate['_id']
}

export default function AccountsForReview(props: Props) {
  const { candidateId, onReviewClick } = props

  const api = useContractsApi()

  const { data, isLoading, error } = useSWR<Account[], AxiosError>(
    ['accounts', candidateId],
    () => {
      return api
        .get('accounts/pro', {
          headers: { 'astor-sortby': 'createdOn', 'astor-sortby-dir': '-1' },
          params: { candidateId },
        })
        .then(validateResponse(z.array(accountSchema)))
    },
  )

  const accountsAwaitingReview = (data || []).filter(
    ({ status }) => status === 'Waiting for approval',
  )

  if (isLoading || error || accountsAwaitingReview.length === 0) return null

  return (
    <Container>
      <p>
        {accountsAwaitingReview.length === 1 ? (
          <b>This candidate has an account for review:</b>
        ) : (
          <b>
            This candidate has {accountsAwaitingReview.length} accounts for
            review:
          </b>
        )}
      </p>

      {accountsAwaitingReview.map((account) => (
        <Row key={account._id}>
          <div className="relative top-px">
            {account.type === 'usa' && (
              <UsaBankIcon className="mr-0.5 inline-block w-5 text-slate-600" />
            )}
            {account.type === 'international' && (
              <Icon name="globe" color="grey" className="scale-110" />
            )}
            {account.type === 'payoneer' && (
              <PayoneerLogo className="inline-block w-24 align-text-bottom" />
            )}
            {account.type !== 'payoneer' && account.alias}
          </div>
          <Button size="mini" primary onClick={() => onReviewClick(account)}>
            Review
          </Button>
        </Row>
      ))}
    </Container>
  )
}
