import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import ContentEditable from 'react-contenteditable'

import { Button, Icon } from 'semantic-ui-react'
import { useTalentApi } from '../../../store/mainContext'

const ButtonBar = styled.div`
  margin-top: 20px;
  text-align: right;
`

const Editable = styled(ContentEditable)`
  border: solid 1px var(--border-grey);

  /* margin-top: 20px; */
  padding: 15px;
  font-size: 16px;
  line-height: 1.4em;
  padding-bottom: 30px;
  border-radius: 4px;
  min-height: 150px;

  &:focus {
    outline: none;
    border: solid 1px var(--primary);
    /* background-color: var(--primary-light); */
    box-shadow: var(--active-shadow);
  }
`

const NoteCreator = ({ relatedCollection, relatedId, onSuccess }) => {
  const defaultValue = 'Say something smart...'

  const [isSaving, setIsSaving] = useState(false)
  const [content, setContent] = useState(defaultValue)

  const [active, setActive] = useState('Note')

  const api = useTalentApi()

  useEffect(() => {
    setContent(
      window.localStorage.getItem(`event-editor.${relatedId}`) || defaultValue,
    )
  }, [relatedId])

  const handleCancel = () => {
    setContent(defaultValue)
    window.localStorage.removeItem(`event-editor.${relatedId}`)
  }

  const handleChange = (e) => {
    setContent(e.target.value)
    window.localStorage.setItem(`event-editor.${relatedId}`, e.target.value)
  }

  const handleFocus = () => {
    if (content === defaultValue) {
      setContent('')
    }
  }

  const handleOnBlur = (e) => {
    if (e.target.innerHTML === '') {
      setContent(defaultValue)
    }
  }

  const handleSave = async () => {
    setIsSaving(true)

    const { data } = await api.post('events', {
      description: content,
      title: active,
      type: 'note',
      relatedId,
      relatedCollection,
    })

    const { data: event } = await api.get(`events/${data._id}`)

    onSuccess && onSuccess(event)

    setContent(defaultValue)
    window.localStorage.removeItem(`event-editor.${relatedId}`)
    setIsSaving(false)
  }

  return (
    <React.Fragment>
      <Button.Group
        basic
        size="tiny"
        style={{ marginTop: 10, marginBottom: 12 }}
        className="w-full overflow-x-auto rounded bg-white [&::-webkit-scrollbar]:hidden"
        disabled={isSaving}
      >
        <Button
          active={active === 'Note'}
          disabled={isSaving}
          onClick={() => setActive('Note')}
        >
          Random <br />
          Note
        </Button>

        <Button
          active={active === 'Screening Notes'}
          disabled={isSaving}
          onClick={() => setActive('Screening Notes')}
        >
          Screening
          <br />
          Notes
        </Button>

        <Button
          active={active === 'Customer Interview'}
          disabled={isSaving}
          onClick={() => setActive('Customer Interview')}
        >
          Customer
          <br />
          Interview
        </Button>

        <Button
          active={active === 'Customer Feedback'}
          disabled={isSaving}
          onClick={() => setActive('Customer Feedback')}
        >
          Customer
          <br />
          Feedback
        </Button>

        <Button
          active={active === 'Candidate Feedback'}
          disabled={isSaving}
          onClick={() => setActive('Candidate Feedback')}
        >
          Candidate
          <br />
          Feedback
        </Button>

        <Button
          active={active === 'Email or Chat'}
          disabled={isSaving}
          onClick={() => setActive('Email or Chat')}
        >
          Email or
          <br /> Chat
        </Button>

        <Button
          active={active === 'Meeting'}
          disabled={isSaving}
          onClick={() => setActive('Meeting')}
        >
          Meeting
        </Button>
      </Button.Group>

      <Editable
        html={content}
        disabled={isSaving}
        onChange={handleChange}
        style={{ color: content === defaultValue && '#bbb' }}
        onFocus={handleFocus}
        onBlur={handleOnBlur}
      />

      <ButtonBar>
        <Button basic disabled={isSaving} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          color="black"
          onClick={handleSave}
          loading={isSaving}
          disabled={!content || content === '' || content === defaultValue}
        >
          <Icon name="save"></Icon>Save
        </Button>
      </ButtonBar>
    </React.Fragment>
  )
}

export default NoteCreator
