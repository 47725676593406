import useSWR from 'swr'

import { TimeoffRequest, timeoffRequestSchema } from '../types'
import { validateResponse } from '../utils'
import { useContractsApi } from '../store/mainContext'
import { AxiosError } from 'axios'

export default function useTimeoffRequest(requestId?: TimeoffRequest['_id']) {
  const api = useContractsApi()
  return useSWR<TimeoffRequest, AxiosError>(
    requestId && ['timeoff-request', requestId],
    () =>
      api
        .get(`/timeoff-requests/${requestId}`)
        .then(validateResponse(timeoffRequestSchema)),
    {
      // Deleted timeoff requests will flood feed items referencing them with errors
      shouldRetryOnError: (err) => err.response?.status !== 404,
    },
  )
}
