import { AxiosResponse } from 'axios'
import { fromZodError } from 'zod-validation-error'
import { ZodSchema, ZodTypeDef } from 'zod'

import { ApiDate } from './types'

type ValidDateValues = number | string | Date

const getDiffInDays = (from: ValidDateValues, to: ValidDateValues) => {
  const fromDate = new Date(new Date(from).toLocaleDateString('en-US'))
  const toDate = new Date(new Date(to).toLocaleDateString('en-US'))

  const result = (toDate.getTime() - fromDate.getTime()) / 1000 / 60 / 60 / 24

  return result
}

const isToday = (value: ValidDateValues) => {
  return getDiffInDays(new Date(), value) === 0
}

const isYesterday = (value: ValidDateValues) => {
  return getDiffInDays(new Date(), value) === -1
}

const isTomorrow = (value: ValidDateValues) => {
  return getDiffInDays(new Date(), value) === 1
}

const isBeforeToday = (value: ValidDateValues) => {
  return getDiffInDays(new Date(), value) < 0
}

const isAfterToday = (value: ValidDateValues) => {
  return getDiffInDays(new Date(), value) > 0
}

const timeout = (delay: number) => {
  return new Promise((resolve) => setTimeout(resolve, delay))
}

export function classNames(
  ...classes: (string | false | null | undefined)[]
): string {
  return classes.filter(Boolean).join(' ')
}

export function validateResponse<Output>(
  schema: ZodSchema<Output, ZodTypeDef, unknown>,
) {
  return function (response: AxiosResponse<Output>): Output {
    const result = schema.safeParse(response.data)
    if (result.success) {
      return result.data
    }
    console.warn(
      fromZodError(result.error, { prefix: `(URL: ${response.config.url})` }),
    )
    return response.data
  }
}

export function dateFromIsoString(isoString: ApiDate): Date {
  /**
   * We need to append a time string to the date, otherwise the Date object
   * will be considered UTC and when formatting back to ISO we might end up
   * on the previous or following day.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#sect2
   */
  return new Date(isoString + 'T00:00:00')
}

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format

export {
  currencyFormat,
  getDiffInDays,
  isToday,
  isYesterday,
  isTomorrow,
  isBeforeToday,
  isAfterToday,
  timeout,
}
