import { Button, Confirm, Modal } from 'semantic-ui-react'
import * as React from 'react'

import AccountDetails from './AccountDetails'
import { Account } from '../../types'

export type Dialog =
  | { type: 'approve-account-confirm'; account: Account }
  | { type: 'reject-account-confirm'; account: Account }
  | { type: 'account-details'; account: Account }
  | { type: 'review-account'; account: Account }

interface Props {
  onAccountUpdate(newData: Account): Promise<any>
  onApprove(account: Account): void
  onReject(account: Account): void
  onClose(): void
  currentDialog: Dialog | null
  isPending: boolean
}

export default function AccountModal(props: Props) {
  const {
    onAccountUpdate,
    currentDialog,
    isPending,
    onApprove,
    onReject,
    onClose,
  } = props

  return (
    <React.Fragment>
      <Modal
        closeOnDimmerClick={!isPending}
        closeOnEscape={!isPending}
        onClose={onClose}
        size="tiny"
        open={
          currentDialog?.type === 'account-details' ||
          currentDialog?.type === 'review-account'
        }
      >
        {currentDialog?.type === 'account-details' && (
          <Modal.Header>Account Details</Modal.Header>
        )}
        {currentDialog?.type === 'review-account' && (
          <Modal.Header>Review Account</Modal.Header>
        )}
        <Modal.Content className="-mt-4">
          {currentDialog?.account && (
            <AccountDetails account={currentDialog.account} />
          )}
        </Modal.Content>
        <Modal.Actions className="-mt-4 ">
          <Button content="Close" basic onClick={onClose} />
          {currentDialog?.type === 'review-account' && (
            <Button
              content="Approve"
              color="green"
              onClick={() => onApprove(currentDialog.account)}
            />
          )}
          {currentDialog?.type === 'review-account' && (
            <Button
              content="Reject"
              color="red"
              onClick={() => onReject(currentDialog.account)}
            />
          )}
        </Modal.Actions>
      </Modal>

      <Confirm
        header="Hey... approving the account?"
        content={
          <div className="px-6 pb-6">
            <div className="mb-2 mt-2 text-base">This action will:</div>
            <ul className="list-disc space-y-2 pl-6 text-base">
              <li>
                Mark the account as{' '}
                <strong className="ml-0.5 text-sm font-semibold uppercase text-green-600 [letter-spacing:1px]">
                  Approved
                </strong>
              </li>
              <li>
                Notify the candidate <strong>via e-mail</strong>
              </li>
              <li>
                Allow the candidate to select the account to receive payments
              </li>
            </ul>
          </div>
        }
        closeOnDimmerClick={!isPending}
        closeOnEscape={!isPending}
        onCancel={onClose}
        onConfirm={() =>
          currentDialog?.account
            ? onAccountUpdate({ ...currentDialog.account, status: 'Approved' })
            : null
        }
        open={currentDialog?.type === 'approve-account-confirm'}
        cancelButton={<Button disabled={isPending} content="Cancel" />}
        confirmButton={
          <Button
            disabled={isPending}
            loading={isPending}
            content="Sure, approve"
            primary={false}
            color="green"
          />
        }
        size="tiny"
      />

      <Confirm
        header="Hey... rejecting the account?"
        content={
          <div className="px-6 pb-6">
            <div className="mb-2 mt-2 text-base">This action will:</div>
            <ul className="list-disc space-y-2 pl-6 text-base">
              <li>
                Mark the account as{' '}
                <strong className="ml-0.5 text-sm font-semibold uppercase text-red-500 [letter-spacing:1px]">
                  Rejected
                </strong>
              </li>
              <li>
                Notify the candidate <strong>via e-mail</strong>
              </li>
              <li>
                Prevent the candidate from selecting the account to receive
                payments
              </li>
            </ul>
          </div>
        }
        closeOnDimmerClick={!isPending}
        closeOnEscape={!isPending}
        onCancel={onClose}
        onConfirm={() =>
          currentDialog?.account
            ? onAccountUpdate({ ...currentDialog.account, status: 'Rejected' })
            : null
        }
        open={currentDialog?.type === 'reject-account-confirm'}
        cancelButton={<Button disabled={isPending} content="Cancel" />}
        confirmButton={
          <Button
            disabled={isPending}
            loading={isPending}
            content="Sure, reject"
            primary={false}
            color="red"
          />
        }
        size="tiny"
      />
    </React.Fragment>
  )
}
