import React, { useEffect, useRef, useState } from 'react'
import { useSWRConfig } from 'swr'
import ContentEditable from 'react-contenteditable'
import styled, { css } from 'styled-components'
import {
  DropdownItem,
  Dropdown,
  Confirm,
  Button,
  Icon,
} from 'semantic-ui-react'

import { Candidate, Task, TimeoffRequest } from '../../types'
import { useContractsApi, useTalentApi } from '../../store/mainContext'
import TimeoffReviewConfirmationDialog from '../TimeoffReviewConfirmationDialog'
import TimeoffRequestDetails from './TimeoffRequestDetails'
import CheckButton from '../form/checkButton'
import { isToday } from '../../utils'
import DataSelect from '../form/dataSelect'
import DateSelect from '../form/dateSelect'
import UserSelect from '../form/userSelect'
import DateFormat from '../dateFormat'
import FormField from '../form/formField'
import UserName from '../remoteValues/userName'
import Period from '../period'
import Check from '../form/check'

type ContainerVariant = 'expired' | 'done' | 'today' | 'fulfill' | 'base'

const Container = styled.div<{
  variant: ContainerVariant
  isEditing: boolean
}>`
  position: relative;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;

  ${({ variant }) => {
    switch (variant) {
      case 'expired':
        return css`
          background-color: #fffcfc;
          border: dotted 1px #e1bfbf;

          header [contenteditable],
          h2,
          h3 b {
            color: var(--red) !important;
          }
        `
      case 'today':
        return css`
          border: dotted 1px var(--border-grey);
          background-color: #fcfcfc;

          h2,
          h3 b {
            color: var(--primary) !important;
          }
        `
      case 'done':
        return css`
          border: dotted 1px var(--border-primary);
          background-color: var(--primary-light);

          h2,
          h3 b {
            color: var(--primary) !important;
          }
        `
      case 'fulfill':
        return css`
          border: dotted 1px var(--border-grey);
          background-color: #fff;
        `
      default:
        return css`
          border: dotted 1px var(--border-grey);
          background-color: #fcfcfc;
        `
    }
  }}

  ${({ isEditing }) =>
    isEditing &&
    css`
      box-shadow: var(--active-shadow) !important;
      border-color: var(--primary) !important;
    `}
`

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;

  &[aria-disabled='true'] {
    cursor: auto;
  }
`

const Title = styled.header`
  margin: 0px;
  width: 100%;
  padding-left: 15px;
  padding-right: 55px;
  font-size: 18px !important;
`

const Details = styled.div`
  margin-top: 20px;
  font-size: 16px !important;
  line-height: 1.4em;
  padding: 0px 5px;
`

const Fields = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const Split = styled.div`
  border-top: dotted 1px var(--border-grey);
  margin-top: 20px;
  margin-left: -20px;
  margin-right: -20px;
`

const Sub = styled.div`
  color: #666;
  font-size: 14px;
  margin-top: 20px;
`

const DateLabel = styled.h3`
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  color: #666 !important;
  display: flex;
  align-items: center;
`

const Menu = styled.div`
  position: absolute;
  font-size: 15px;
  top: 0px;
  right: 0px;
  z-index: 90;
`

const ButtonBar = styled.div`
  margin-top: 20px;
  text-align: right;
  display: flex;
  align-items: baseline;
`

const Editor = styled(ContentEditable)`
  width: 100%;

  &:empty:before {
    content: 'Say something...';
    display: block;
    color: #999;
  }

  &[contenteditable='true'] {
    cursor: text;

    &:hover,
    &:focus {
      outline: none;
      outline-offset: 4px;
      border-radius: 0.5px;
    }
  }
`

const ViewMoreButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
`

interface Props {
  onChangeSuccess(): void
  disabled?: boolean
  onDone(openModal?: boolean): void
  task: Task
}

// TODO: Este componente esta malisimo, quedo muy complejo, refactorizar.
const PendingTask = ({
  onChangeSuccess,
  disabled = false,
  onDone,
  task,
}: Props) => {
  const { mutate } = useSWRConfig()
  const editor = useRef<ContentEditable>(null)
  const api = useTalentApi()
  const contractsApi = useContractsApi()

  const [currentTask, setCurrentTask] = useState({ ...task })

  const [isCollapsed, setIsCollapsed] = useState(true)
  const [editMode, setEditMode] = useState(false)

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)

  const [isSaving, setIsSaving] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const [markingDone, setMarkingDone] = useState(false)

  const [fullfilMode, setFulfillMode] = useState(false)

  const [mode, setMode] = useState<'fulfill' | 'approve' | 'reject'>('fulfill')

  const collapse = !editMode && !fullfilMode && isCollapsed

  let containerVariant: ContainerVariant = 'base'

  if (isToday(task.dueDate)) {
    containerVariant = 'today'
  } else if (task.dueDate < Date.now()) {
    containerVariant = 'expired'
  }
  if (fullfilMode) {
    containerVariant = 'fulfill'
  }
  if (markingDone) {
    containerVariant = 'done'
  }

  useEffect(() => {
    // TODO: not sure why this is needed
    const doneNoteInLocalStorage = window.localStorage.getItem(
      `task-doneNote-${currentTask._id}`,
    )
    if (doneNoteInLocalStorage) {
      setCurrentTask({ ...task, doneNote: doneNoteInLocalStorage })
    }

    // TODO: not sure why this is needed
    const candidateNoteInLocalStorage = window.localStorage.getItem(
      `task-candidateNote-${currentTask._id}`,
    )
    if (candidateNoteInLocalStorage) {
      setCurrentTask({ ...task, candidateNote: candidateNoteInLocalStorage })
    }

    if (candidateNoteInLocalStorage || doneNoteInLocalStorage) return

    setCurrentTask({ ...task })
  }, [task, currentTask._id])

  useEffect(() => {
    if (editor && editor.current) {
      editor.current.el.current.focus()
    }
  }, [editMode, fullfilMode])

  useEffect(() => {
    currentTask.doneNote &&
      window.localStorage.setItem(
        `task-doneNote-${currentTask._id}`,
        currentTask.doneNote,
      )
    currentTask.candidateNote &&
      window.localStorage.setItem(
        `task-candidateNote-${currentTask._id}`,
        currentTask.candidateNote,
      )
  }, [currentTask.doneNote, currentTask.candidateNote, currentTask._id])

  const handleCancel = () => {
    setCurrentTask({ ...task })
    setEditMode(false)
    setFulfillMode(false)
  }

  const handleSave = async () => {
    const {
      requireDoneNote,
      assignedToId,
      relatedId,
      subject,
      dueDate,
      details,
    } = currentTask

    setIsSaving(true)
    await api.patch(`tasks/${task._id}`, {
      requireDoneNote,
      assignedToId,
      relatedId,
      subject,
      dueDate,
      details,
    })
    setIsSaving(false)
    onChangeSuccess && onChangeSuccess()
    setEditMode(false)
  }

  const handleConfirmRemove = async () => {
    setIsRemoving(true)
    await api.delete(`tasks/${task._id}`)

    onChangeSuccess && onChangeSuccess()

    setIsRemoving(false)
    setShowConfirmDeleteModal(false)
  }

  const timeout = (delay: number) => {
    return new Promise((resolve) => setTimeout(resolve, delay))
  }

  const handleMarkAsDone = async () => {
    if (currentTask.requireDoneNote) {
      setFulfillMode((prev) => !prev)
      return
    }

    await markAsDone()
  }

  const handleMarkAsDoneWithNote = () => markAsDone()

  const handlePtoReview = (status: TimeoffRequest['status']) => {
    if (!currentTask.timeOffRequestId) return
    setMarkingDone(true)
    setCurrentTask({ ...currentTask, isDone: true })

    contractsApi
      .patch(`timeoff-requests/${currentTask.timeOffRequestId}`, {
        candidateNote: currentTask.candidateNote,
        doneNote: currentTask.doneNote,
        status,
      })
      // give some time to the API to update the related task status behind the scenes
      .then(() => timeout(2000))
      .then(() =>
        mutate((key) => Array.isArray(key) && key[0] === 'timeoff-requests'),
      )
      .then(() => onDone(false))
      .then(() => {
        setMode('fulfill')
        setFulfillMode(false)
        setMarkingDone(false)
      })
  }

  const markAsDone = async () => {
    setMarkingDone(true)

    setCurrentTask({ ...currentTask, isDone: true })
    await api.patch(`tasks/${currentTask._id}`, {
      isDone: true,
      doneNote: currentTask.doneNote,
    })

    await timeout(500)

    onDone && onDone()
  }

  const canMarkAsDone =
    currentTask.dueDate &&
    currentTask.assignedToId &&
    currentTask.doneNote &&
    currentTask.doneNote.length >= 4

  return (
    <React.Fragment>
      <Container isEditing={editMode || fullfilMode} variant={containerVariant}>
        <Header
          aria-disabled={editMode}
          onClick={() => !editMode && setIsCollapsed(!isCollapsed)}
        >
          <div>
            <CheckButton
              onChange={handleMarkAsDone}
              disabled={markingDone || editMode}
              value={currentTask.isDone || fullfilMode}
            />
          </div>

          <Title>
            <Editor
              html={currentTask.subject}
              disabled={!editMode}
              onChange={(e) =>
                setCurrentTask((t) => ({ ...t, subject: e.target.value }))
              }
            />
            {!editMode && (
              <DateLabel>
                <b>
                  <Period date={currentTask.dueDate} />
                </b>
                &nbsp;-&nbsp;
                <DateFormat date={currentTask.dueDate} />
                &nbsp;-&nbsp;
                <UserName id={currentTask.assignedToId} short height={20} />
              </DateLabel>
            )}
          </Title>

          {!disabled && !editMode && !fullfilMode && (
            <Menu>
              <ViewMoreButton>
                <Icon name={`chevron ${isCollapsed ? 'down' : 'up'}`} />
              </ViewMoreButton>
              <Dropdown
                className="-mr-2 ml-2 pr-2"
                direction="left"
                pointing="top left"
                icon="ellipsis horizontal"
              >
                <Dropdown.Menu>
                  <DropdownItem onClick={() => setEditMode(true)}>
                    Edit
                  </DropdownItem>
                  {currentTask.type !== 'pto-review' && (
                    <DropdownItem
                      onClick={() => setShowConfirmDeleteModal(true)}
                    >
                      <span className="text-red-600">Remove</span>
                    </DropdownItem>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Menu>
          )}
        </Header>

        {!collapse && (
          <React.Fragment>
            <Split />
            <Details>
              {(currentTask.type === 'pto-review' ||
                currentTask.type === 'pto-cancel') &&
              currentTask.timeOffRequestId ? (
                <TimeoffRequestDetails
                  requestId={currentTask.timeOffRequestId}
                />
              ) : (
                <Editor
                  html={currentTask.details || ''}
                  disabled={!editMode}
                  ref={editor}
                  onChange={(e) =>
                    setCurrentTask((t) => ({ ...t, details: e.target.value }))
                  }
                  style={{ minHeight: editMode && 100 }}
                />
              )}

              {!editMode && (
                <Sub>
                  Task created by{' '}
                  <b>
                    <UserName id={currentTask.createdBy} />
                  </b>{' '}
                  on <DateFormat date={currentTask.createdOn} hours />
                </Sub>
              )}

              {currentTask.type === 'pto-review' &&
                !currentTask.isDone &&
                !editMode &&
                !markingDone &&
                !fullfilMode && (
                  <div className="mt-4">
                    <Button basic onClick={handleMarkAsDone}>
                      Review this request
                    </Button>
                  </div>
                )}
            </Details>

            {editMode && (
              <React.Fragment>
                <Split />
                <div style={{ marginTop: 20 }}></div>

                {currentTask.relatedCollection === 'candidates' && (
                  <Fields>
                    <FormField label="Candidate">
                      <DataSelect
                        name="relatedId"
                        search
                        placeholder="Candidate..."
                        url="candidates"
                        fluid
                        onChange={(_: any, target: HTMLSelectElement) =>
                          setCurrentTask({
                            ...currentTask,
                            relatedId: target.value,
                          })
                        }
                        render={(x: Candidate) => `${x.name} (${x.email})`}
                        value={currentTask.relatedId}
                        key={currentTask.relatedId}
                      />
                    </FormField>
                  </Fields>
                )}

                <Fields>
                  <FormField label="Assigned To">
                    <UserSelect
                      name="assignedToId"
                      search
                      placeholder="Assigned To..."
                      fluid={true}
                      onChange={(userId) =>
                        setCurrentTask({ ...currentTask, assignedToId: userId })
                      }
                      value={currentTask.assignedToId}
                      key={currentTask.assignedToId}
                    />
                  </FormField>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <FormField label="Due Date *">
                    <DateSelect
                      name="dueDate"
                      pointing="top left"
                      fluid
                      onChange={(_: any, target: HTMLSelectElement) =>
                        setCurrentTask({
                          ...currentTask,
                          dueDate: parseInt(target.value, 10),
                        })
                      }
                      value={
                        currentTask.dueDate && new Date(currentTask.dueDate)
                      }
                      autocomplete="off"
                    />
                  </FormField>
                </Fields>

                <Fields>
                  {currentTask.type !== 'pto-review' ? (
                    <Check
                      text="Require note for completion"
                      value={currentTask.requireDoneNote}
                      onChange={() =>
                        setCurrentTask({
                          ...currentTask,
                          requireDoneNote: !currentTask.requireDoneNote,
                        })
                      }
                    />
                  ) : (
                    <div className="grow" />
                  )}

                  <ButtonBar className="ml-2">
                    <Button basic disabled={isSaving} onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button
                      style={{ display: 'flex' }}
                      color="black"
                      onClick={handleSave}
                      loading={isSaving}
                      disabled={
                        !currentTask.dueDate || !currentTask.assignedToId
                      }
                    >
                      <Icon name="save"></Icon>Save
                    </Button>
                  </ButtonBar>
                </Fields>
              </React.Fragment>
            )}

            {fullfilMode && (
              <React.Fragment>
                <Split />
                <Details>
                  {mode === 'fulfill' && (
                    <>
                      <h3>
                        <Icon name="clipboard outline" />
                        <b>
                          {currentTask.type === 'pto-review'
                            ? 'PTO Review Notes'
                            : 'Done Notes'}
                        </b>
                      </h3>
                      <Sub style={{ marginTop: 0, marginBottom: 10 }}>
                        Provide a note to mark this task as done:
                      </Sub>
                      <Editor
                        html={currentTask.doneNote || ''}
                        onChange={(e) =>
                          setCurrentTask((t) => ({
                            ...t,
                            doneNote: e.target.value,
                          }))
                        }
                        style={{ minHeight: (editMode || fullfilMode) && 100 }}
                        disabled={markingDone}
                        autoFocus
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                      />
                    </>
                  )}
                </Details>

                <ButtonBar>
                  <Button basic disabled={markingDone} onClick={handleCancel}>
                    Cancel
                  </Button>

                  {currentTask.type === 'pto-review' && (
                    <>
                      <span style={{ flexGrow: 1 }} />
                      {mode === 'fulfill' && (
                        <>
                          <Button
                            disabled={!canMarkAsDone}
                            onClick={() => setMode('approve')}
                            color="green"
                          >
                            Approve
                          </Button>
                          <Button
                            disabled={!canMarkAsDone}
                            onClick={() => setMode('reject')}
                            color="red"
                          >
                            Reject
                          </Button>
                        </>
                      )}
                    </>
                  )}

                  {currentTask.type !== 'pto-review' && (
                    <Button
                      color="black"
                      onClick={handleMarkAsDoneWithNote}
                      loading={markingDone}
                      disabled={!canMarkAsDone || markingDone}
                    >
                      <Icon name="check"></Icon>Save As Done
                    </Button>
                  )}
                </ButtonBar>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Container>

      <Confirm
        open={showConfirmDeleteModal}
        closeOnDimmerClick={true}
        onCancel={() => setShowConfirmDeleteModal(false)}
        onConfirm={handleConfirmRemove}
        content={
          <div style={{ padding: 20 }}>
            <p>
              You are about to remove this task.{' '}
              <b>This action cannot be undone.</b>
            </p>
            <p>Do you want to continue?</p>
          </div>
        }
        header="Hey... removing the task?"
        confirmButton={
          <Button primary={false} color="red" loading={isRemoving}>
            Sure, remove.
          </Button>
        }
      />

      {mode !== 'fulfill' && (
        // TODO maybe refactor this out and share a single instance for all list items
        <TimeoffReviewConfirmationDialog
          onConfirm={() =>
            handlePtoReview(mode === 'approve' ? 'Approved' : 'Rejected')
          }
          onCandidateNoteChange={(newValue) =>
            setCurrentTask((t) => ({ ...t, candidateNote: newValue }))
          }
          onCancel={() => setMode('fulfill')}
          isPending={markingDone}
          open
          task={currentTask}
          mode={mode}
        />
      )}
    </React.Fragment>
  )
}

export default PendingTask
