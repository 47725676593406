import { AxiosError } from 'axios'
import useSWR from 'swr'
import { z } from 'zod'

import {
  timeoffCoverageSchema,
  TimeoffCoverage,
  TimeoffRequest,
} from '../types'
import { validateResponse } from '../utils'
import { useContractsApi } from '../store/mainContext'

export default function useTimeoffCoverage(requestId?: TimeoffRequest['_id']) {
  const api = useContractsApi()
  return useSWR<TimeoffCoverage[], AxiosError>(
    requestId && ['timeoff-coverage', requestId],
    () =>
      api
        .get(`/timeoff-requests/${requestId}/summary`)
        .then(validateResponse(z.array(timeoffCoverageSchema))),
    {
      // Deleted timeoff requests will flood feed items referencing them with errors
      shouldRetryOnError: (err) =>
        err.response?.status !== 404 && err.response?.status !== 400,
    },
  )
}
