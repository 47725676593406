import useSWR, { useSWRConfig } from 'swr'
import { Redirect, useParams } from 'react-router-dom'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Menu } from 'semantic-ui-react'
import { z } from 'zod'

import {
  invoiceDetailSchema,
  invoiceSchema,
  InvoiceDetail,
  Invoice,
} from '../../types'
import { validateResponse } from '../../utils'
import { useContractsApi } from '../../store/mainContext'
import InvoiceEditor from '../../components/invoices/InvoiceEditor'
import { useToasts } from '../../components/toasts/ToastsProvider'
import PageLoader from '../../components/pageLoader'
import ChangeLog from '../../components/changeLog'
import Feed from '../../components/feeds/feed'

type Tab = 'feed' | 'changelog'

const InvoicePage = () => {
  const { invoiceId } = useParams<{ invoiceId?: string }>()

  if (!invoiceId) {
    throw new Error('`invoiceId` param must be present in the URL.')
  }

  const { mutate } = useSWRConfig()

  const [selectedTab, setSelectedTab] = useState<Tab>('feed')
  const { addToast } = useToasts()
  const api = useContractsApi()

  const invoice = useSWR<Invoice>(['invoice', invoiceId], () =>
    api.get(`invoices/${invoiceId}`).then(validateResponse(invoiceSchema)),
  )

  const details = useSWR<InvoiceDetail[]>(['invoice-details', invoiceId], () =>
    api
      .get(`invoice-details`, { params: { invoiceId } })
      .then(validateResponse(z.array(invoiceDetailSchema))),
  )

  const refreshChangelog = () => mutate(['changelog', 'invoice', invoiceId])

  if (invoice.isLoading || details.isLoading) {
    return <PageLoader />
  }

  if (!invoice.data) {
    addToast('Invoice not found', { variant: 'danger' })
    return <Redirect to="/invoices" />
  }

  if (!details.data) {
    addToast('Invoice details not found', { variant: 'danger' })
    return <Redirect to="/invoices" />
  }

  return (
    <div className="flex h-screen justify-between bg-slate-100">
      <Helmet>
        <title>
          {invoice.data.number
            ? `${invoice.data.number} - Invoice`
            : 'New Invoice'}
        </title>
      </Helmet>

      <div className="sticky top-0 grow overflow-y-auto px-8 [&::-webkit-scrollbar]:hidden">
        <InvoiceEditor
          onDetailsSuccess={() => {
            refreshChangelog()
            return details.mutate()
          }}
          onSuccess={() => {
            refreshChangelog()
            return invoice.mutate()
          }}
          details={details.data}
          invoice={invoice.data}
        />
      </div>

      <div className="w-full min-w-[300px] max-w-[500px] shrink-0 overflow-y-auto border-l bg-white p-6 [&::-webkit-scrollbar]:hidden">
        <Menu tabular>
          <Menu.Item
            icon="feed"
            name="Feed"
            active={selectedTab === 'feed'}
            onClick={() => setSelectedTab('feed')}
          />
          <Menu.Item
            icon="history"
            name="Change Log"
            active={selectedTab === 'changelog'}
            onClick={() => setSelectedTab('changelog')}
          />
        </Menu>
        {selectedTab === 'feed' && (
          <Feed
            relatedCollection="invoices"
            relatedId={invoiceId}
            showEditor={false}
          />
        )}
        {selectedTab === 'changelog' && (
          <ChangeLog entityName="invoice" injectedApi={api} id={invoiceId} />
        )}
      </div>
    </div>
  )
}

export default InvoicePage
