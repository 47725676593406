import {
  ExclamationCircleIcon,
  CalendarDaysIcon,
  ArrowRightIcon,
} from '@heroicons/react/24/outline'
import isWithinInterval from 'date-fns/isWithinInterval'
import startOfToday from 'date-fns/startOfToday'
import addYears from 'date-fns/addYears'
import subDays from 'date-fns/subDays'
import format from 'date-fns/format'
import { Loader } from 'semantic-ui-react'
import * as React from 'react'

import useTimeoffSummary from '../../hooks/useTimeoffSummary'
import { classNames } from '../../utils'
import { Candidate } from '../../types'

interface Props {
  candidateId: Candidate['_id']
}

export default function TimeoffHistory(props: Props) {
  const summary = useTimeoffSummary(props.candidateId)
  return (
    <div>
      {summary.data && (
        <ol className="ml-2 mt-4 space-y-3 lg:space-y-4">
          {summary.data.map((period, i) => {
            const isCurrentPeriod = isWithinInterval(startOfToday(), {
              start: period.start,
              end: period.end,
            })
            const previousPeriod = summary.data?.[i + 1]

            /**
             * Because of how the backend calculates the currently accrued days
             * the `end` property is populated with the current date during the
             * the first period, instead the actual period end.
             */
            const actualPeriodEnd =
              isCurrentPeriod && period.year === 1
                ? subDays(addYears(period.start, 1), 1)
                : period.end

            return (
              <li
                className="rounded-xl bg-slate-100 px-4 py-4"
                key={period.start.toString()}
              >
                <h2 className="-mx-4 flex items-baseline gap-1 border-b px-4 pb-2 text-sm">
                  <div className="flex grow items-baseline gap-2">
                    <span className="whitespace-nowrap text-sm font-semibold text-slate-500">
                      Period {period.year}
                    </span>{' '}
                    {isCurrentPeriod && (
                      <span className="relative -top-px ml-0 rounded border border-green-600/50 px-1 text-[11px] font-semibold uppercase text-green-600">
                        current
                      </span>
                    )}
                  </div>
                  <CalendarDaysIcon className="relative -top-0.5 hidden w-5 self-center rounded-full text-slate-500 md:inline-block" />{' '}
                  <span className="text-sm font-semibold text-slate-500">
                    {format(period.start, 'PP')}
                    <ArrowRightIcon className="relative -top-px mx-1 inline-block h-5 w-5 origin-center scale-75 text-slate-500" />
                    {format(actualPeriodEnd, 'PP')}
                  </span>
                </h2>

                <dl
                  className={classNames(
                    'mt-3 grid divide-x divide-slate-300',
                    period.year > 1 ? 'grid-cols-3' : 'grid-cols-2',
                  )}
                >
                  <div className="flex flex-col justify-between py-1">
                    <dt className="px-1 text-center text-xs font-semibold uppercase text-slate-500 [letter-spacing:0.5px]">
                      Accrued
                    </dt>
                    <dd className="px-1 text-center text-2xl text-green-600">
                      {period.accrued}
                    </dd>
                  </div>
                  {period.year > 1 && (
                    <div className="flex flex-col justify-between py-1">
                      <dt className="px-1 text-center text-xs font-semibold uppercase text-slate-500 [letter-spacing:0.5px]">
                        Carried over
                      </dt>
                      <dd className="px-1 text-center text-2xl">
                        <span className="text-green-600">
                          {period.carriedOverFromLastYear > 0 && (
                            <span className="relative -top-0.5 text-xl">+</span>
                          )}
                          {period.carriedOverFromLastYear}
                        </span>
                        {previousPeriod &&
                          previousPeriod.totalInPeriod -
                            previousPeriod.consumed >
                            5 && (
                            <p>
                              {previousPeriod.totalInPeriod -
                                previousPeriod.consumed -
                                5}{' '}
                              PTO lost because of maximmum carry over exceeded.
                            </p>
                          )}
                      </dd>
                    </div>
                  )}
                  <div className="flex flex-col justify-between py-1">
                    <dt className="px-1 text-center text-xs font-semibold uppercase text-slate-500 [letter-spacing:0.5px]">
                      Consumed
                    </dt>
                    <dd
                      className={classNames(
                        'px-1 text-center text-2xl',
                        period.consumed > 0
                          ? 'text-orange-500'
                          : 'text-slate-500',
                      )}
                    >
                      {period.consumed}
                    </dd>
                  </div>
                </dl>

                {period.daysOff && period.daysOff.length > 0 ? (
                  <div className="mt-3 rounded-lg bg-white py-3 pl-3 pr-2">
                    <h2 className="text-xs font-semibold text-slate-500 md:text-sm">
                      Consumed PTO {isCurrentPeriod && 'so far'}
                    </h2>
                    <ol className="mt-2 list-decimal space-y-0.5 pl-4 text-xs text-slate-600 marker:text-slate-400 md:space-y-1 md:text-sm">
                      {period.daysOff
                        .sort((a, b) => a.ndate - b.ndate)
                        .map((pto) => {
                          return (
                            <li key={pto.ndate.toString()}>
                              {format(pto.ndate, 'EEE, PPP')}
                            </li>
                          )
                        })}
                    </ol>
                  </div>
                ) : (
                  <p className="pb-1 pt-6 text-center text-sm italic text-slate-600">
                    {isCurrentPeriod
                      ? 'No PTO days consumed yet'
                      : 'No PTO days consumed during this period'}
                  </p>
                )}
              </li>
            )
          })}
        </ol>
      )}

      {!summary.data && !summary.error && (
        <div className="text-center">
          <Loader active size="mini" inline />
        </div>
      )}

      {summary.error && !summary.data && (
        <div className="my-4 rounded-md  bg-red-100 p-4 text-red-800">
          <ExclamationCircleIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle" />
          {summary.error.message}
        </div>
      )}
    </div>
  )
}
