import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Event, Task, User } from '../../../types'
import UserName from '../../remoteValues/userName'

const Container = styled.div`
  position: relative;
  border-left: dotted 1px var(--border-grey);
  padding: 0px 20px 50px 40px;
  margin-left: 41px;
`

const ContainerFirstOne = styled.div`
  position: relative;
  padding: 0px 20px 50px 40px;
  margin-left: 41px;
`

const UserImage = styled.div`
  position: absolute;
  top: 0px;
  left: -21px;
  z-index: 2;
`

const Buttons = styled.div`
  position: absolute;
  right: 20px;
`

const UserAvatar = styled.img`
  border-radius: 25px;
  width: 45px;
`

interface Props {
  event: Event | Task
  children: ReactNode
  button?: ReactNode
  userId?: User['_id']
  userImage?: User['image']
  isFirstOne?: boolean
}

const Entry = ({
  event,
  button,
  children,
  isFirstOne,
  userId,
  userImage,
}: Props) => {
  const C = isFirstOne ? ContainerFirstOne : Container

  return (
    <C>
      <UserImage>
        {userImage ? (
          <UserAvatar src={userImage} />
        ) : (
          <UserName id={userId || event.createdBy} image height={45} />
        )}
      </UserImage>

      <Buttons>{button}</Buttons>

      {children}
    </C>
  )
}

export default Entry
