import React, { useEffect, useState } from 'react'
import { useTalentApi } from '../../store/mainContext'

const StatusNames = ({ status, showLoading }) => {
  const api = useTalentApi()
  const [loading, setLoading] = useState(showLoading)
  const [value, setValue] = useState()

  useEffect(() => {
    api
      .get(`funnels`)
      .then(({ data: funnels }) => {
        const column = funnels
          .map((x) => x.columns)
          .reduce((pre, cur) => pre.concat(cur))
          .find((x) => x.id === status)
        setValue((column || {}).title)
      })
      .finally(() => setLoading(false))
  }, [api, status])

  if (loading) {
    return <React.Fragment>Trying to remember...</React.Fragment>
  }

  if (!value)
    return (
      <React.Fragment>
        <b>I&apos;m not sure.</b>
      </React.Fragment>
    )

  return <React.Fragment>{value}</React.Fragment>
}

export default StatusNames
