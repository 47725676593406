import { Dropdown } from 'semantic-ui-react'
import React from 'react'

export type View = 'requests' | 'holidays' | 'history'

interface Props {
  onViewChange(view: View): void
}

export default function TimeoffMenu({ onViewChange }: Props) {
  return (
    <Dropdown
      pointing="top right"
      icon="ellipsis horizontal"
      style={{ marginRight: 10 }}
      basic
    >
      <Dropdown.Menu>
        <Dropdown.Item
          text="Time-off Requests"
          icon="calendar plus outline"
          onClick={() => onViewChange('requests')}
        />
        <Dropdown.Item
          text="View History"
          icon="history"
          onClick={() => onViewChange('history')}
        />
        <Dropdown.Item
          text="Holidays"
          icon="star outline"
          onClick={() => onViewChange('holidays')}
        />
      </Dropdown.Menu>
    </Dropdown>
  )
}
