import { DropdownItemProps, Button, Select, Confirm } from 'semantic-ui-react'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { format, getYear } from 'date-fns'
import { groupBy } from 'ramda'
import * as React from 'react'

import { dateFromIsoString } from '../../utils'
import { Company, Holiday } from '../../types'
import { useContractsApi } from '../../store/mainContext'
import ImportHolidaysForm from './ImportHolidaysForm'
import UpdateHolidayForm from './UpdateHolidayForm'
import { useHolidays } from '../../hooks/useHolidays'
import AddHolidayForm from './AddHolidayForm'

interface Props {
  company: Company
}

export default function HolidaysDetail(props: Props) {
  const api = useContractsApi()

  const {
    data: allHolidays,
    isLoading,
    mutate,
    error,
  } = useHolidays({ customerId: props.company._id })

  const [removeCandidate, setRemoveCandidate] = React.useState<Holiday | null>(
    null,
  )

  const currentYear = new Date().getFullYear()
  const [selectedYear, setSelectedYear] = React.useState<number>(currentYear)

  // group current company holidays by year
  const holidaysByYear = groupBy(
    (holiday) => getYear(dateFromIsoString(holiday.date)).toString(),
    allHolidays || [],
  )

  const yearSelectOptions = Object.entries<Holiday[]>({
    [currentYear]: [], // add current year in case we don't have holidays yet
    [currentYear + 1]: [], // also allow updating the next year
    ...holidaysByYear,
  }).reduce((acc, [year, list]) => {
    return [
      ...acc,
      {
        text: year,
        value: parseInt(year, 10),
        description:
          list.length === 0
            ? undefined
            : `${list.length} ${list.length === 1 ? 'holiday' : 'holidays'}`,
      },
    ]
  }, [] as DropdownItemProps[])

  const selectedYearHolidays = holidaysByYear[selectedYear] || []

  const [isImportVisible, setIsImportVisible] = React.useState(false)
  const [isRemoving, setIsRemoving] = React.useState(false)
  const [removeError, setRemoveError] = React.useState('')

  const handleRemove = () => {
    if (!removeCandidate) return
    setRemoveError('')
    setIsRemoving(true)
    api
      .delete(`/holidays/${removeCandidate._id}`)
      .then(() => mutate())
      .then(() => setRemoveCandidate(null))
      .catch((err) => setRemoveError(err.message))
      .finally(() => setIsRemoving(false))
  }

  return (
    <div>
      <div className="mt-6 flex items-baseline">
        <label htmlFor="year" className="mr-2 font-semibold text-slate-600">
          Year
        </label>
        <Select
          id="year"
          options={yearSelectOptions.sort(
            (a, b) => Number(b.value) - Number(a.value),
          )}
          onChange={(_, p) => setSelectedYear(Number(p.value))}
          value={selectedYear}
        />
        {!isLoading && (
          <div className="ml-3 text-slate-500">
            {selectedYearHolidays.length === 0
              ? 'No holidays'
              : `${selectedYearHolidays.length} ${selectedYearHolidays.length === 1 ? 'holiday' : 'holidays'}`}
          </div>
        )}
        <div className="grow" />
        <Button
          disabled={isLoading}
          onClick={() => setIsImportVisible(true)}
          content="Import"
          icon="building"
          basic
        />
      </div>

      {isLoading && (
        <div className="mt-6 text-center text-slate-500">Loading...</div>
      )}

      {isImportVisible && (
        <ImportHolidaysForm
          selectedYearHolidays={selectedYearHolidays}
          companyId={props.company._id}
          onSuccess={mutate}
          onClose={() => setIsImportVisible(false)}
          year={selectedYear}
          key={selectedYear} // reset form when switching years
        />
      )}

      {!isLoading && !error && (
        <div className="mt-8 space-y-2">
          {selectedYearHolidays.map((holiday, i) => (
            <UpdateHolidayForm
              selectedYearHolidays={selectedYearHolidays}
              onRemoveClick={setRemoveCandidate}
              selectedYear={selectedYear}
              hideLabels={i > 0}
              onSuccess={mutate}
              holiday={holiday}
              key={holiday._id}
            />
          ))}

          <AddHolidayForm
            selectedYearHolidays={selectedYearHolidays}
            selectedYear={selectedYear}
            companyId={props.company._id}
            onSuccess={mutate}
            key={selectedYear}
          />
        </div>
      )}

      <Confirm
        header="Are you sure?"
        size="tiny"
        closeOnDimmerClick={!isRemoving}
        closeOnEscape={!isRemoving}
        onCancel={() => {
          setRemoveCandidate(null)
          setRemoveError('')
        }}
        open={!!removeCandidate}
        content={
          <div className="px-6 pb-4 text-base text-slate-600">
            Removing this holiday will affect the time-off calendars of company
            employees, enabling them to request this date as PTO.
            {removeCandidate && (
              <div className="mt-6 rounded-md bg-slate-100 px-6 py-4 text-xl font-semibold text-sky-700">
                <span>
                  {format(dateFromIsoString(removeCandidate.date), 'PPP')}
                </span>
                <span className="mx-2">&mdash;</span>
                <span>{removeCandidate.name}</span>
              </div>
            )}
            {removeError && (
              <div className="mt-8 rounded-md  bg-red-100 p-4 text-red-800">
                <ExclamationCircleIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle" />
                {removeError}
              </div>
            )}
          </div>
        }
        confirmButton={
          <Button disabled={isRemoving} loading={isRemoving} content="Remove" />
        }
        cancelButton={<Button disabled={isRemoving} content="Cancel" />}
        onConfirm={handleRemove}
      />

      {error && (
        <div className="my-6 rounded-md  bg-red-100 p-4 text-red-800">
          <ExclamationCircleIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle" />
          {error.message}
        </div>
      )}
    </div>
  )
}
