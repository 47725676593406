import React, { ReactNode } from 'react'
import styled from 'styled-components'
import UserName from '../../remoteValues/userName'
import Entry from './entry'
import PositionLink from '../../remoteValues/positionLink'
import Period from '../../period'
import DateFormat from '../../dateFormat'
import StatusNames from '../../remoteValues/statusNames'
import Note from './note'
import { Event as EventType, Task, User } from '../../../types'

const Date = styled.div`
  margin-top: 0px !important;
  color: #666;
`

const What = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 20px;
  padding-right: 8px;
`

interface EventProps {
  event: EventType
  isFirstOne?: boolean
}

const Event = ({ event, isFirstOne }: EventProps) => (
  <Note event={event} isFirstOne={isFirstOne} disabled={true} />
)

interface SmallEventProps {
  event: EventType | Task
  isFirstOne?: boolean
  children?: ReactNode
  userId?: User['_id']
}

const SmallEvent = ({
  event,
  isFirstOne,
  children,
  userId,
}: SmallEventProps) => (
  <Entry event={event} isFirstOne={isFirstOne} userId={userId}>
    <What>
      <UserName id={userId || event.createdBy} short />{' '}
      {children || ('description' in event && event.description)}
    </What>
    <Date>
      <b>
        <Period date={event.createdOn} />
      </b>{' '}
      - <DateFormat date={event.createdOn} hours />
    </Date>
  </Entry>
)

interface ActionEventProps {
  event: EventType
  isFirstOne?: boolean
}

const ActionEvent = ({ event, isFirstOne }: ActionEventProps) => (
  <Entry event={event} isFirstOne={isFirstOne} userId={event.createdBy}>
    <What dangerouslySetInnerHTML={{ __html: event.description || '' }}></What>
    <Date>
      <b>
        <Period date={event.createdOn} />
      </b>{' '}
      - <DateFormat date={event.createdOn} hours />
    </Date>
  </Entry>
)

interface PositionChangeEventProps {
  event: EventType
  isFirstOne?: boolean
}

const PositionChangeEvent = ({
  event,
  isFirstOne,
}: PositionChangeEventProps) => {
  const { fromPositionId, toPositionId } = event

  if (!fromPositionId && !toPositionId) {
    return null
  }

  if (toPositionId && !fromPositionId) {
    return (
      <Entry event={event} isFirstOne={isFirstOne}>
        <What>
          <UserName id={event.createdBy} short /> <b>assigned</b> position{' '}
          <PositionLink positionId={toPositionId} />.
        </What>
        <Date>
          <b>
            <Period date={event.createdOn} />
          </b>{' '}
          - <DateFormat date={event.createdOn} hours />
        </Date>
      </Entry>
    )
  }

  if (!toPositionId && fromPositionId) {
    return (
      <Entry event={event} isFirstOne={isFirstOne}>
        <What>
          <UserName id={event.createdBy} short /> <b>removed</b> position{' '}
          <PositionLink positionId={fromPositionId} />.
        </What>
        <Date>
          <b>
            <Period date={event.createdOn} />
          </b>{' '}
          - <DateFormat date={event.createdOn} hours />
        </Date>
      </Entry>
    )
  }

  return (
    <Entry event={event} isFirstOne={isFirstOne}>
      <What>
        <UserName id={event.createdBy} short /> <b>changed</b> position from{' '}
        <PositionLink positionId={fromPositionId!} /> to{' '}
        <PositionLink positionId={toPositionId!} />
      </What>
      <Date>
        <b>
          <Period date={event.createdOn} />
        </b>{' '}
        - <DateFormat date={event.createdOn} hours />
      </Date>
    </Entry>
  )
}

interface StatusChangeEventProps {
  event: EventType
  isFirstOne?: boolean
}

const StatusChangeEvent = ({ event, isFirstOne }: StatusChangeEventProps) => {
  const { fromStatus, toStatus } = event

  if (!fromStatus && !toStatus) {
    return null
  }

  if (toStatus && !fromStatus) {
    return (
      <SmallEvent
        isFirstOne={isFirstOne}
        userId={event.createdBy}
        event={event}
      >
        moved this candidate to{' '}
        <b>
          <StatusNames status={toStatus} showLoading />
        </b>{' '}
        stage.
      </SmallEvent>
    )
  }

  if (!toStatus && fromStatus) {
    return (
      <SmallEvent
        isFirstOne={isFirstOne}
        userId={event.createdBy}
        event={event}
      >
        remove this candidate from{' '}
        <b>
          <StatusNames status={fromStatus} showLoading />
        </b>{' '}
        stage.
      </SmallEvent>
    )
  }

  return (
    <SmallEvent isFirstOne={isFirstOne} userId={event.createdBy} event={event}>
      moved this candidate from{' '}
      <b>
        <StatusNames status={fromStatus} showLoading />
      </b>{' '}
      to{' '}
      <b>
        <StatusNames status={toStatus} showLoading />
      </b>{' '}
      stage.
    </SmallEvent>
  )
}

interface UserEventProps {
  event: EventType
  isFirstOne?: boolean
}

const UserEvent = ({ event, isFirstOne }: UserEventProps) => {
  return (
    <Entry event={event} isFirstOne={isFirstOne} userImage={event.userImage}>
      <What dangerouslySetInnerHTML={{ __html: event.title || '' }}></What>
      {event.description && (
        <p style={{ fontSize: '14px', margin: 10 }}>
          <span dangerouslySetInnerHTML={{ __html: event.description }} />
        </p>
      )}
      <Date>
        <b>
          <Period date={event.createdOn} />
        </b>{' '}
        - <DateFormat date={event.createdOn} hours />
      </Date>
    </Entry>
  )
}

interface PositionPausedResumeEventProps {
  event: EventType
  isFirstOne?: boolean
}

const PositionPausedResumeEvent = ({
  event,
  isFirstOne,
}: PositionPausedResumeEventProps) => (
  <SmallEvent event={event} isFirstOne={isFirstOne}>
    {event.isNowPaused ? <b>paused</b> : <b>resumed</b>} this position.
  </SmallEvent>
)

interface PositionStatusChangeEventProps {
  event: EventType
  isFirstOne?: boolean
}

const PositionStatusChangeEvent = ({
  event,
  isFirstOne,
}: PositionStatusChangeEventProps) => (
  <SmallEvent event={event} isFirstOne={isFirstOne}>
    {event.newStatus === 'closed' ? <b>closed</b> : <b>re-open</b>} this
    position.
  </SmallEvent>
)

export default Event

export {
  Event,
  PositionChangeEvent,
  SmallEvent,
  StatusChangeEvent,
  UserEvent,
  PositionPausedResumeEvent,
  PositionStatusChangeEvent,
  ActionEvent,
}
