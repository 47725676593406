import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import { useTalentApi } from '../store/mainContext'
import Period from './period'
import UserName from './remoteValues/userName'
import TextArea from './form/textArea'

const Sticky = styled.div`
  /* border: solid 1px var(--primary-light); */
  margin-top: 20px;
  padding: 20px;
  border-radius: 4px;
  background-color: var(--primary-light);
  border: solid 1px var(--border-primary);
`

const Container = styled.div``

const StickyNotes = ({ tag, title, sub }) => {
  const [notes, setNotes] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)

  const [value, setValue] = useState()

  const api = useTalentApi()

  useEffect(() => {
    setIsLoading(true)
    api
      .get(`stickynotes`, { params: { tag } })
      .then(({ data }) =>
        setNotes(data.sort((a, b) => b.createdOn - a.createdOn)),
      )
      .finally(() => setIsLoading(false))
  }, [tag, api])

  const handleAdd = async () => {
    setIsSaving(true)

    const { data: newNote } = await api.post(`stickynotes`, {
      text: value,
      tag,
    })

    const newNotes = [...notes, newNote]

    setNotes(newNotes.sort((a, b) => b.createdOn - a.createdOn))

    setIsSaving(false)
    setValue('')
  }

  if (isLoading) {
    return 'Loading...'
  }

  return (
    <Container>
      <h2>{title || 'Sticky Notes'}</h2>
      <p>
        {sub ||
          'Just a space for leaving some notes for the next status meeting.'}
      </p>

      <TextArea
        placeholder="Just say something..."
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />

      <div style={{ textAlign: 'right' }}>
        <Button
          style={{ marginRight: 0, marginTop: 5 }}
          color="black"
          disabled={!value || value.length === 0}
          onClick={handleAdd}
          loading={isSaving}
        >
          Add
        </Button>
      </div>

      {notes.map((n) => (
        <Sticky key={n._id}>
          <h3>
            <b>
              <Period date={n.createdOn} />
            </b>{' '}
            - <UserName id={n.createdBy} />
          </h3>
          <p>{n.text}</p>
        </Sticky>
      ))}
    </Container>
  )
}

export default StickyNotes
