import React from 'react'

import SimpleFilter from './simpleFilter'
import { Company } from '../../types'
import DataFilter from './dataFilter'

const statusOptions = [
  { key: 0, value: undefined, text: 'All Invoices' },
  { key: 1, value: 'draft', text: 'Draft Invoices' },
  { key: 2, value: 'sent', text: 'Sent Invoices' },
  { key: 3, value: 'on-track', text: 'On Track Invoices' },
  { key: 4, value: 'overdue', text: 'Overdue Invoices' },
  { key: 5, value: 'paid', text: 'Paid Invoices' },
]

export interface Filter {
  customerId?: null | { $in: Company['_id'][] }
  dueDate?: null | Partial<Record<'$gt' | '$lt', number>>
  status?: (typeof statusOptions)[number]['value']
}

interface Props {
  onChange: React.Dispatch<React.SetStateAction<Filter>>
  value: Filter
}

const InvoicesFilter = ({ onChange, value }: Props) => {
  const handleStatusChange = (
    _: Filter,
    name: 'status',
    newStatus: Filter['status'],
  ) => {
    if (newStatus === 'on-track') {
      return onChange((prev) => ({
        ...prev,
        status: 'sent',
        dueDate: { $gt: Date.now() },
      }))
    }
    if (newStatus === 'overdue') {
      return onChange((prev) => ({
        ...prev,
        status: 'sent',
        dueDate: { $lt: Date.now() },
      }))
    }
    onChange((prev) => ({ ...prev, status: newStatus, dueDate: undefined }))
  }

  return (
    <div className="flex items-baseline gap-2 py-3">
      <div>Showing</div>
      <SimpleFilter
        name="status"
        onFilterChange={handleStatusChange}
        options={statusOptions}
        value={value.status}
      />
      <div>for</div>
      <DataFilter
        name="customerId"
        collection="companies"
        label="All Customers"
        onFilterChange={(newValue: Filter) =>
          onChange((prev) => ({ ...prev, ...newValue }))
        }
        optionOrderBy={(x: Company) => x.name}
        optionRender={(x: Company) => x.name}
      />
    </div>
  )
}

export default InvoicesFilter
