import { Button, Placeholder, Select } from 'semantic-ui-react'
import * as React from 'react'

import { Company, Invoice } from '../../types'
import { useCompanies } from '../../hooks/useCompany'
import useField from '../../hooks/useField'

interface Props {
  initialValue: Invoice['customerId']
  onSave(value: Company['_id']): Promise<any>
  onClear(): Promise<any>
  disabled?: boolean
  error?: string
}

export default function CompanyField(props: Props) {
  const { initialValue, onSave, onClear, disabled } = props

  const companies = useCompanies()
  const field = useField({ initialValue, onSave, onClear })

  const selectedCompany = companies.data?.find((c) => c._id === field.value)

  const cancel = field.cancel

  React.useEffect(() => {
    if (disabled) cancel()
  }, [disabled, cancel])

  return (
    <div>
      {field.isEditing ? (
        <div className="-mx-2 rounded-md bg-slate-100 p-2">
          <Select
            className="max-w-80"
            placeholder={companies.isLoading ? '' : 'Choose a company'}
            onChange={(_, p) => {
              if (typeof p.value === 'string') field.setValue(p.value)
            }}
            disabled={field.isSaving}
            loading={companies.isLoading}
            options={(companies.data || []).map((c) => ({
              value: c._id,
              text: c.name,
            }))}
            value={field.value || ''}
            clearable
            fluid
            id="customerId"
          />
          <div className="mt-2">
            <Button
              content="Cancel"
              disabled={field.isSaving}
              onClick={field.cancel}
              size="tiny"
              compact
            />
            <Button
              content="Save"
              disabled={field.isSaving}
              loading={field.isSaving}
              onClick={field.save}
              size="tiny"
              compact
              primary
            />
          </div>
        </div>
      ) : !field.value ? (
        <button
          className="-mx-1.5 mt-1.5 px-1.5 py-1 text-[var(--primary)] hover:text-blue-900"
          onClick={field.edit}
        >
          + Add value
        </button>
      ) : (
        <div className="group -mx-2 rounded-md px-2 py-2 hover:bg-slate-50">
          {selectedCompany ? (
            <div className="text-base text-slate-500">
              <div className="flex">
                <div className="grow pb-1 text-lg text-slate-700">
                  {selectedCompany.name}
                </div>
                <div className="opacity-0 group-hover:opacity-100">
                  <Button
                    icon={field.isCopyingToClipboard ? 'check' : 'copy'}
                    disabled={field.isCopyingToClipboard}
                    size="tiny"
                    compact
                    onClick={() =>
                      field.copyToClipboard(selectedCompany?.name || '')
                    }
                    style={{
                      backgroundColor: '#fff',
                      border: 'solid 1px var(--border-grey)',
                    }}
                  />
                  {!disabled && (
                    <Button
                      icon="pencil"
                      size="tiny"
                      compact
                      onClick={field.edit}
                      style={{
                        backgroundColor: '#fff',
                        border: 'solid 1px var(--border-grey)',
                      }}
                    />
                  )}
                </div>
              </div>
              {selectedCompany?.address && <div>{selectedCompany.address}</div>}
              {selectedCompany?.location && (
                <div>{selectedCompany.location}</div>
              )}
              {selectedCompany?.website && (
                <a
                  className="!text-slate-500 underline decoration-slate-300"
                  target="_blank"
                  rel="noreferrer"
                  href={selectedCompany.website}
                >
                  {selectedCompany.website.split('//')[1]}
                </a>
              )}
            </div>
          ) : (
            <Placeholder>
              <Placeholder.Line length="medium" />
              <Placeholder.Line length="long" />
              <Placeholder.Line length="short" />
            </Placeholder>
          )}
        </div>
      )}
      {props.error && (
        <div className="mt-0.5 text-sm text-red-600">{props.error}</div>
      )}
    </div>
  )
}
