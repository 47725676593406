import useSWR from 'swr'

import { Candidate, TimeOffBalance, timeOffBalanceSchema } from '../types'
import { validateResponse } from '../utils'
import { useContractsApi } from '../store/mainContext'

export default function useTimeoffBalance(candidateId: Candidate['_id']) {
  const api = useContractsApi()
  return useSWR<TimeOffBalance, Error>(['timeoff-balance', candidateId], () =>
    api
      .get(`/${candidateId}/active-contract/paid-timeoff/balance`)
      .then(validateResponse(timeOffBalanceSchema)),
  )
}
