import React from 'react'

import { getDiffInDays, isToday, isTomorrow, isYesterday } from '../utils'

interface Props {
  daysSince?: boolean
  date?: number | string | Date
}

const Period = ({ date, daysSince }: Props) => {
  if (!date) {
    return <>Not sure</>
  }

  const daysDiff = getDiffInDays(Date.now(), date)

  if (daysSince) {
    return <>{daysDiff * -1}</>
  }

  if (isToday(date)) {
    return <>Today</>
  }

  if (isYesterday(date)) {
    return <>Yesterday</>
  }

  if (isTomorrow(date)) {
    return <>Tomorrow</>
  }

  if (daysDiff > 30) {
    const months = Math.round(daysDiff / 30)
    return <>{months === 1 ? `Next Month` : `In ${months} months`}</>
  }

  if (daysDiff > 7) {
    const weeks = Math.round(daysDiff / 7)
    return <>{weeks === 1 ? `Next Week` : `In ${weeks} weeks`}</>
  }

  if (daysDiff > 0) {
    return <>In {daysDiff} days</>
  }

  if (daysDiff > -7) {
    return <>{daysDiff * -1} days ago</>
  }

  if (daysDiff > -30) {
    const weeks = Math.round(daysDiff / 7)
    return <>{weeks === -1 ? `Last week` : `${weeks * -1} weeks ago`}</>
  }

  if (daysDiff > -365) {
    const months = Math.round(daysDiff / 30)
    return <>{months === -1 ? `Last month` : `${months * -1} months ago`}</>
  }

  const years = Math.round(daysDiff / 365)
  return <>{years === -1 ? `Last year` : `${years * -1} years ago`}</>
}

export default Period
