import styled from 'styled-components'
import React, { ReactNode } from 'react'

const Help = styled.p`
  margin-top: 10px;
  font-size: 14px;
  color: #666;
`

const Label = styled.p`
  margin-bottom: 7px;
`

const ErrorLabel = styled.p`
  margin-bottom: 7px;
  color: var(--error);
`

const Contaier = styled.div`
  margin-bottom: 20px;
  width: 100%;
`

const ActionsContaier = styled.div`
  margin-bottom: 20px;
`

interface Props {
  children: ReactNode
  actions?: boolean
  error?: boolean
  label?: ReactNode
  help?: ReactNode
}

const FormField = ({ label, help, children, error, actions }: Props) => {
  if (error) {
    return (
      <Contaier>
        {label && <ErrorLabel>{label}</ErrorLabel>}

        {children}

        {help && <Help>{help}</Help>}
      </Contaier>
    )
  }

  if (actions) {
    return <ActionsContaier>{children}</ActionsContaier>
  }

  return (
    <Contaier>
      {label && <Label>{label}</Label>}

      {children}

      {help && <Help>{help}</Help>}
    </Contaier>
  )
}

export default FormField
