import React, { useState, useEffect } from 'react'
import { Dropdown, SelectProps } from 'semantic-ui-react'

import { useAuthApi } from '../../store/mainContext'
import { useUser } from '../../pages/session/hooks'
import { User } from '../../types'

interface Props extends Omit<SelectProps, 'options' | 'onChange'> {
  onChange(newValue: User['_id']): void
  orderBy?(x: any): number
}

const UserSelect = ({
  onChange,
  orderBy,
  clerable = true,
  ...other
}: Props) => {
  const api = useAuthApi()
  const user = useUser()

  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState<User[]>([])

  useEffect(() => {
    setIsLoading(true)
    // TODO: validate schema
    api
      .get<User[]>('users')
      .then(({ data }) => {
        const activeUsers = data.filter((x) => !x.disabled)
        setOptions(
          orderBy
            ? activeUsers.sort((a, b) => orderBy(a) - orderBy(b))
            : activeUsers,
        )
      })
      .finally(() => setIsLoading(false))
  }, [api, orderBy])

  return (
    <Dropdown
      selection
      loading={isLoading}
      clearable={clerable}
      options={options.map((o) => ({
        key: o._id,
        value: o._id,
        text: user._id === o._id ? 'Me!' : o.name,
        image: { avatar: true, src: o.image },
      }))}
      onChange={(_, p) => onChange(p.value as User['_id'])}
      {...other}
    />
  )
}

export default UserSelect
