import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Search } from 'semantic-ui-react'
import styled from 'styled-components'

import { useTalentApi } from '../../store/mainContext'

const ItemName = styled.p`
  font-size: 1.3em;
  margin-bottom: 3px;
  font-weight: bold;
`

const ItemSub = styled.div`
  color: #61605f;

  p {
    line-height: 22px;
  }
`

const SearchBox = ({
  collection,
  render = (e) => (
    <React.Fragment>
      <ItemName>{e.name}</ItemName>
      <ItemSub>{e.email}</ItemSub>
    </React.Fragment>
  ),
  fields = ['name'],
  resultsLimit = 10,
  orderBy = 'lastUpdatedOn',
  ...other
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [results, setResults] = useState([])
  const [value] = useState()

  const api = useTalentApi()

  const history = useHistory()

  const handleSearchChange = async (e, { value: words }) => {
    if (words.length < 3) {
      return
    }

    setIsLoading(true)

    const params = {}

    fields.forEach((field) => (params[field] = words))

    const headers = {
      'astor-limit': resultsLimit,
    }

    const { data } = await api.get(`${collection}/any`, {
      params,
      headers,
    })

    setResults(data.sort((a, b) => b[orderBy] - a[orderBy]))

    setIsLoading(false)
  }

  const handleResultSelect = (e, data) => {
    history.push(`/${collection}/${data.result._id}`)
  }

  return (
    <Search
      loading={isLoading}
      onResultSelect={handleResultSelect}
      onSearchChange={handleSearchChange}
      resultRenderer={render}
      results={results}
      value={value}
      placeholder="Search..."
      {...other}
    />
  )
}

export default SearchBox
