import React, { DragEventHandler, useRef, useState } from 'react'
import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { Button, Progress, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

import { useTalentApi } from '../../store/mainContext'
import TrEditButton from './trEditButton'
import TrCopyButton from './trCopyButton'

import './trFile.css'

const DragPanel = styled.div`
  border: dashed 1px var(--border-grey);
  padding: 20px 0px;
  text-align: center;
`

interface Props {
  name: string
  label: string
  fileUrl: string
  url: string
  value?: string
  disabled?: boolean
  injectedApi?: AxiosInstance
  onSuccess?(name: string, val?: string): void
}

const TrFile = ({
  name,
  label,
  value,
  onSuccess,
  disabled,
  fileUrl,
  url,

  // TODO: Esto es alto conejo. Fixear.
  injectedApi,
}: Props) => {
  let api = useTalentApi()
  api = injectedApi || api

  const [editMode, setEditMode] = useState(false)

  const [showEdit, setShowEdit] = useState(false)

  const [showDragPanel, setShowDragPanel] = useState(false)

  const [val, setVal] = useState(value)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const [progress, setProgress] = useState<number | null>(null)

  const handleFileSelect = async (file: File) => {
    setProgress(1)

    const formData = new FormData()
    formData.append('file', file)

    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (e) => setProgress((e.loaded / e.total) * 100),
    }

    const { data } = await api.post(fileUrl, formData, config)

    const obj: Record<string, any> = {}
    obj[name] = data.location

    await api.patch(url, obj)

    setVal(data.location)
    setProgress(null)
    setShowEdit(false)
    setEditMode(false)
    setShowDragPanel(false)

    onSuccess && onSuccess(name, val)
  }

  const handleCancel = () => {
    setVal(value)
    setProgress(null)
    setShowEdit(false)
    setEditMode(false)
  }

  const handleOnDragEnter: DragEventHandler = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setShowDragPanel(true)
  }

  const handleOnDragLeave = () => {
    setEditMode(false)
    setShowEdit(false)
    setShowDragPanel(false)
  }

  const handleOnDrop: DragEventHandler = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (e.dataTransfer.files?.[0]) {
      handleFileSelect(e.dataTransfer.files[0])
    }
  }

  if (progress) {
    return (
      <tr>
        <th>{label}</th>
        <td
          style={{
            padding: 12,
          }}
        >
          <Progress
            className="uploadProgressBar"
            percent={progress}
            indicating
          />
        </td>
      </tr>
    )
  }

  if (editMode) {
    return (
      <tr
      // onBlur={(x) => !cancelOnBlur && handleCancel()}
      >
        <th>{label}</th>
        <td
          style={{
            paddingTop: 6,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            icon="upload"
            basic
            content={'Upload'}
            size="tiny"
            style={{ padding: `9px 20px`, marginRight: 0 }}
            onClick={() => fileInputRef.current?.click()}
            disabled={disabled}
          />

          <input
            ref={fileInputRef}
            type="file"
            hidden
            onChange={(e) => {
              if (e.target.files?.[0]) {
                handleFileSelect(e.target.files?.[0])
              }
            }}
          />

          <Button
            content="Cancel"
            size="tiny"
            onClick={handleCancel}
            style={{ padding: `9px 13px` }}
          />
        </td>
      </tr>
    )
  }

  return (
    <tr
      onMouseEnter={() => setShowEdit(true)}
      onMouseLeave={() => setShowEdit(false)}
      onDragEnter={handleOnDragEnter}
      onDragOver={handleOnDragEnter}
      onDragExit={handleOnDragLeave}
      onDragLeave={handleOnDragLeave}
      onDrop={handleOnDrop}
    >
      <th>{label}</th>

      {!showDragPanel && (
        <td style={{ position: 'relative' }}>
          {val && (
            <React.Fragment>
              <a rel="noreferrer" href={val} target="_blank">
                <Icon name="file alternate outline" /> Open
              </a>

              <TrCopyButton visible={showEdit} value={val} />
              <TrEditButton
                onClick={() => setEditMode(true)}
                visible={showEdit}
              />
            </React.Fragment>
          )}

          {!val && (
            <React.Fragment>
              <Button
                icon="upload"
                basic
                content={'Upload'}
                size="tiny"
                style={{ padding: `9px 20px`, marginRight: 0 }}
                onClick={() => fileInputRef.current?.click()}
                disabled={disabled}
              />

              <input
                ref={fileInputRef}
                type="file"
                hidden
                onChange={(e) => {
                  if (e.target.files?.[0]) {
                    handleFileSelect(e.target.files[0])
                  }
                }}
              />
            </React.Fragment>
          )}
        </td>
      )}

      {showDragPanel && (
        <td>
          <DragPanel>
            <Icon name="file outline" />
            Just drop it.
          </DragPanel>
        </td>
      )}
    </tr>
  )
}

export default TrFile
