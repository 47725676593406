import React from 'react'

import RemoteValue from './remoteValue'

interface Props {
  id: string
  showLoading?: boolean
}

const CompanyName = ({ id, showLoading }: Props) => (
  <RemoteValue
    key={id}
    id={id}
    collection="companies"
    predicate={(x: { name: any }) => x.name}
    showLoading={showLoading}
  />
)

export default CompanyName
