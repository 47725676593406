import * as React from 'react'

import { classNames } from '../../utils'
import { Payroll } from '../../types'

interface Props {
  payroll: Payroll
}

export default function PayrollStatusLabel(props: Props) {
  const { status, dueDate } = props.payroll

  const isOverdue = Boolean(dueDate && dueDate < Date.now())

  return (
    <span
      className={classNames(
        'whitespace-nowrap text-sm font-semibold uppercase',
        status === 'Waiting for approval' && !isOverdue && 'text-amber-600',
        status === 'Completed' && 'text-green-600',
        isOverdue && 'text-red-500',
      )}
    >
      {isOverdue
        ? 'Overdue'
        : status === 'Waiting for approval'
          ? 'In progress'
          : status}
    </span>
  )
}
