import React, {
  useEffect,
  // , useRef
  useState,
} from 'react'
import ContentEditable from 'react-contenteditable'
import {
  Button,
  Icon,
  Input,
  Menu,
  // , MenuHeader
} from 'semantic-ui-react'
import styled from 'styled-components'
import PageHeader from '../../components/pageHeader/pageHeader'
import Editor from '@monaco-editor/react'
import FormField from '../../components/form/formField'

import { useTalentApi } from '../../store/mainContext'
import PageLoader from '../../components/pageLoader'

const PageContent = styled.div`
  margin: 20px 40px;
  display: flex;
`

const Container = styled.div`
  width: 80%;
  margin-left: 30px;
  position: relative;
`

const CodeEditor = styled(Editor)`
  border: solid 1px var(--border-grey);
  border-radius: 4px;

  &:focus {
    border-color: red !important;
  }
`

const Frame = styled.iframe`
  width: 100%;
  border: dotted 1px var(--border-grey);
  height: 75vh;
  padding-top: -25px;
  border-radius: 4px;
`

const Previewer = styled(ContentEditable)`
  padding: 20px;
  border: dotted 1px var(--border-grey);
`

const Templates = () => {
  document.title = 'Templates'

  const [view, setView] = useState('recruiters')

  return (
    <React.Fragment>
      <PageHeader title="Settings" sub="One Screen to configure them all." />

      <PageContent>
        <Menu secondary vertical>
          <Menu.Item header>Global Settings</Menu.Item>
          <Menu.Item
            name="Access Control"
            active={view === 'users'}
            onClick={() => setView('users')}
          />          
          <Menu.Item
            name="External Recruiters"
            active={view === 'recruiters'}
            onClick={() => setView('recruiters')}
          />
           <Menu.Item
            name="Candidates Metadata"
            active={view === 'candidates'}
            onClick={() => setView('candidates')}
          />
           <Menu.Item
            name="Invoicing Settings"
            active={view === 'invoicing'}
            onClick={() => setView('invoicing')}
          />

        <Menu.Item
            name="Automation"
            active={view === 'automation'}
            onClick={() => setView('automation')}
          />
          <Menu.Item
            name="Webhoooks"
            active={view === 'webhooks'}
            onClick={() => setView('webhook')}
          />

          <Menu.Item
            name="Email Templates"
            active={view === 'positions-email'}
            onClick={() => setView('positions-email')}
          />

        </Menu>

        {view === 'positions-email' && (
          <TemplateEditor
            name="positions-email"
            title={'Positions Update Template'}
          />
        )}

        {view === 'recruiters' && (
          <Container>
            <Frame
              src="https://docs.google.com/spreadsheets/d/1YjO9oSu00ajRkhrIz8jotmUZbqARFJk7rwgEOIzYTBs/edit#gid=0"
              // onLoad={() => setIsLoading(false)}
            />
          </Container>
        )}
      </PageContent>
    </React.Fragment>
  )
}

export default Templates

const TemplateEditor = ({ name, title }) => {
  // const iframe = useRef()

  // const user = useUser()
  const api = useTalentApi()

  const [preview, setPreview] = useState()

  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)

  const [value, setValue] = useState({})
  // const [testEmailAddress, setTestEmailAddress] = useState(user.email)

  useEffect(() => {
    setIsLoading(true)
    api
      .get(`templates`, { params: { name } })
      .then(({ data }) => {
        api.get(`public/emails/recruiting`).then(({ data: html }) => {
          setPreview(html.message)
          setValue(data[0])
        })
      })
      .finally(() => setIsLoading(false))
  }, [api, name])

  const handleSave = async () => {
    setIsSaving(true)

    const { subject, body, position } = value

    await api.patch(`templates/${value._id}`, { subject, body, position })

    const { data: html } = await api.get(`public/emails/recruiting`)

    setPreview(html.message)

    setIsSaving(false)
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <Container>
      <h2>{title}</h2>

      <FormField label="Subject">
        <Input
          disabled={isSaving}
          fluid
          placeholder="Email subject..."
          onChange={(e) => setValue({ ...value, subject: e.target.value })}
          value={value?.subject}
        />
      </FormField>

      <FormField label="Email Body">
        <CodeEditor
          height="400px"
          defaultLanguage="html"
          defaultValue="<p>Hi [Somebody]</p>"
          theme="vs-dark"
          scrollbarVisibility={2}
          options={{
            minimap: { enabled: false },
            lineNumbers: 'off',
            padding: { top: 10 },
            wordWrap: 'on',
            fontSize: 14,
            readOnly: isSaving,
          }}
          value={value?.body}
          onChange={(code) => setValue({ ...value, body: code })}
        />
      </FormField>

      <FormField label="Position Detail">
        <CodeEditor
          height="200px"
          defaultLanguage="html"
          defaultValue="<p>Hi [Somebody]</p>"
          theme="vs-dark"
          scrollbarVisibility={2}
          options={{
            minimap: { enabled: false },
            lineNumbers: 'off',
            padding: { top: 10 },
            wordWrap: 'on',
            fontSize: 14,
            readOnly: isSaving,
          }}
          value={value?.position}
          onChange={(code) => setValue({ ...value, position: code })}
        />
      </FormField>

      <FormField actions>
        <Button
          color="black"
          floated="right"
          onClick={handleSave}
          loading={isSaving}
        >
          <Icon name="save" /> Save
        </Button>
        <Button basic floated="right" disabled={isSaving}>
          Cancel
        </Button>
      </FormField>

      <h2>Preview</h2>

      <Previewer html={preview} disabled={true} />
    </Container>
  )
}
