import { AxiosError } from 'axios'
import * as React from 'react'
import { omit } from 'ramda'

import { Invoice, InvoiceDetail } from '../../types'
import { useContractsApi } from '../../store/mainContext'
import InvoiceDetailsForm from './InvoiceDetailsForm'
import InvoiceActions from './InvoiceActions'
import { useToasts } from '../toasts/ToastsProvider'
import CompanyField from './CompanyField'
import StringField from './StringField'
import PeriodField from './PeriodField'
import DateField from './DateField'

interface Props {
  onDetailsSuccess(): Promise<any>
  onSuccess(): Promise<any>
  details: InvoiceDetail[]
  invoice: Invoice
}

export default function InvoiceEditor(props: Props) {
  const { addToast } = useToasts()
  const api = useContractsApi()
  const [errors, setErrors] = React.useState<
    Record<string, string | undefined>
  >({})

  const handleSaveField = async (data: Partial<Invoice>) => {
    setErrors((prev) => omit(Object.keys(data), prev))
    return api
      .patch(`/invoices/${props.invoice._id}`, data)
      .then(() => props.onSuccess())
      .then(() => addToast('Field updated', { variant: 'success' }))
  }

  const handleClearField = async (fieldName: keyof Invoice) => {
    return api
      .delete(`/invoices/${props.invoice._id}/${fieldName}`)
      .then(() => props.onSuccess())
      .then(() => addToast('Field cleared', { variant: 'success' }))
  }

  const handleClearFields = async (fieldNames: (keyof Invoice)[]) => {
    return Promise.all(
      fieldNames.map((fieldName) =>
        api.delete(`/invoices/${props.invoice._id}/${fieldName}`),
      ),
    )
      .then(() => props.onSuccess())
      .then(() => addToast('Fields cleared', { variant: 'success' }))
  }

  const handleSaveDetails = async (
    data: { type: string; detail: string; amount: number }[],
  ) => {
    setErrors((prev) => omit(['details'], prev))
    return api
      .put(`/invoices/${props.invoice._id}/invoice-details`, data)
      .then(() => props.onDetailsSuccess())
      .then(() => addToast('Details updated', { variant: 'success' }))
  }

  const [isPublishing, setIsPublishing] = React.useState(false)

  const handlePublish = async () => {
    const errs: Record<string, string> = {}
    if (!props.invoice.customerId) errs.customerId = 'Customer is required'
    if (!props.invoice.number) errs.number = 'Required'
    if (!props.invoice.date) errs.date = 'Required'
    if (!props.invoice.dueDate) errs.dueDate = 'Required'
    if (
      (props.invoice.periodStartDate || props.invoice.periodEndDate) &&
      !props.invoice.period
    ) {
      errs.period = 'Required'
    }
    if (
      (props.invoice.period || props.invoice.periodEndDate) &&
      !props.invoice.periodStartDate
    ) {
      errs.periodStartDate = 'Required'
    }
    if (
      (props.invoice.period || props.invoice.periodStartDate) &&
      !props.invoice.periodEndDate
    ) {
      errs.periodEndDate = 'Required'
    }
    if (props.details.length === 0) errs.details = 'At least 1 row is required'

    if (Object.keys(errs).length > 0) return setErrors(errs)

    setIsPublishing(true)
    return api
      .patch(`/invoices/${props.invoice._id}/status`, {
        status: 'Waiting for approval',
      })
      .then(() => props.onSuccess())
      .then(() => addToast('Invoice marked for review', { variant: 'success' }))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'warning' })
      })
      .finally(() => setIsPublishing(false))
  }

  return (
    <div>
      <div className="mx-auto max-w-[900px] py-6">
        <InvoiceActions
          isPublishing={isPublishing}
          onPublish={handlePublish}
          onSuccess={props.onSuccess}
          invoice={props.invoice}
        />
      </div>

      <div className="mx-auto mb-12 aspect-[85/110] max-w-[900px] rounded bg-white px-20 py-20 shadow-xl">
        <img
          alt="Southteams"
          className="float-right mb-8 ml-8 w-24"
          src="/img/128.png"
        />
        <div className="float-left">
          <h1 className="m-0 leading-none ![letter-spacing:2px]">INVOICE</h1>
          <div className="mt-1.5 text-base text-slate-500">
            <div>Southteams</div>
            <div>66 W Flagler Street STE 900,</div>
            <div>Miami, FL 33130, United States</div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://southteams.com"
              className="!text-slate-500 underline decoration-slate-300"
            >
              southteams.com
            </a>
          </div>
        </div>
        <div className="clear-both grid grid-cols-2 items-start gap-4 pt-12">
          <div className="-mt-[6px]">
            <label className="text-sm font-semibold uppercase text-slate-400 [letter-spacing:1px]">
              Customer
            </label>
            <CompanyField
              initialValue={props.invoice.customerId}
              onClear={() => handleClearField('customerId')}
              onSave={(customerId) => handleSaveField({ customerId })}
              disabled={props.invoice.status !== 'Draft' || isPublishing}
              error={errors.customerId}
            />
          </div>

          <div className="rounded-md bg-slate-100 px-4 pb-3 pt-4">
            <div className="group grid grid-cols-2 items-baseline gap-x-2 gap-y-2">
              <label className="text-sm font-semibold uppercase text-slate-500 [letter-spacing:1px]">
                Invoice Number
              </label>
              <StringField
                initialValue={props.invoice.number}
                onClear={() => handleClearField('number')}
                onSave={(number) => handleSaveField({ number })}
                disabled={props.invoice.status !== 'Draft' || isPublishing}
                error={errors.number}
              />
            </div>

            <div className="group grid grid-cols-2 items-baseline gap-x-2 gap-y-2">
              <label className="text-sm font-semibold uppercase text-slate-500 [letter-spacing:1px]">
                Issue Date
              </label>
              <DateField
                initialValue={props.invoice.date}
                onClear={() => handleClearField('date')}
                onSave={(date) => handleSaveField({ date })}
                disabled={props.invoice.status !== 'Draft' || isPublishing}
                error={errors.date}
              />
            </div>

            <div className="group grid grid-cols-2 items-baseline gap-x-2 gap-y-2">
              <label className="text-sm font-semibold uppercase text-slate-500 [letter-spacing:1px]">
                Due Date
              </label>
              <DateField
                initialValue={props.invoice.dueDate}
                onClear={() => handleClearField('dueDate')}
                onSave={(dueDate) => handleSaveField({ dueDate })}
                disabled={props.invoice.status !== 'Draft' || isPublishing}
                clearable
                error={errors.dueDate}
              />
            </div>
          </div>
        </div>

        {(props.invoice.status === 'Draft' ||
          props.invoice.period ||
          props.invoice.periodStartDate ||
          props.invoice.periodEndDate) && (
          <PeriodField
            initialValue={{
              period: props.invoice.period,
              periodStartDate: props.invoice.periodStartDate,
              periodEndDate: props.invoice.periodEndDate,
            }}
            onSave={({ period, periodStartDate, periodEndDate }) =>
              handleSaveField({ period, periodEndDate, periodStartDate })
            }
            onClear={() =>
              handleClearFields(['period', 'periodStartDate', 'periodEndDate'])
            }
            disabled={props.invoice.status !== 'Draft' || isPublishing}
            errors={errors}
          />
        )}

        <InvoiceDetailsForm
          invoice={props.invoice}
          details={props.details}
          onSave={handleSaveDetails}
        />
        {errors.details && (
          <div className="ml-1 mt-1 text-sm text-red-600">{errors.details}</div>
        )}
      </div>
    </div>
  )
}
