import { Link, Redirect, useParams } from 'react-router-dom'
import React, { useState } from 'react'
import { useSWRConfig } from 'swr'
import { Breadcrumb } from 'semantic-ui-react'
import { AxiosError } from 'axios'
import { Helmet } from 'react-helmet'

import { useContractsApi, useTalentApi } from '../../store/mainContext'
import { Account, Candidate, Contract } from '../../types'
import AccountModal, { Dialog } from '../../components/accounts/AccountModal'
import CandidateSidePanel from '../../components/sidepanels/candidateSidePanel'
import CandidateDuplicate from '../../components/candidateDuplicate'
import AccountsSidePanel from '../../components/sidepanels/AccountsSidePanel'
import AccountsForReview from '../../components/accounts/AccountsForReview'
import CandidateActions from '../../components/actions/candidateActions'
import PositionLinker from '../../components/positionLinker'
import LinkedinViewer from '../../components/linkedinViewer'
import { useToasts } from '../../components/toasts/ToastsProvider'
import RequirePowers from '../../components/requirePowers'
import useCandidate from '../../hooks/useCandidate'
import CompanyName from '../../components/remoteValues/companyName'
import Submissions from '../../components/submissions/submissions'
import { Section } from '../../components/sidepanel/sidePanel'
import PageLoader from '../../components/pageLoader'
import DateFormat from '../../components/dateFormat'
import FunnelBar from '../../components/funnelBar/funnelBar'
import Contracts from '../../components/contracts/contracts'
import POWERS from '../../powers'
import Feed from '../../components/feeds/feed'

const CandidatePage = () => {
  const { candidateId } = useParams<{ candidateId: string }>()

  if (!candidateId) {
    throw new Error('`candidateId` param must be present in the URL.')
  }

  const { data: candidate, isLoading, mutate } = useCandidate(candidateId)

  const [selectedContract, setSelectedContract] = useState<Contract | null>(
    null,
  )

  const { addToast } = useToasts()
  const api = useTalentApi()
  const contractsApi = useContractsApi()

  const handleCandidateChange = (property: keyof Candidate, value: any) => {
    if (!candidate) return
    const updatedProps = { [property]: value }
    mutate(() => api.patch(`candidates/${candidateId}`, updatedProps), {
      optimisticData: { ...candidate, ...updatedProps },
      populateCache: false,
      rollbackOnError: true,
    }).catch((e: AxiosError) => {
      addToast(e.response?.data.message || e.message, { variant: 'danger' })
    })
  }

  const [currentDialog, setCurrentDialog] = React.useState<Dialog | null>(null)

  const [isPending, setIsPending] = React.useState(false)

  const { mutate: globalMutate } = useSWRConfig()

  const handleAccountUpdate = async ({ _id, status }: Account) => {
    setIsPending(true)
    return contractsApi
      .patch(`accounts/${_id}`, { status })
      .then(() => globalMutate(['accounts', candidateId]))
      .then(() => setCurrentDialog(null))
      .then(() => addToast('Account reviewed', { variant: 'success' }))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'danger' })
      })
      .finally(() => setIsPending(false))
  }

  if (isLoading) return <PageLoader />

  if (!candidate) {
    addToast('Candidate not found', { variant: 'danger' })
    return <Redirect to="/candidates" />
  }

  return (
    <div className="flex justify-between">
      <Helmet>
        <title>{candidate.name}</title>
      </Helmet>
      <div className="sticky top-0 z-50 ml-3 max-h-screen w-[400px] shrink-0 overflow-auto pb-28 pr-3 [&::-webkit-scrollbar]:hidden">
        <Breadcrumb className="py-4 pl-8 pr-2">
          <Breadcrumb.Section>
            <Link to="/dashboard">Dashboard</Link>
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right chevron" />

          <Breadcrumb.Section>
            {!candidate.status && <Link to="/candidates">Candidates</Link>}

            {candidate.status && (
              <Link
                to={`/candidates/funnels/${candidate.status.split('.')[0]}`}
              >
                Funnel
              </Link>
            )}
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right chevron" />

          <Breadcrumb.Section active>
            {candidate.name.split(' ')[0]}
          </Breadcrumb.Section>
        </Breadcrumb>

        <h1 className="mb-0 ml-8 mr-6 mt-4">{candidate.name}</h1>

        <div className="ml-8 mr-6 mt-1 text-base text-slate-600">
          {candidate.location}
        </div>

        {selectedContract?.active ? (
          <div className="ml-8 mr-6 mt-1 text-base text-slate-600">
            With us since{' '}
            <b>
              <DateFormat date={selectedContract.startingDate} />{' '}
            </b>
            (<CompanyName id={selectedContract.customerId} />)
          </div>
        ) : (
          candidate.referal && (
            <div className="ml-8 mr-6 mt-1 text-base text-slate-600">
              Referred by {candidate.referal}
            </div>
          )
        )}

        <CandidateActions
          candidate={candidate}
          contract={selectedContract}
          onSuccess={handleCandidateChange}
        />

        <CandidateDuplicate candidate={candidate} />

        {selectedContract?.active && (
          <AccountsForReview
            onReviewClick={(account) =>
              setCurrentDialog({ type: 'review-account', account })
            }
            candidateId={candidate._id}
          />
        )}

        <FunnelBar
          key={`status-${candidate.status}`}
          entity={candidate}
          collection="candidates"
          onSuccess={handleCandidateChange}
        />

        <PositionLinker
          key={`position-${candidate.positionId}`}
          candidate={candidate}
          onSuccess={handleCandidateChange}
        />

        <Submissions
          candidateId={candidate._id}
          positionId={candidate.positionId}
        />

        <RequirePowers powers={[POWERS.contracts]}>
          <Contracts
            candidate={candidate}
            selectedContract={selectedContract}
            onSelectedContractChange={setSelectedContract}
          />
        </RequirePowers>

        {selectedContract?.active && (
          <Section icon="dollar" title="Payment Setup & Accounts">
            <AccountsSidePanel
              candidateId={candidate._id}
              onAccountViewClick={(account) =>
                setCurrentDialog({ type: 'account-details', account })
              }
              onAccountReviewClick={(account) =>
                setCurrentDialog({ type: 'review-account', account })
              }
            />

            <AccountModal
              onAccountUpdate={handleAccountUpdate}
              currentDialog={currentDialog}
              isPending={isPending}
              onClose={() => setCurrentDialog(null)}
              onApprove={(account) =>
                setCurrentDialog({ type: 'approve-account-confirm', account })
              }
              onReject={(account) =>
                setCurrentDialog({ type: 'reject-account-confirm', account })
              }
            />
          </Section>
        )}

        <CandidateSidePanel
          candidate={candidate}
          onChangeSuccess={handleCandidateChange}
        />
      </div>

      <div className="w-full min-w-[500px] p-6">
        <Feed relatedId={candidate._id} relatedCollection="candidates" />
      </div>

      <LinkedinViewer url={candidate.linkedin} />
    </div>
  )
}

export default CandidatePage
