import { Button, Input, Label } from 'semantic-ui-react'
import {
  SubmitHandler,
  useFieldArray,
  Controller,
  useForm,
} from 'react-hook-form'
import * as React from 'react'

import { classNames, currencyFormat } from '../../utils'
import { Invoice, InvoiceDetail } from '../../types'
import { useToasts } from '../toasts/ToastsProvider'

const emptyRow = { type: '', detail: '', amount: null } as const

type FormValues = {
  details: Array<{
    type: InvoiceDetail['type']
    detail: InvoiceDetail['detail']
    amount: InvoiceDetail['amount']
  }>
}

interface Props {
  onSave(
    details: Array<{ type: string; detail: string; amount: number }>,
  ): Promise<any>
  details: InvoiceDetail[]
  invoice: Invoice
}

export default function InvoiceDetailsForm(props: Props) {
  const { invoice, details, onSave } = props

  const [isPending, setIsPending] = React.useState(false)
  const [isEditing, setIsEditing] = React.useState(false)

  const { handleSubmit, control, formState, reset } = useForm<FormValues>({
    shouldFocusError: false,
    disabled: invoice.status !== 'Draft',
    defaultValues: {
      details:
        details.length === 0
          ? []
          : details.map((detail) => ({
              type: detail.type || '',
              detail: detail.detail || '',
              amount: detail.amount,
            })),
    },
  })

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    setIsPending(true)
    onSave(
      data.details.map((d) => ({
        type: d.type || '',
        detail: d.detail || '',
        amount: d.amount || 0,
      })),
    )
      .then(() => setIsEditing(false))
      .finally(() => setIsPending(false))
  }

  const { fields, append, remove } = useFieldArray({ control, name: 'details' })

  const totalSum = details.reduce((sum, row) => sum + (row.amount || 0), 0)

  const { addToast } = useToasts()

  const [isCopyingToClipboard, setIsCopyingToClipboard] = React.useState<
    Record<string, boolean>
  >({})

  const copyToClipboard = (text: string, id: string) => {
    setIsCopyingToClipboard((prev) => ({ ...prev, [id]: true }))
    navigator.clipboard
      .writeText(text)
      .then(() => addToast('Copied to clipboard'))
      .finally(() =>
        setTimeout(
          () => setIsCopyingToClipboard((prev) => ({ ...prev, [id]: false })),
          3000,
        ),
      )
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classNames(
          isEditing ? 'bg-slate-100' : 'hover:bg-slate-50',
          'group -mx-2 mt-8 rounded-md px-2 pb-2 pt-2',
        )}
        spellCheck="false"
      >
        <div className={classNames(!isEditing && 'divide-y border-b')}>
          <div className="grid grid-cols-[1fr_1.5fr_125px_39px] gap-x-1 gap-y-2">
            <label
              className={classNames(
                'block pb-3 pl-0.5 text-sm font-semibold uppercase [letter-spacing:1px]',
                isEditing ? 'text-slate-500' : 'text-slate-400',
              )}
            >
              Service
            </label>
            <label
              className={classNames(
                'block pb-3 pl-0.5 text-sm font-semibold uppercase [letter-spacing:1px]',
                isEditing ? 'text-slate-500' : 'text-slate-400',
              )}
            >
              Detail
            </label>
            <label
              className={classNames(
                'col-span-2 block pb-3 pl-0.5 text-sm font-semibold uppercase [letter-spacing:1px]',
                isEditing ? 'text-slate-500' : 'text-slate-400',
              )}
            >
              Amount
            </label>
          </div>
          {fields.map((item, index) => {
            const row = details[index]
            const amountString =
              typeof row?.amount === 'number'
                ? currencyFormat(row?.amount || 0)
                : ''
            return (
              <div
                className={classNames(
                  'group/row grid grid-cols-[1fr_1.5fr_125px_39px] gap-x-1 gap-y-2',
                  !isEditing &&
                    details.length > 2 &&
                    'even:bg-slate-50 group-hover:even:bg-slate-100',
                )}
                key={item.id}
              >
                <Controller
                  name={`details.${index}.type`}
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field, fieldState }) => {
                    const error = fieldState.error?.message
                    return (
                      <div className="grow basis-1/2">
                        {isEditing ? (
                          <Input
                            {...field}
                            disabled={field.disabled || isPending}
                            fluid
                            error={!!error}
                            id={field.name}
                            className={classNames(
                              field.disabled &&
                                'cursor-text !opacity-100 [&>input]:!border-slate-50 [&>input]:!bg-slate-50',
                            )}
                            /* disable 1Password */
                            input={<input data-1p-ignore />}
                          />
                        ) : (
                          <div className="pl-2 leading-[38px]">{row?.type}</div>
                        )}
                        {isEditing && (
                          <div className="mt-1 text-sm text-red-600">
                            {error}
                          </div>
                        )}
                      </div>
                    )
                  }}
                />

                <Controller
                  name={`details.${index}.detail`}
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field, fieldState }) => {
                    const error = fieldState.error?.message
                    return (
                      <div className="grow basis-1/2">
                        {isEditing ? (
                          <Input
                            {...field}
                            disabled={field.disabled || isPending}
                            fluid
                            error={!!error}
                            id={field.name}
                            className={classNames(
                              field.disabled &&
                                'cursor-text !opacity-100 [&>input]:!border-slate-50 [&>input]:!bg-slate-50',
                            )}
                            /* disable 1Password */
                            input={<input data-1p-ignore />}
                          />
                        ) : (
                          <div className="leading-[38px]">{row?.detail}</div>
                        )}
                        {isEditing && (
                          <div className="mt-1 text-sm text-red-600">
                            {error}
                          </div>
                        )}
                      </div>
                    )
                  }}
                />
                <Controller
                  name={`details.${index}.amount`}
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field, fieldState }) => {
                    const error = fieldState.error?.message
                    return (
                      <div
                        className={classNames(
                          'grow basis-1/4',
                          (field.disabled || !isEditing) && 'col-span-2',
                        )}
                      >
                        {isEditing ? (
                          <Input
                            {...field}
                            disabled={field.disabled || isPending}
                            value={field.value || ''}
                            onChange={(e) => {
                              if (isNaN(e.target.valueAsNumber)) {
                                field.onChange('')
                              } else {
                                field.onChange(Number(e.target.value))
                              }
                            }}
                            fluid
                            type="number"
                            label={
                              <Label>
                                <span className="relative top-[3px]">$</span>
                              </Label>
                            }
                            className={classNames(
                              field.disabled &&
                                'cursor-text !opacity-100 [&>.label]:!border-slate-50 [&>.label]:!bg-slate-50 [&>input]:!border-slate-50 [&>input]:!bg-slate-50',
                            )}
                            error={!!error}
                            id={field.name}
                            /* disable 1Password */
                            input={<input data-1p-ignore />}
                          />
                        ) : (
                          <div className="relative leading-[38px]">
                            {amountString}
                            <Button
                              className="absolute right-0 top-[5px] opacity-0 group-hover/row:opacity-100"
                              icon={
                                row && isCopyingToClipboard[row._id]
                                  ? 'check'
                                  : 'copy'
                              }
                              disabled={row && isCopyingToClipboard[row._id]}
                              size="tiny"
                              compact
                              type="button"
                              onClick={() =>
                                copyToClipboard(
                                  `${row?.type} ${row?.detail} ${amountString}`,
                                  row?._id || '',
                                )
                              }
                              style={{
                                backgroundColor: '#fff',
                                border: 'solid 1px var(--border-grey)',
                              }}
                            />
                          </div>
                        )}
                        {isEditing && (
                          <div className="mt-1 text-sm text-red-600">
                            {error}
                          </div>
                        )}
                      </div>
                    )
                  }}
                />
                {!formState.disabled && isEditing && (
                  <Button
                    className={classNames(
                      'top-0.5',
                      'relative !mr-0 self-start',
                    )}
                    disabled={
                      fields.length === 1 || formState.disabled || isPending
                    }
                    onClick={() => remove(index)}
                    icon="trash alternate outline"
                    color="red"
                    type="button"
                  />
                )}
              </div>
            )
          })}
        </div>

        <div className="mt-2 flex items-baseline">
          <div className="min-h-[29px] grow">
            {invoice.status === 'Draft' && (
              <>
                {!isEditing && details.length > 0 ? (
                  <Button
                    content="Edit details"
                    onClick={() => setIsEditing(true)}
                    basic
                    compact
                    type="button"
                    icon="pencil"
                    size="small"
                    className="opacity-0 group-hover:opacity-100"
                  />
                ) : (
                  <button
                    className="mt-1.5 px-1.5 py-1 text-[var(--primary)] hover:text-blue-900 disabled:opacity-60"
                    onClick={() => {
                      setIsEditing(true)
                      append(emptyRow)
                    }}
                    type="button"
                    disabled={isPending}
                  >
                    + Add row
                  </button>
                )}
              </>
            )}
          </div>
          {isEditing && (
            <Button
              disabled={isPending}
              onClick={() => {
                reset({ details })
                setIsEditing(false)
              }}
              type="button"
              size="small"
            >
              Cancel
            </Button>
          )}
          {isEditing && (
            <Button
              type="submit"
              primary
              size="small"
              disabled={isPending}
              loading={isPending}
            >
              Save
            </Button>
          )}
        </div>
      </form>
      {details.length > 0 && (
        <div
          className={classNames(
            'pointer-events-none grid grid-cols-[1fr_1.5fr_125px_39px] items-baseline gap-x-1',
            isEditing ? 'mt-6' : '-mt-9',
          )}
        >
          <div className="col-span-2 self-baseline pr-6 text-right text-xl font-semibold uppercase text-slate-800">
            Total
          </div>
          <div className="col-span-2 self-baseline text-xl font-semibold">
            {currencyFormat(totalSum)}
          </div>
        </div>
      )}
    </>
  )
}
