import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { Button, Confirm } from 'semantic-ui-react'
import * as React from 'react'

import { useContractsApi } from '../../store/mainContext'
import { Contract } from '../../types'

interface Props {
  onSuccess(): void
  onCancel(): void
  contractId: Contract['_id']
  show: boolean
}

export default function DeleteContractModal(props: Props) {
  const api = useContractsApi()

  const [removeError, setRemoveError] = React.useState('')
  const [isRemoving, setIsRemoving] = React.useState(false)

  const handleRemove = () => {
    if (!props.contractId) return
    setRemoveError('')
    setIsRemoving(true)
    api
      .delete(`/contracts/${props.contractId}`)
      .then(() => props.onSuccess())
      .catch((err) => setRemoveError(err.message))
      .finally(() => setIsRemoving(false))
  }

  return (
    <Confirm
      header="Are you sure?"
      size="tiny"
      closeOnDimmerClick={!isRemoving}
      closeOnEscape={!isRemoving}
      onCancel={() => {
        props.onCancel()
        setRemoveError('')
      }}
      open={props.show}
      content={
        <div className="px-6 pb-4 text-base text-slate-600">
          <div>
            Removing this contract will also remove all related deals, SOWs and
            time-off requests.
          </div>
          <div className="mt-2 font-semibold text-slate-500">
            You can remove this contract because it has not started yet.
          </div>
          {removeError && (
            <div className="mt-8 rounded-md  bg-red-100 p-4 text-red-800">
              <ExclamationCircleIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle" />
              {removeError}
            </div>
          )}
        </div>
      }
      confirmButton={
        <Button disabled={isRemoving} loading={isRemoving} content="Remove" />
      }
      cancelButton={<Button disabled={isRemoving} content="Cancel" />}
      onConfirm={handleRemove}
    />
  )
}
