import { Button, Icon, Select } from 'semantic-ui-react'
import React, { useState } from 'react'
import styled from 'styled-components'

import { Funnel, FunnelColumn } from '../../types'

const ColumnButton = styled.button`
  cursor: pointer;
  border: dotted 1px var(--border-grey);
  padding: 10px;
  border-radius: 4px;
  background-color: #fff;
  display: block;
  width: 100%;

  &[disabled] {
    cursor: auto;
    opacity: 0.8;
  }

  &[aria-selected='true'] {
    background-color: var(--primary-light);
    border: solid 1px var(--border-primary);
    color: var(--primary);
    font-weight: bold;

    &[disabled] {
      opacity: 1;
    }
  }

  &:hover {
    border: solid 1px var(--border-primary);
    background-color: var(--primary-light);
  }
`

interface Props {
  onCancel(): void
  onRemove(): Promise<void>
  onSave(columnId: FunnelColumn['id']): Promise<void>
  funnels: Funnel[]
  value?: FunnelColumn
}

const StatusSelector = ({
  onCancel,
  onRemove,
  funnels,
  onSave,
  value,
}: Props) => {
  const [isRemoving, setIsRemoving] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [selectedColumn, setSelectedColumn] = useState<
    FunnelColumn | undefined
  >(value)

  const [selectedFunnel, setSelectedFunnel] = useState<Funnel | undefined>(
    funnels.find((x) => x.name === value?.id.split('.')[0]) || funnels[0],
  )

  const isDisabled = isRemoving || isSaving

  return (
    <div className="p-3 text-center">
      <h4 className="m-0 pb-1 pt-1 text-left text-base">Funnel</h4>
      <Select
        disabled={isDisabled}
        options={funnels
          .sort((a, b) => a.order - b.order)
          .map((x) => ({ value: x._id, text: x.title }))}
        value={selectedFunnel?._id || ''}
        fluid
        style={{ marginBottom: 20 }}
        onChange={(e, target) =>
          setSelectedFunnel(funnels.find((x) => x._id === target.value))
        }
      />

      <h4 className="m-0 pb-2 pt-0 text-left text-base">Stage</h4>
      {(selectedFunnel?.columns || []).map((column, index) => {
        const isSelected = column.id === selectedColumn?.id
        return (
          <React.Fragment key={column?.id}>
            <ColumnButton
              aria-selected={isSelected}
              onClick={() => setSelectedColumn(column)}
              disabled={isDisabled || isSelected}
              type="button"
            >
              {column.title}
            </ColumnButton>

            {index !== (selectedFunnel?.columns || []).length - 1 && (
              <Icon name="arrow down" className="mx-auto" />
            )}
          </React.Fragment>
        )
      })}

      <div className="mt-6 flex justify-between">
        <Button
          color="red"
          size="tiny"
          disabled={isDisabled}
          onClick={() => {
            setIsRemoving(true)
            onRemove().finally(() => setIsRemoving(false))
          }}
          loading={isRemoving}
        >
          Remove Stage
        </Button>

        <div>
          <Button basic size="tiny" onClick={onCancel} disabled={isDisabled}>
            Cancel
          </Button>
          <Button
            loading={isSaving}
            color="black"
            size="tiny"
            disabled={isDisabled || !selectedColumn}
            onClick={() => {
              if (!selectedColumn) return
              setIsSaving(true)
              onSave(selectedColumn.id).finally(() => setIsSaving(false))
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

export default StatusSelector
