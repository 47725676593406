import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import DateFormat from '../dateFormat'
import { Section } from '../sidepanel/sidePanel'
import styled from 'styled-components'
import CompanyName from '../remoteValues/companyName'
import ContactName from '../remoteValues/contactName'

const Container = styled.div`
  margin: 20px 50px 40px 50px;
`

const SubmissionsHistory = ({ onSelect, onGoBack, selected, submissions }) => (
  <Section
    icon="history"
    title="Submissions History"
    menu={
      <Button size="mini" basic icon onClick={onGoBack}>
        <Icon name="caret left" /> Back
      </Button>
    }
  >
    <Container>
      {submissions.map((submission) => (
        <Button
          key={submission._id}
          basic
          color={submission._id === selected._id && 'violet'}
          fluid
          style={{ marginBottom: 10, textAlign: 'left' }}
          onClick={() => onSelect(submission)}
        >
          <p style={{ marginBottom: 5 }}>
            <b>To:</b> <ContactName id={submission.submittedTo} />
            &nbsp;(
            {submission.customerId ? (
              <CompanyName id={submission.customerId} />
            ) : (
              'No Company'
            )}
            )
          </p>

          {submission.submittedOn ? (
            <React.Fragment>
              <b>On: </b>
              <DateFormat date={submission.submittedOn} />
            </React.Fragment>
          ) : (
            'No date specified.'
          )}
        </Button>
      ))}
    </Container>
  </Section>
)

export default SubmissionsHistory
