import React, { useEffect, useState } from 'react'
import { Button, Icon, Input, Modal } from 'semantic-ui-react'
import styled from 'styled-components'
import { useContractsApi } from '../../store/mainContext'
import FormField from '../form/formField'

import DateSelect from '../form/dateSelect'
import CompanySelect from '../form/companySelect.tsx'

const Title = styled.h2`
  font-weight: bold !important;
`

const AddContractModal = ({ show, candidate, onCancel, onSuccess }) => {
  const api = useContractsApi()

  // const [task, setTask] = useState(taskToFullfil);
  const [errors, setErrors] = useState()
  const [errorMessage, setErrorMessage] = useState()

  const [contract, setContract] = useState({})

  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    setErrors(null)
    setContract({})
    setIsSaving(false)
    setErrorMessage(null)
  }, [show])

  const handleSave = async () => {
    setIsSaving(true)

    contract.candidateId = candidate._id

    try {
      const { data } = await api.post('contracts', contract)

      onSuccess && onSuccess(data)
    } catch ({ response }) {
      if (response.data.fields) {
        setErrors(response.data.fields)
        return
      }

      setErrorMessage(response.data.message)
    } finally {
      setIsSaving(false)
    }
  }

  if (!show) {
    return null
  }

  return (
    <Modal
      onClose={onCancel}
      open={true}
      size="tiny"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <Title>New Contract</Title>

        <p>
          You are about to create a new contract for{' '}
          <b className="highlight">{candidate.name}</b>.
        </p>

        <p>
          <b>This action has an impact on Payroll & Invoicing</b> so be serious
          about it.
        </p>

        <FormField label="Position">
          <Input
            fluid
            placeholder="Position..."
            autoFocus
            value={contract.position || ''}
            onChange={(e) =>
              setContract({ ...contract, position: e.target.value })
            }
            error={errors && errors.find((x) => x === 'position')}
            disabled={isSaving}
          />
        </FormField>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flexGrow: 1 }}>
            <FormField label="Contractor Fee (Monthly)">
              <Input
                type="number"
                style={{ width: '100%' }}
                iconPosition="left"
                placeholder="Contractor Monthly Fee..."
                value={contract.salary || ''}
                onChange={(e) =>
                  setContract({ ...contract, salary: Number(e.target.value) })
                }
                error={errors && errors.find((x) => x === 'salary')}
                disabled={isSaving}
              >
                <Icon name="dollar" />
                <input />
              </Input>
            </FormField>
          </div>

          <div style={{ marginLeft: 10, flexGrow: 1 }}>
            <FormField label="Starting Date">
              <DateSelect
                name="startingDate"
                style={{ width: '100%' }}
                error={errors && errors.find((x) => x === 'startingDate')}
                pointing="left"
                onChange={(e, target) =>
                  setContract({ ...contract, startingDate: target.value })
                }
                value={contract.startingDate && new Date(contract.startingDate)}
                placeholder="Starting Date"
                disabled={isSaving}
              />
            </FormField>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flexGrow: 1 }}>
            <FormField label="Customer Rate (Monthly)">
              <Input
                type="number"
                fluid={true}
                iconPosition="left"
                placeholder="Customer Monthly Rate..."
                value={contract.rate || ''}
                onChange={(e) =>
                  setContract({ ...contract, rate: Number(e.target.value) })
                }
                error={errors && errors.find((x) => x === 'rate')}
                disabled={isSaving}
              >
                <Icon name="dollar" />
                <input />
              </Input>
            </FormField>
          </div>

          <div style={{ flexGrow: 1, marginLeft: 10 }}>
            <FormField label="Customer">
              <CompanySelect
                name="customerId"
                fluid={true}
                error={errors && errors.find((x) => x === 'customerId')}
                value={contract.customerId}
                onChange={(e, target) =>
                  setContract({ ...contract, customerId: target.value })
                }
                disabled={isSaving}
              />
            </FormField>
          </div>
        </div>

        {errorMessage && <p style={{ color: 'var(--red)' }}>{errorMessage}</p>}
      </Modal.Content>

      <Modal.Actions>
        <Button basic onClick={onCancel} disabled={isSaving}>
          Nervermind...
        </Button>
        <Button color="black" onClick={handleSave} loading={isSaving}>
          <Icon name="flag checkered" /> Start contract!
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

// const getNames = (fullName) => {
//   const parts = fullName.split(' ')

//   if (parts.length === 2) {
//     return parts
//   }

//   return [parts[0], parts[parts.length - 1]]
// }

export default AddContractModal
