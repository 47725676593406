import { Button, Header, Input, Modal } from 'semantic-ui-react'
import React, { useState } from 'react'
import styled from 'styled-components'

const StyledInput = styled(Input)`
  width: 100%;
  font-size: 17px !important;
`

const ConfirmRemoveModal = ({
  show,
  validationValue,
  content,
  header,
  onCancel,
  onConfirm,
}) => {
  const [, setValue] = useState()
  const [enableRemove, setEnableRemove] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  if (!show) return null

  const handleConfirm = () => {
    setIsLoading(true)
    onConfirm()
  }

  const handleOnChange = (e) => {
    setValue(e.target.value)
    setEnableRemove(
      e.target.value.toLowerCase() === validationValue.toLowerCase(),
    )
  }

  return (
    <Modal
      closeOnDimmerClick={!isLoading}
      closeOnEscape={!isLoading}
      onClose={onCancel}
      size="small"
      open
    >
      <Header>{header}</Header>
      <Modal.Content>
        {content}

        <StyledInput
          autoFocus
          onChange={handleOnChange}
          placeholder="Enter value to confirm..."
          disabled={isLoading}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={onCancel} disabled={isLoading}>
          Ugh...No no no, I&apos;m sorry...
        </Button>
        <Button
          color="red"
          disabled={!enableRemove || isLoading}
          onClick={handleConfirm}
          loading={isLoading}
        >
          Sure. Remove.
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ConfirmRemoveModal
