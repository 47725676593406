import * as React from 'react'

import { useToasts } from '../components/toasts/ToastsProvider'

export default function useCopyToClipboard() {
  const { addToast } = useToasts()
  const [isCopyingToClipboard, setIsCopyingToClipboard] = React.useState(false)

  const copyToClipboard = (text: string) => {
    setIsCopyingToClipboard(true)
    navigator.clipboard
      .writeText(text)
      .then(() => addToast('Copied to clipboard'))
      .finally(() => setTimeout(() => setIsCopyingToClipboard(false), 3000))
  }

  return { isCopyingToClipboard, copyToClipboard }
}
