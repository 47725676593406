import React from 'react'

import DateFormat from '../dateFormat'
import UserName from '../remoteValues/userName'

import TrCheckbox from '../sidepanel/trCheckbox'
import TrDataSelect from '../sidepanel/trDataSelect'
import TrFile from '../sidepanel/trFile'
import TrInput from '../sidepanel/trInput'
import PositionActions from '../actions/positionActions'
import SidePanel, { Divider, Section } from '../sidepanel/sidePanel'

import TrCompanySelect from '../sidepanel/trCompanySelect'
import TrContactSelect from '../sidepanel/trContactSelect'

import TrInputTags from '../sidepanel/trInputTags'

const PositionSidePanel = ({ position, onChangeSuccess }) => {
  return (
    <React.Fragment>
      <PositionActions position={position} onChangeSuccess={onChangeSuccess} />

      <Section icon="file alternate outline" title="Position Information">
        <SidePanel>
          <tbody>
            <TrInput
              key="title"
              name="title"
              label="Job Title"
              value={position.title}
              url={`positions/${position._id}`}
              onSuccess={onChangeSuccess}
              // type="textarea"
              placeholder="Job Title..."
            />

            <TrInput
              key="description"
              name="description"
              label="Job Description"
              value={position.description}
              url={`positions/${position._id}`}
              onSuccess={onChangeSuccess}
              type="textarea"
              placeholder="Job Description..."
            />

            <TrInput
              key="openings"
              name="openings"
              label="Openings"
              value={position.openings}
              url={`positions/${position._id}`}
              onSuccess={onChangeSuccess}
              // render={(v) => `$ ${v}`}
              number={true}
            />

            <TrInput
              key="salary"
              name="salary"
              label="Salary (Average)"
              value={position.salary}
              url={`positions/${position._id}`}
              onSuccess={onChangeSuccess}
              render={(v) => `$ ${v}`}
              number={true}
            />

            <TrCompanySelect
              key="companyId"
              name="companyId"
              label="Customer"
              value={position.companyId}
              url={`positions/${position._id}`}
              onSuccess={onChangeSuccess}
              search
              placeholder="Customer..."
              render={(x) => x.name}
              allowAdditions
            />

            <TrFile
              key="originalJD"
              name="originalJD"
              label="Original JD"
              value={position.originalJD}
              url={`positions/${position._id}`}
              fileUrl={`cdn/original-jd/${position._id}`}
              onSuccess={onChangeSuccess}
              placeholder="Upload Job Description"
            />

            <TrContactSelect
              key="requesterId"
              name="requesterId"
              label="Requested By"
              value={position.requesterId}
              url={`positions/${position._id}`}
              onSuccess={onChangeSuccess}
              placeholder="Requested by..."
              allowAdditions={true}
              render={(x) => x.name}
            />
          </tbody>
        </SidePanel>

        <Divider />

        <SidePanel>
          <tbody>
            <tr>
              <th>Open On</th>
              <td>
                <DateFormat date={position.openOn} />
              </td>
            </tr>

            <tr>
              <th>Open By</th>
              <td>
                <UserName id={position.openBy} />
              </td>
            </tr>

            {position.status === 'closed' && (
              <React.Fragment>
                <tr>
                  <th>Closed On</th>
                  <td>
                    <DateFormat date={position.closedOn} />
                  </td>
                </tr>
                <tr>
                  <th>Closed By</th>
                  <td>
                    <DateFormat date={position.closedBy} />
                  </td>
                </tr>
              </React.Fragment>
            )}
          </tbody>
        </SidePanel>
      </Section>

      <Section icon="user circle" title="Contact Information">
        <SidePanel>
          <tbody>
            <TrContactSelect
              key="hiringManagerId"
              name="hiringManagerId"
              label="Hiring Manager"
              value={position.hiringManagerId}
              url={`positions/${position._id}`}
              onSuccess={onChangeSuccess}
              placeholder="Hiring Manager..."
              allowAdditions={true}
              render={(x) => x.name}
            />

            <TrContactSelect
              key="secondContact"
              name="secondContact"
              label="2nd Contact"
              value={position.secondContact}
              url={`positions/${position._id}`}
              onSuccess={onChangeSuccess}
              placeholder="Second Contact..."
              allowAdditions={true}
              render={(x) => x.name}
            />

            <TrContactSelect
              key="thirdContact"
              name="thirdContact"
              label="3rd Contact"
              value={position.thirdContact}
              url={`positions/${position._id}`}
              onSuccess={onChangeSuccess}
              placeholder="Third Contact..."
              allowAdditions={true}
              render={(x) => x.name}

              // color="red"
            />

            <TrContactSelect
              key="fourthContact"
              name="fourthContact"
              label="4th Contact"
              value={position.fourthContact}
              url={`positions/${position._id}`}
              onSuccess={onChangeSuccess}
              placeholder="Fourth Contact..."
              allowAdditions={true}
              render={(x) => x.name}
              // color="red"
            />
          </tbody>
        </SidePanel>
      </Section>

      <Section icon="id badge outline" title="Profile Information">
        <SidePanel>
          <tbody>
            <TrDataSelect
              key="profilesRequired"
              name="profilesRequired"
              label="Profiles Required"
              value={position.profilesRequired}
              url={`positions/${position._id}`}
              dataCollection="profiles"
              onSuccess={onChangeSuccess}
              multiple
              search
              placeholder="Profiles required..."
              // color="red"
            />

            <TrDataSelect
              key="techsRequired"
              name="techsRequired"
              label="Techs Required"
              value={position.techsRequired}
              url={`positions/${position._id}`}
              dataCollection="techs"
              onSuccess={onChangeSuccess}
              multiple
              search
              placeholder="Techs required..."
              // color="red"
            />

            <TrInput
              key="minYears"
              name="minYears"
              label="Minimun XP Required (Years)"
              value={position.minYears}
              url={`positions/${position._id}`}
              onSuccess={onChangeSuccess}
              // type="textarea"
              placeholder="Years..."
            />

            <TrInputTags
              key="keywords"
              name="keywords"
              label="Keywords"
              value={position.keywords}
              url={`positions/${position._id}`}
              onSuccess={onChangeSuccess}
              placeholder="Keywords..."
            />
          </tbody>
        </SidePanel>
      </Section>

      <Section icon="settings" title="Position Configuration">
        <SidePanel>
          <tbody>
            <TrCheckbox
              key="isHighPriority"
              name="isHighPriority"
              label="High Priority"
              value={position.isHighPriority}
              url={`positions/${position._id}`}
              help="If set this position will be marked as high priority"
              onSuccess={onChangeSuccess}
            />

            <TrCheckbox
              key="sharePublicly"
              name="sharePublicly"
              label="Share Publicly"
              value={position.sharePublicly}
              url={`positions/${position._id}`}
              help="If set this position will be shared with general public thru the web and with external recruiters and agencies."
              onSuccess={onChangeSuccess}
            />

            <TrCheckbox
              key="shareInSlack"
              name="shareInSlack"
              label="Share on Slack"
              value={position.shareInSlack}
              url={`positions/${position._id}`}
              help="If set Slack Bot will share this position in #jobs channel."
              onSuccess={onChangeSuccess}
            />
          </tbody>
        </SidePanel>
      </Section>

      <SidePanel>
        <tbody>
          <tr>
            <th>Added On</th>
            <td>
              <DateFormat date={position.createdOn} />
            </td>
          </tr>
        </tbody>
      </SidePanel>
    </React.Fragment>
  )
}

export default PositionSidePanel
