import { Switch, Route } from 'react-router-dom'
import * as React from 'react'

import Candidates from './pages/candidates/candidates'
import Recruiters from './pages/recruiters/recruiters'
import Candidate from './pages/candidates/candidate'
import Positions from './pages/positions/positions'
import Position from './pages/positions/position'
import SideBar from './components/sidebar/Sidebar'

import Settings from './pages/settings/settings'
import Tasks from './pages/tasks/tasks'

import CandidatesFunnel from './pages/funnels/candidatesFunnel'

import ContactsFunnel from './pages/funnels/contactsFunnel'

import Contacts from './pages/contacts/contacts'
import Contact from './pages/contacts/contact'

import Companies from './pages/companies/companies'
import Company from './pages/companies/company'

import Contracts from './pages/contracts/contracts'
import FunnelEdit from './pages/funnels/funnelEdit'

import Invoices from './pages/invoices/invoices'
import InvoicePage from './pages/invoices/invoice'
import Dashboard from './pages/dashboard/Dashboard'

import PayrollPage from './pages/payrolls/Payroll'
import Payrolls from './pages/payrolls/Payrolls'

const App = () => (
  <div className="flex flex-row items-stretch justify-start">
    <SideBar />

    <div id="content" className="w-full pl-[63px]">
      <Switch>
        <Route path="/invoices/:invoiceId" exact component={InvoicePage} />
        <Route path="/invoices" exact component={Invoices} />

        <Route path="/payrolls/:payrollId" exact component={PayrollPage} />
        <Route path="/payrolls" exact component={Payrolls} />

        <Route path="/contracts" exact component={Contracts} />

        <Route path="/positions/:positionId" exact component={Position} />
        <Route path="/positions" exact component={Positions} />

        <Route path="/candidates/:candidateId" exact component={Candidate} />
        <Route path="/candidates" exact component={Candidates} />
        <Route
          path="/candidates/funnels/:funnelName"
          exact
          component={CandidatesFunnel}
        />
        <Route
          path="/candidates/funnels/:funnelName/edit"
          exact
          component={FunnelEdit}
        />

        <Route path="/contacts/:contactId" exact component={Contact} />
        <Route path="/contacts" exact component={Contacts} />

        <Route
          path="/contacts/funnels/:funnelName"
          exact
          component={ContactsFunnel}
        />

        <Route
          path="/contacts/funnels/:funnelName/edit"
          exact
          component={FunnelEdit}
        />

        <Route path="/companies/:companyId" exact component={Company} />
        <Route path="/companies" exact component={Companies} />

        <Route path="/recruiters" exact component={Recruiters} />
        <Route path="/settings" exact component={Settings} />
        <Route path="/tasks" exact component={Tasks} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/" exact component={Candidates} />
      </Switch>
    </div>
  </div>
)

export default App
