import { AxiosError } from 'axios'
import * as React from 'react'

import useCopyToClipboard from './useCopyToClipboard'
import { useToasts } from '../components/toasts/ToastsProvider'

interface UseFieldParams<V> {
  initialEditMode?: boolean
  initialValue: V
  onSave(value: V): Promise<any>
  onClear?(): Promise<any>
}

export default function useField<V>(params: UseFieldParams<V>) {
  const { initialValue, onSave, onClear, initialEditMode = false } = params

  const { copyToClipboard, isCopyingToClipboard } = useCopyToClipboard()
  const { addToast } = useToasts()

  const [isEditing, setIsEditing] = React.useState(initialEditMode)
  const [isSaving, setIsSaving] = React.useState(false)
  const [value, setValue] = React.useState(initialValue)

  const clear = () => {
    if (!onClear) return
    setIsSaving(true)
    onClear()
      .then(() => setIsEditing(false))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message, { variant: 'danger' })
      })
      .finally(() => setIsSaving(false))
  }

  const save = () => {
    if (!value) return clear()
    setIsSaving(true)
    onSave(value)
      .then(() => setIsEditing(false))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message, { variant: 'danger' })
      })
      .finally(() => setIsSaving(false))
  }

  const cancel = () => {
    setValue(initialValue)
    setIsEditing(false)
  }

  const edit = () => setIsEditing(true)

  return {
    isCopyingToClipboard,
    copyToClipboard,
    isEditing,
    setValue,
    isSaving,
    cancel,
    value,
    clear,
    edit,
    save,
  }
}
