import * as React from 'react'
import styled from 'styled-components'
import format from 'date-fns/format'

import { classNames, currencyFormat, dateFromIsoString } from '../utils'
import useTimeoffRequest from '../hooks/useTimeoffRequest'
import { Task } from '../types'
import useTimeoffCoverage from '../hooks/useTimeoffCoverage'
import { Popup } from 'semantic-ui-react'

const Editable = styled.textarea`
  border: solid 1px var(--border-grey);
  padding: 15px;
  font-size: 17px;
  line-height: 1.4em;
  padding-bottom: 30px;
  border-radius: 4px;
  overflow: auto;
  max-height: 400px;
  resize: none;
  width: 100%;
  display: block;

  &::placeholder {
    color: #999;
  }

  &:focus {
    outline: none;
    border: solid 1px var(--primary);
    box-shadow: var(--active-shadow);
  }
`

interface Props {
  onCandidateNoteChange(newValue: string): void
  candidateNote?: string
  isPending?: boolean
  doneNote?: string
  task: Task
  mode: 'approve' | 'reject'
}

export default function TimeoffReviewConfirmation(props: Props) {
  const { task, mode, candidateNote } = props

  const request = useTimeoffRequest(task.timeOffRequestId)

  const coverage = useTimeoffCoverage(task.timeOffRequestId)

  const totalCost = (coverage.data || []).reduce(
    (sum, { dayValue }) => sum + (dayValue || 0),
    0,
  )

  const dates = request.data?.dates || []

  return (
    <div>
      <p>This action will:</p>

      <ul className="list-disc space-y-4 pl-6 text-lg">
        <li>
          Mark the related task as{' '}
          <strong className="ml-0.5 text-base font-semibold uppercase [letter-spacing:1px]">
            done
          </strong>
        </li>
        <li>
          Mark the time-off request as{' '}
          <strong
            className={classNames(
              'ml-0.5 text-base font-semibold uppercase [letter-spacing:1px]',
              mode === 'reject' ? 'text-red-500' : 'text-green-600',
            )}
          >
            {mode === 'approve' ? 'Approved' : 'Rejected'}
          </strong>
          {mode === 'approve' && (
            <div className="text-base text-slate-600">
              This will deduct{' '}
              <strong>
                {dates.length} {dates.length === 1 ? 'day' : 'days'}
              </strong>{' '}
              from the contractor&apos;s time-off balance.
            </div>
          )}
          {mode === 'reject' && (
            <div className="text-base text-slate-500">
              This will allow the contractor to request some or all of the same
              dates in the future.
            </div>
          )}
          <div className="mt-2 break-words">
            {dates.map((day, i) => {
              const tx = (coverage.data || []).find(
                (item) => item.date === day && !item.coveredByPtoPolicy,
              )
              const isNotCovered = Boolean(tx)
              return (
                <Popup
                  key={day}
                  size="mini"
                  content={
                    <div>
                      Not covered by PTO policy
                      {typeof tx?.dayValue === 'number' && (
                        <div>Cost: {currencyFormat(tx.dayValue)}</div>
                      )}
                    </div>
                  }
                  position="top center"
                  mouseEnterDelay={0}
                  mouseLeaveDelay={0}
                  disabled={!isNotCovered}
                  trigger={
                    <span
                      className={classNames(
                        isNotCovered &&
                          'cursor-help underline decoration-dotted underline-offset-4',
                        'whitespace-nowrap text-base font-semibold',
                        mode === 'approve' ? 'text-green-600' : 'text-red-500',
                      )}
                    >
                      {format(dateFromIsoString(day), 'MMM do')}
                      {i < dates.length - 1 && (
                        <span className="mr-2 text-slate-500">,</span>
                      )}
                    </span>
                  }
                />
              )
            })}
          </div>
        </li>
        {mode === 'approve' && totalCost > 0 && (
          <li>
            Deduct a total of{' '}
            <span className="font-semibold text-red-700">
              {currencyFormat(totalCost)}
            </span>{' '}
            from the candidate&apos;s next payment
          </li>
        )}
        <li>
          Notify the contractor <strong>via e-mail</strong> with the following
          message
        </li>
      </ul>
      <div>
        <label
          style={{
            fontWeight: 'bold',
            margin: '24px 0 6px 2px',
            display: 'block',
          }}
        >
          Drop a line for the candidate:
        </label>

        <Editable
          autoFocus
          disabled={props.isPending}
          onChange={(e) => props.onCandidateNoteChange(e.target.value)}
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="off"
          spellCheck="false"
          required
          value={candidateNote}
          rows={2}
        />
      </div>
    </div>
  )
}
