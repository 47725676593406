import useSWR from 'swr'

import { CustomerSow, customerSowSchema } from '../types'
import { validateResponse } from '../utils'
import { useContractsApi } from '../store/mainContext'

export default function useSow(sowId?: CustomerSow['_id']) {
  const api = useContractsApi()
  return useSWR<CustomerSow, Error>(
    sowId ? ['/customerSOWs/', sowId] : null,
    () =>
      api
        .get(`/customerSOWs/${sowId}`)
        .then(validateResponse(customerSowSchema)),
  )
}
