import * as React from 'react'

import { classNames } from '../../utils'
import { Invoice } from '../../types'

interface Props {
  invoice: Invoice
}

export default function InvoiceStatusLabel(props: Props) {
  const { status, dueDate } = props.invoice

  const isOverdue = Boolean(
    status === 'Sent' && dueDate && dueDate < Date.now(),
  )

  return (
    <span
      className={classNames(
        'whitespace-nowrap text-sm font-semibold uppercase',
        status === 'Waiting for approval' && 'text-amber-600',
        status === 'Sent' && !isOverdue && 'text-blue-600',
        status === 'Sent' && isOverdue && 'text-red-500',
        status === 'Dismissed' && 'text-purple-400',
        status === 'Approved' && 'text-teal-500',
        status === 'Draft' && 'text-slate-400',
        status === 'Paid' && 'text-green-600',
      )}
    >
      {isOverdue ? 'Overdue' : status}
    </span>
  )
}
