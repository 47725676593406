import React, { useState } from 'react'
import { Dropdown, Icon } from 'semantic-ui-react'
import { isFuture } from 'date-fns'

import { Candidate, Contract } from '../../types'
import TerminateContractModal from '../modals/terminateContractModal'
import DeleteContractModal from '../modals/DeleteContractModal'
import AddContractModal from '../modals/addContractModal'
import UpdateDealModal from '../modals/updateDealModal'
import RequirePowers from '../requirePowers'
import POWERS from '../../powers'

interface Props {
  onContractTerminated(): void
  onContractRemoved(): void
  onContractAdded(): void
  onShowHistory(): void
  onDealUpdated(): void
  selectedContract: Contract
  contracts: Contract[]
  candidate: Candidate
}

const ContractsMenu = (props: Props) => {
  const {
    onContractTerminated,
    onContractRemoved,
    selectedContract,
    onContractAdded,
    onShowHistory,
    onDealUpdated,
    candidate,
    contracts,
  } = props

  const [showTerminateModal, setShowTerminateModal] = useState(false)
  const [showAddContractModal, setShowAddContractModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [deleteCandidate, setDeleteCandidate] = useState<Contract | null>(null)

  const canDeleteContract = isFuture(selectedContract.startingDate)
  const activeContract = contracts.find((x) => !x.endDate)

  return (
    <React.Fragment>
      <Dropdown
        icon={null}
        trigger={<Icon name="ellipsis horizontal" />}
        style={{ marginRight: 10 }}
        basic
        pointing="top right"
      >
        <Dropdown.Menu>
          <RequirePowers powers={[POWERS.createContract]}>
            <Dropdown.Item
              text="Add New Contract"
              icon="add"
              disabled={!!activeContract}
              onClick={() => setShowAddContractModal(true)}
            />
          </RequirePowers>

          <Dropdown.Item
            text="View History"
            icon="history"
            onClick={onShowHistory}
          />

          <RequirePowers powers={[POWERS.updateDeal, POWERS.customerSOWs]}>
            <Dropdown.Divider />
            <Dropdown.Item
              text="Update Contract"
              icon="edit outline"
              onClick={() => setShowUpdateModal(true)}
              disabled={!activeContract}
            />
          </RequirePowers>

          {canDeleteContract ? (
            <RequirePowers powers={[POWERS.removeContract]}>
              <Dropdown.Item
                content={
                  <div style={{ color: 'var(--red)' }}>
                    <b>
                      <Icon name="trash alternate outline" />
                      Delete Contract
                    </b>
                  </div>
                }
                onClick={() => setDeleteCandidate(selectedContract)}
              />
            </RequirePowers>
          ) : (
            <RequirePowers powers={[POWERS.terminateContract]}>
              <Dropdown.Divider />
              <Dropdown.Item
                disabled={!activeContract}
                content={
                  <div style={{ color: 'var(--red)' }}>
                    <b>
                      <Icon name="cut" />
                      Terminate Contract
                    </b>
                  </div>
                }
                onClick={() => setShowTerminateModal(true)}
              />
            </RequirePowers>
          )}
        </Dropdown.Menu>
      </Dropdown>

      <AddContractModal
        show={showAddContractModal}
        candidate={candidate}
        onCancel={() => setShowAddContractModal(false)}
        onSuccess={() => {
          setShowAddContractModal(false)
          onContractAdded()
        }}
      />

      <UpdateDealModal
        show={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        contractId={selectedContract._id}
        onSuccess={() => {
          setShowUpdateModal(false)
          onDealUpdated()
        }}
      />

      <TerminateContractModal
        candidate={candidate}
        show={showTerminateModal}
        onCancel={() => setShowTerminateModal(false)}
        contractId={selectedContract._id}
        onSuccess={() => {
          setShowTerminateModal(false)
          onContractTerminated()
        }}
      />

      <DeleteContractModal
        onSuccess={() => {
          setDeleteCandidate(null)
          onContractRemoved()
        }}
        contractId={selectedContract._id}
        onCancel={() => setDeleteCandidate(null)}
        show={!!deleteCandidate}
      />
    </React.Fragment>
  )
}

export default ContractsMenu
