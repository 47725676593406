import React, { CSSProperties } from 'react'
import { classNames } from '../../utils'

interface DataTableProps extends React.HTMLAttributes<HTMLTableElement> {}

const DataTable = ({ children, style }: DataTableProps) => {
  return (
    <table
      className="w-full [&_tbody_tr:hover]:cursor-pointer [&_tbody_tr:hover]:bg-slate-50"
      style={style}
    >
      {children}
    </table>
  )
}

interface ThProps extends React.HTMLAttributes<HTMLTableCellElement> {
  minWidth?: CSSProperties['minWidth']
  width?: CSSProperties['width']
  align?: CSSProperties['textAlign']
}

const Th: React.FC<ThProps> = ({
  className,
  children,
  minWidth,
  width,
  align,
  ...other
}) => (
  <th
    className={classNames(
      className,
      'border border-l-0 border-r-0 border-slate-300 px-4 py-2.5 text-left text-sm uppercase text-slate-500 [letter-spacing:2px]',
    )}
    style={{ width, minWidth: width || minWidth, textAlign: align }}
    {...other}
  >
    {children}
  </th>
)

Th.displayName = 'DataTable.Th'
DataTable.Th = Th

interface TdProps extends React.HTMLAttributes<HTMLTableCellElement> {
  paddingBottom?: CSSProperties['paddingBottom']
  paddingTop?: CSSProperties['paddingTop']
  colspan?: HTMLTableCellElement['colSpan']
  valign?: CSSProperties['verticalAlign']
  align?: CSSProperties['textAlign']
}

const Td: React.FC<TdProps> = ({
  paddingBottom,
  paddingTop,
  className,
  children,
  colspan,
  valign,
  align,
  ...other
}) => (
  <td
    className={classNames(
      className,
      'h-14 min-h-14 border border-l-0 border-r-0 border-slate-300 px-4 py-2.5 text-lg [letter-spacing:0] ',
    )}
    colSpan={colspan}
    style={{
      verticalAlign: valign,
      textAlign: align,
      paddingTop,
      paddingBottom,
    }}
    {...other}
  >
    {children}
  </td>
)

Td.displayName = 'DataTable.Td'
DataTable.Td = Td

export default DataTable
