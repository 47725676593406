import React from 'react'
import { Checkbox } from 'semantic-ui-react'
import styled from 'styled-components'
import { useTalentApi } from '../../store/mainContext'

const Help = styled.p`
  color: #666;
  margin-top: 10px;
  font-size: 14px;
`

const TrCheckbox = ({
  name,
  value,
  url,
  onSuccess,
  label,
  help,
  injectedApi,
  disabled = false,
}) => {
  let api = useTalentApi()
  api = injectedApi || api

  const handleOnChange = async (e, target) => {
    const update = {}
    update[name] = target.checked

    await api.patch(url, update)

    onSuccess && onSuccess(name, target.checked)
  }

  return (
    <tr>
      <th>{label}</th>
      <td>
        <Checkbox
          name={name}
          toggle
          onChange={handleOnChange}
          checked={value}
          disabled={disabled}
        />
        {help && <Help>{help}</Help>}
      </td>
    </tr>
  )
}

export default TrCheckbox
