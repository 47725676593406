import { groupBy } from 'ramda'
import useSWR from 'swr'
import { z } from 'zod'

import { Collection, Event, Task, taskSchema, eventSchema } from '../types'
import { validateResponse } from '../utils'
import { useTalentApi } from '../store/mainContext'

/**
 * SWR hook that returns all pending `Task`s (sorted by descending due date) and
 * all "event feed items" (currently `Event`s and "done" `Task`s sorted by
 * creation date or completion date) related to a specific collection item.
 *
 * TODO: feels a little weird to mix 2 different item types in the same list.
 * Maybe it would make more sense for Events to be immutable entities and create
 * more Event subtypes for logging Tasks lifecycles.
 */
export default function useEventFeed(params: {
  relatedCollection: Collection
  relatedId: string
}) {
  const api = useTalentApi()

  return useSWR<{ feed: (Task | Event)[]; pending: Task[] }, Error>(
    ['events-feed', params.relatedCollection, params.relatedId],
    // we need to hit 2 different endpoints to gather all the required data
    () =>
      Promise.all([
        api
          .get('tasks', {
            headers: { 'astor-sortby': 'dueDate', 'astor-sortby-dir': '-1' },
            params,
          })
          .then(validateResponse(z.array(taskSchema))),
        api
          .get('events', {
            headers: { 'astor-sortby': 'createdOn', 'astor-sortby-dir': '-1' },
            params,
          })
          .then(validateResponse(z.array(eventSchema))),
      ]).then(([tasks, events]) => {
        // Group `Task`s in "done" and "pending".
        // Note `Task`s are already sorted by `dueDate`.
        const { done = [], pending = [] } = groupBy<Task, 'done' | 'pending'>(
          (t) => (t.isDone ? 'done' : 'pending'),
        )(tasks)

        // The Feed is a mix of `Event`s and "done" `Task`s. Then we sort by
        // descending date (`doneOn` if a `Task`, `createdOn` if an `Event`)
        const feed: (Event | Task)[] = [...events, ...done].sort(
          (taskOrEventA, taskOrEventB) => {
            const sortByA =
              'doneOn' in taskOrEventA
                ? (taskOrEventA as Task).doneOn || 0
                : taskOrEventA.createdOn
            const sortByB =
              'doneOn' in taskOrEventB
                ? (taskOrEventB as Task).doneOn || 0
                : taskOrEventB.createdOn
            return sortByB - sortByA
          },
        )

        return { feed, pending }
      }),
  )
}
