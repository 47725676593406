import useSWR from 'swr'
import { z } from 'zod'

import { Candidate, Contract, contractSchema } from '../types'
import { validateResponse } from '../utils'
import { useContractsApi } from '../store/mainContext'

export default function useContracts(candidateId: Candidate['_id']) {
  const api = useContractsApi()
  return useSWR<Contract[], Error>(['contracts', candidateId], () =>
    api
      .get(`/${candidateId}/contracts`, {
        headers: {
          'astor-sortby': 'startingDate',
          'astor-sortby-dir': '-1',
        },
      })
      .then(validateResponse(z.array(contractSchema)))
      // TODO: API is not reacting to sortby headers
      .then((contracts) =>
        contracts.sort((a, b) => b.startingDate - a.startingDate),
      ),
  )
}
