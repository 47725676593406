import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { Button, Input } from 'semantic-ui-react'
import { format } from 'date-fns'
import * as React from 'react'

import { classNames } from '../../utils'
import { Invoice } from '../../types'
import useField from '../../hooks/useField'

type Value = {
  period: Invoice['period']
  periodStartDate: Invoice['periodStartDate']
  periodEndDate: Invoice['periodEndDate']
}

interface Props {
  onSave(value: {
    period: string
    periodStartDate: number
    periodEndDate: number
  }): Promise<any>
  onClear(): Promise<any>
  initialValue: Value
  disabled?: boolean
  errors: Record<string, string | undefined>
}

export default function PeriodField(props: Props) {
  const { initialValue, onClear, onSave, disabled, ...other } = props

  const field = useField<Value>({ initialValue, onClear, onSave })

  const cancel = field.cancel

  React.useEffect(() => {
    if (disabled) cancel()
  }, [disabled, cancel])

  if (
    !field.isEditing &&
    !field.value.period &&
    !field.value.periodStartDate &&
    !field.value.periodEndDate
  ) {
    return (
      <button
        className="-mx-1.5 mt-8 px-1.5 py-1 text-[var(--primary)] hover:text-blue-900"
        onClick={field.edit}
      >
        + Add period
      </button>
    )
  }

  return (
    <form
      className={classNames(
        '-mx-2 mt-10 rounded-md px-2 py-2',
        field.isEditing ? 'bg-slate-100' : 'hover:bg-slate-50',
      )}
      spellCheck="false"
      onSubmit={(e) => {
        e.preventDefault()
        if (
          !field.value.period &&
          !field.value.periodStartDate &&
          !field.value.periodEndDate
        ) {
          return field.clear()
        }
        field.save()
      }}
    >
      <div className="group grid grid-cols-3 gap-x-4">
        <div className="-mt-1 rounded">
          <label
            className={classNames(
              'text-sm font-semibold uppercase [letter-spacing:1px]',
              field.isEditing ? 'text-slate-500' : 'text-slate-400',
            )}
          >
            Period
          </label>
          {field.isEditing ? (
            <Input
              fluid
              value={field.value.period || ''}
              onChange={(_, p) =>
                field.setValue((prev) => ({ ...prev, period: p.value }))
              }
              disabled={field.isSaving}
              /* disable 1Password */
              input={<input data-1p-ignore />}
            />
          ) : (
            <div className="relative">
              <div className="leading-[38px]">
                {field.value.period || (
                  <span className="italic text-slate-500">
                    &ndash;empty&ndash;
                  </span>
                )}
              </div>
            </div>
          )}
          {props.errors.period && (
            <div className="ml-0.5 text-sm text-red-600">
              {props.errors.period}
            </div>
          )}
        </div>
        <div className="-mt-1 rounded [&>.field]:!block">
          <label
            className={classNames(
              'text-sm font-semibold uppercase [letter-spacing:1px]',
              field.isEditing ? 'text-slate-500' : 'text-slate-400',
            )}
          >
            Period Start
          </label>
          {field.isEditing ? (
            <SemanticDatepicker
              onChange={(_, { value }) => {
                field.setValue((prev) => ({
                  ...prev,
                  periodStartDate:
                    value instanceof Date ? value.getTime() : undefined,
                }))
              }}
              disabled={field.isSaving}
              datePickerOnly
              clearOnSameDateClick={false}
              clearable={false}
              className="w-full"
              value={
                field.value.periodStartDate
                  ? new Date(field.value.periodStartDate)
                  : undefined
              }
              pointing="right"
              {...other}
            />
          ) : (
            <div className="relative">
              <div className="leading-[38px]">
                {field.value.periodStartDate ? (
                  format(field.value.periodStartDate, 'yyyy-MM-dd')
                ) : (
                  <span className="italic text-slate-500">
                    &ndash;empty&ndash;
                  </span>
                )}
              </div>
            </div>
          )}
          {props.errors.periodStartDate && (
            <div className="ml-0.5 text-sm text-red-600">
              {props.errors.periodStartDate}
            </div>
          )}
        </div>
        <div className="-mt-1 rounded [&>.field]:!block">
          <label
            className={classNames(
              'text-sm font-semibold uppercase [letter-spacing:1px]',
              field.isEditing ? 'text-slate-500' : 'text-slate-400',
            )}
          >
            Period End
          </label>
          {field.isEditing ? (
            <SemanticDatepicker
              onChange={(_, { value }) => {
                field.setValue((prev) => ({
                  ...prev,
                  periodEndDate:
                    value instanceof Date ? value.getTime() : undefined,
                }))
              }}
              disabled={field.isSaving}
              datePickerOnly
              clearOnSameDateClick={false}
              clearable={false}
              className="w-full"
              value={
                field.value.periodEndDate
                  ? new Date(field.value.periodEndDate)
                  : undefined
              }
              pointing="right"
              {...other}
            />
          ) : (
            <div className="relative">
              <div className="leading-[38px]">
                {field.value.periodEndDate ? (
                  format(field.value.periodEndDate, 'yyyy-MM-dd')
                ) : (
                  <span className="italic text-slate-500">
                    &ndash;empty&ndash;
                  </span>
                )}
              </div>
              <div className="absolute right-0 top-1 opacity-0 group-hover:opacity-100">
                <Button
                  icon={field.isCopyingToClipboard ? 'check' : 'copy'}
                  disabled={field.isCopyingToClipboard}
                  size="tiny"
                  compact
                  onClick={() =>
                    field.copyToClipboard(
                      field.value.period +
                        ' ' +
                        (field.value.periodStartDate
                          ? format(field.value.periodStartDate, 'yyyy-MM-dd')
                          : '') +
                        ' ' +
                        (field.value.periodEndDate
                          ? format(field.value.periodEndDate, 'yyyy-MM-dd')
                          : ''),
                    )
                  }
                  style={{
                    backgroundColor: '#fff',
                    border: 'solid 1px var(--border-grey)',
                  }}
                />
                {!disabled && (
                  <Button
                    icon="pencil"
                    size="tiny"
                    compact
                    onClick={field.edit}
                    style={{
                      backgroundColor: '#fff',
                      border: 'solid 1px var(--border-grey)',
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {props.errors.periodEndDate && (
            <div className="ml-0.5 text-sm text-red-600">
              {props.errors.periodEndDate}
            </div>
          )}
        </div>
      </div>
      {field.isEditing && (
        <div className="-mr-0.5 mt-2 flex justify-end">
          <Button
            content="Cancel"
            disabled={field.isSaving}
            onClick={field.cancel}
            size="small"
            type="button"
          />
          <Button
            content="Save"
            disabled={field.isSaving}
            loading={field.isSaving}
            size="small"
            primary
            type="submit"
          />
        </div>
      )}
    </form>
  )
}
