import {
  Button,
  DropdownItemProps,
  Icon,
  Popup,
  Select,
} from 'semantic-ui-react'
import { ExclamationCircleIcon } from '@heroicons/react/16/solid'
import * as React from 'react'

import { classNames, dateFromIsoString } from '../../utils'
import { Company, Holiday } from '../../types'
import { useContractsApi } from '../../store/mainContext'
import { useCompanies } from '../../hooks/useCompany'
import { useHolidays } from '../../hooks/useHolidays'
import { format } from 'date-fns'

interface Props {
  selectedYearHolidays: Holiday[]
  onSuccess(): Promise<void>
  companyId: Company['_id']
  onClose(): void
  year: number
}

export default function ImportHolidaysForm(props: Props) {
  const api = useContractsApi()
  const companies = useCompanies()

  // get all holidays in the DB
  const { data: allHolidays, isLoading } = useHolidays()

  // company selected in combobox
  const [selectedCompany, setSelectedCompany] =
    React.useState<Holiday['customerId']>()

  // selected company holidays for the selected year
  const selectedCompanyHolidays = (allHolidays || []).filter(
    (h) => h.customerId === selectedCompany && h.year === props.year,
  )

  // holidays that do not clash with current company holidays
  const nonConflictingHolidays = selectedCompanyHolidays.filter(
    (h) =>
      !props.selectedYearHolidays.some(
        (existingHoliday) => existingHoliday.date === h.date,
      ),
  )

  const companySelectOptions = (companies.data || [])
    // exclude the current company
    .filter((c) => c._id !== props.companyId)
    // create the select options
    .reduce((acc, company) => {
      const companyHolidays = (allHolidays || []).filter(
        (h) => h.customerId === company._id && h.year === props.year,
      )
      return [
        ...acc,
        {
          text: company.name,
          value: company._id,
          disabled: companyHolidays.length === 0,
          description:
            companyHolidays.length === 0
              ? `No holidays in ${props.year}`
              : `${companyHolidays.length} ${companyHolidays.length === 1 ? 'holiday' : 'holidays'}`,
        },
      ]
    }, [] as DropdownItemProps[])

  const hasEnabledOptions = companySelectOptions.some(
    (option) => !option.disabled,
  )

  const [isImportingArr, setIsImportingArr] = React.useState<Holiday['_id'][]>(
    [],
  )

  const [importingErrors, setImportingErrors] = React.useState<
    Record<Holiday['_id'], string>
  >({})

  const handleSingleImport = async (holiday: Holiday) => {
    setIsImportingArr((prev) => [...prev, holiday._id])
    setImportingErrors((prev) => ({ ...prev, [holiday._id]: '' }))
    const date = dateFromIsoString(holiday.date)
    return api
      .post('holidays', {
        customerId: props.companyId,
        ndate: date.getTime(),
        year: date.getFullYear(),
        name: holiday.name,
        date: holiday.date,
      })
      .then(() => props.onSuccess())
      .catch((err) => {
        setImportingErrors((prev) => ({ ...prev, [holiday._id]: err.message }))
      })
      .finally(() => {
        setIsImportingArr((prev) => prev.filter((id) => id !== holiday._id))
      })
  }

  const handleImportAll = () => {
    const promises = nonConflictingHolidays.map(handleSingleImport)
    Promise.allSettled(promises)
  }

  const isSubmitting = isImportingArr.length > 0

  return (
    <section className="-mx-4 -mb-2 mt-4 rounded-md bg-slate-100 px-4 pb-4 pt-4">
      <div className="max-w-prose text-base text-slate-600">
        Import {props.year} holidays from another company.
      </div>
      <div className="mt-4">
        <label
          htmlFor="company"
          className="mb-0.5 ml-0.5 block text-base font-semibold text-slate-600"
        >
          Holidays from
        </label>
        <Select
          id="company"
          placeholder={
            !isLoading && hasEnabledOptions
              ? 'Select Company...'
              : `No company has holidays in ${props.year}`
          }
          className="max-w-96"
          onChange={(_, p) => setSelectedCompany(String(p.value))}
          disabled={isSubmitting || isLoading || !hasEnabledOptions}
          options={companySelectOptions}
          loading={isLoading}
          value={selectedCompany}
          search
          fluid
        />
      </div>
      {selectedCompanyHolidays.length > 0 && (
        <div className="ml-0.5 mt-5">
          <div className="mb-2 text-base font-semibold text-slate-600">
            Dates{' '}
            <span className="ml-0.5 font-normal text-slate-400">
              ({selectedCompanyHolidays.length})
            </span>
          </div>
          <table className="-ml-2 space-y-2 text-lg">
            <tbody>
              {selectedCompanyHolidays.map((h) => {
                const isImporting = isImportingArr.includes(h._id)
                const itemError = importingErrors[h._id]
                const hasConflict = props.selectedYearHolidays.some(
                  (existingHoliday) => existingHoliday.date === h.date,
                )
                return (
                  <React.Fragment key={h._id}>
                    <tr
                      className={classNames(
                        !hasConflict && 'hover:bg-slate-50',
                        itemError ? 'text-red-700' : 'text-slate-700',
                        'rounded-md align-baseline',
                      )}
                    >
                      <td
                        className={classNames(
                          hasConflict &&
                            'text-slate-400 line-through decoration-slate-400',
                          'py-1 pl-2',
                        )}
                      >
                        {format(dateFromIsoString(h.date), 'MMMM do')}
                      </td>
                      <td
                        className={classNames(
                          hasConflict &&
                            'text-slate-400 line-through decoration-slate-400',
                          itemError && 'text-red-700',
                          'py-1 pl-8',
                        )}
                      >
                        {h.name}
                      </td>
                      <td className="pl-8 pr-2">
                        <Button
                          content="Import"
                          onClick={() => handleSingleImport(h)}
                          disabled={isImporting || hasConflict}
                          loading={isImporting}
                          compact
                          basic
                          size="mini"
                        />
                        {hasConflict && (
                          <span className="ml-0.5">
                            <Popup
                              position="top center"
                              size="small"
                              content="Date conflict"
                              trigger={
                                <Icon
                                  size="small"
                                  color="grey"
                                  name="question circle outline"
                                  className="cursor-help"
                                />
                              }
                            />
                          </span>
                        )}
                      </td>
                    </tr>
                    {itemError && (
                      <tr className="relative -top-1">
                        <td colSpan={3} className="pl-2 text-sm text-red-700">
                          <ExclamationCircleIcon className="relative -top-0.5 inline-block h-4 w-4" />{' '}
                          {itemError}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
      )}

      <div className="mb-1 ml-0.5 mt-6 flex gap-1">
        <Button
          disabled={nonConflictingHolidays.length === 0 || isSubmitting}
          onClick={handleImportAll}
          content={
            nonConflictingHolidays.length === 0
              ? 'Import All'
              : `Import All (${nonConflictingHolidays.length})`
          }
          primary
        />
        <Button
          disabled={isSubmitting}
          onClick={props.onClose}
          content="Close"
          basic
        />
      </div>
    </section>
  )
}
