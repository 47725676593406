import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

const getStyle = (isDragging, def) => {
  if (!isDragging) {
    return {
      ...def,
      backgroundColor: 'red!important',
      border: 'solid 20px red!important',
    }
  }

  return {
    ...def,
    boxShadow: 'var(--active-shadow)',
    // border: "solid 1px var(--primary)",
    border: 'solid 20px red!important',
  }
}

const Card = ({ index, id, children, onClick, isRotten }) => {
  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
            isRotten,
          )}
          className="fx card"
          onClick={onClick}
        >
          {children}
        </div>
      )}
    </Draggable>
  )
}

export default Card
