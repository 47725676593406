import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTalentApi } from '../../store/mainContext'
import DataFilter from './dataFilter'
import SearchFilter from './searchFilter'
import SimpleFilter from './simpleFilter'

const FilterBar = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 10px 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  border-bottom: solid 1px var(--border-grey);
  margin-bottom: -1px;
  z-index: 1;
`

const FilterLabel = styled.div`
  margin: 5px;
  text-align: center;
`

const initialEnglishFilterOptions = [
  { key: 0, text: 'Any stars' },
  { key: 1, text: '1 star or more' },
  { key: 2, text: '2 stars or more' },
  { key: 3, text: '3 stars or more' },
  { key: 4, text: '4 stars or more' },
  { key: 5, text: '5 stars' },
]

const yearsInTheRoleOptions = [
  { key: 0, value: 0, text: 'Any years in the role' },
  { key: 1, value: 1, text: 'More than 1 year in the role' },
  ...[2, 3, 5, 8, 10].map((x) => ({
    key: x,
    value: x,
    text: `More than ${x} years in the role`,
  })),
]

const yearsInTheIndustryOptions = [
  { key: 0, value: 0, text: 'Any years in any role' },
  { key: 1, value: 1, text: 'More than 1 year in any role' },
  ...[2, 3, 5, 8, 10].map((x) => ({
    key: x,
    value: x,
    text: `More than ${x} years in any role`,
  })),
]

const CandidatesFilter = ({ onFilterChange }) => {
  const [filter, setFilter] = useState({})

  const [englishFilterOptions, setEnglishFilterOptions] = useState()

  const api = useTalentApi()

  useEffect(() => {
    api.get('englishLevels').then(({ data }) => {
      const newOptions = initialEnglishFilterOptions.map((x) => ({
        ...x,
        value: data.filter((level) => level.value >= x.key).map((l) => l._id),
      }))
      newOptions[0].value = [] // empty array for filter "any stars".
      setEnglishFilterOptions(newOptions)
    })
  }, [api])

  const handleChange = (additionalFilter) => {
    const newFilter = {
      ...filter,
      ...additionalFilter,
    }

    setFilter(newFilter)
    onFilterChange && onFilterChange(newFilter)
  }

  const handleChangeYears = (_, name, value) => {
    const newFilter = {}

    if (value === 0) {
      newFilter[name] = null
      handleChange(newFilter)
      return
    }

    newFilter[name] = { $gte: value }

    handleChange(newFilter)
  }

  return (
    <FilterBar>
      <FilterLabel>Looking for</FilterLabel>
      <DataFilter
        onFilterChange={handleChange}
        name="seniority"
        label="All Levels"
        collection="seniority"
        optionOrderBy={(x) => x.value}
      />
      <DataFilter
        onFilterChange={handleChange}
        name="profiles"
        label="All Profiles"
        collection="profiles"
      />

      <FilterLabel>using</FilterLabel>

      <DataFilter
        onFilterChange={handleChange}
        name="mainTechs"
        label="All Techs"
        collection="techs"
      />

      <FilterLabel>as main techs, with</FilterLabel>

      {englishFilterOptions && (
        <SimpleFilter
          onFilterChange={handleChange}
          name="englishLevel"
          options={englishFilterOptions}
        />
      )}

      <FilterLabel>of english level,</FilterLabel>

      <SimpleFilter
        onFilterChange={handleChangeYears}
        name="yearsInTheRole"
        options={yearsInTheRoleOptions}
      />

      <FilterLabel>and</FilterLabel>

      <SimpleFilter
        onFilterChange={handleChangeYears}
        name="yearsInTheIndustry"
        options={yearsInTheIndustryOptions}
      />

      <FilterLabel>from</FilterLabel>

      <SearchFilter
        name="location"
        label="Anywhere"
        onFilterChange={handleChange}
      />
    </FilterBar>
  )
}

export default CandidatesFilter
