import { Placeholder } from 'semantic-ui-react'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { useContractsApi, useTalentApi } from '../../store/mainContext'
import { Collection } from '../../types'
import useSWR from 'swr'

interface Entity {
  _id: string
  name?: string
  title?: string
  number?: string | number
  [key: string]: any
}

interface Props {
  defaultText?: string
  collection: Collection
  entityId?: string
  entity?: Entity
}

const EntityLink = ({ entityId, entity, collection, defaultText }: Props) => {
  const contractsApi = useContractsApi()
  const talentApi = useTalentApi()

  const { data, isLoading, error } = useSWR<Entity>(
    // only fetch if we don't have an `entity` and we DO have an `entityId`
    entityId && !entity ? ['entity', collection, entityId] : null,
    () =>
      (collection === 'invoices' ? contractsApi : talentApi)
        .get<Entity>(`${collection}/${entityId}`)
        .then((response) => response.data),
    // if we do wave an initial `entity` use as fallback data
    { fallbackData: entity, shouldRetryOnError: false },
  )

  if (isLoading) {
    return (
      <Placeholder className="-mt-1.5">
        <Placeholder.Line length="long" className="m-0" />
      </Placeholder>
    )
  }

  if (!data) {
    return defaultText ? (
      <span>{defaultText}</span>
    ) : error ? (
      <span className="text-slate-400">&mdash;Not found&mdash;</span>
    ) : null
  }

  return (
    <Link to={`/${collection}/${data._id}`}>
      {data.name || data.title || data.number || 'No name'}
    </Link>
  )
}

export default EntityLink
