import { Button, Input } from 'semantic-ui-react'
import * as React from 'react'

import useField from '../../hooks/useField'

interface Props<V> {
  onSave(value: string): Promise<any>
  onClear(): Promise<any>
  initialValue: V
  disabled?: boolean
  error?: string
}

export default function StringField(props: Props<string | undefined | null>) {
  const { initialValue, onClear, onSave, disabled } = props

  const field = useField<string | undefined | null>({
    initialValue,
    onClear,
    onSave,
  })

  const cancel = field.cancel

  React.useEffect(() => {
    if (disabled) cancel()
  }, [disabled, cancel])

  if (field.isEditing) {
    return (
      <form
        spellCheck="false"
        onSubmit={(e) => {
          e.preventDefault()
          field.save()
        }}
      >
        <Input
          fluid
          value={field.value || ''}
          onChange={(_, p) => field.setValue(p.value)}
          disabled={field.isSaving}
          /* disable 1Password */
          input={<input data-1p-ignore />}
        />
        <div className="mb-2 mt-1">
          <Button
            content="Cancel"
            disabled={field.isSaving}
            onClick={field.cancel}
            size="mini"
            compact
            type="button"
          />
          <Button
            content="Save"
            disabled={field.isSaving}
            loading={field.isSaving}
            size="mini"
            compact
            primary
            type="submit"
          />
        </div>
        {props.error && (
          <div className="-mt-1 text-sm text-red-600">{props.error}</div>
        )}
      </form>
    )
  }

  return field.value ? (
    <div className="relative">
      <div className="leading-[38px]">{field.value}</div>
      <div className="absolute right-0 top-1 opacity-0 group-hover:opacity-100">
        <Button
          icon={field.isCopyingToClipboard ? 'check' : 'copy'}
          disabled={field.isCopyingToClipboard}
          size="tiny"
          compact
          onClick={() => field.copyToClipboard(field.value || '')}
          style={{
            backgroundColor: '#fff',
            border: 'solid 1px var(--border-grey)',
          }}
        />
        {!disabled && (
          <Button
            icon="pencil"
            size="tiny"
            compact
            onClick={field.edit}
            style={{
              backgroundColor: '#fff',
              border: 'solid 1px var(--border-grey)',
            }}
          />
        )}
      </div>
    </div>
  ) : (
    <div className="mt-[4px]">
      <button
        className="-mx-1.5 mt-1.5 px-1.5 py-1 text-[var(--primary)] hover:text-blue-900"
        onClick={field.edit}
      >
        + Add value
      </button>
      {props.error && (
        <div className="-mt-0.5 text-sm text-red-600">{props.error}</div>
      )}
    </div>
  )
}
