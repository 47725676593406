import React from 'react'
import SearchBox from './searchBox'
import RemoteValue from '../remoteValues/remoteValue'
import Period from '../period'
import styled from 'styled-components'

const Name = styled.p`
  font-size: 1.3em;
  margin-bottom: 3px;
  font-weight: bold;
`

const Sub = styled.div`
  color: #61605f;

  p {
    line-height: 22px;
  }
`

const SearchContacts = () => (
  <SearchBox
    collection="contacts"
    render={({ name, companyId, lastEvent }) => (
      <React.Fragment>
        <Name>{name}</Name>
        <Sub>
          <p>
            <RemoteValue
              id={companyId}
              collection="companies"
              predicate={(x) => x.name}
            />
          </p>

          <p>
            <b>Last Touch:</b> <Period date={lastEvent} />
          </p>
        </Sub>
      </React.Fragment>
    )}
  />
)

export default SearchContacts
