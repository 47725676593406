import React, { CSSProperties } from 'react'
import { Placeholder } from 'semantic-ui-react'
import useSWRImmutable from 'swr/immutable'

import { User, userSchema } from '../../types'
import { validateResponse } from '../../utils'
import { useAuthApi } from '../../store/mainContext'
import { ENV_VARS } from '../../env'

interface Props {
  height?: CSSProperties['width']
  image?: boolean
  short?: boolean
  id: User['_id']
}

const fallbackData = { name: 'Romebotie', image: '/img/bot.png' }

const UserName = (props: Props) => {
  const api = useAuthApi()

  const { data, isLoading } = useSWRImmutable<Pick<User, 'name' | 'image'>>(
    ['user', props.id],
    () => {
      // Romebotie user doesn't actually exist, so let's avoid the 404 and
      // return the fallback data in that case.
      // TODO: or maybe better to just create the user in the DB?
      if (ENV_VARS.REACT_APP_ROMEBOTIE_ID === props.id) {
        return fallbackData
      }
      return api
        .get(`users/${props.id}`)
        .then(validateResponse(userSchema))
        .catch(() => fallbackData)
    },
  )

  if (isLoading && props.image) {
    return (
      <img
        className="rounded-full"
        style={{ width: props.height, height: props.height }}
        src={fallbackData.image}
        alt={'loading...'}
      />
    )
  }

  if (isLoading) {
    return (
      <Placeholder className="-mt-1.5 inline-block w-20">
        <Placeholder.Line length="full" className="m-0" />
      </Placeholder>
    )
  }

  if (props.image && data) {
    return (
      <img
        className="rounded-full"
        style={{ width: props.height, height: props.height }}
        src={data.image}
        alt={data.name}
      />
    )
  }

  if (!data) return <React.Fragment></React.Fragment>

  return (
    <React.Fragment>
      {props.short ? data.name.split(' ')[0] : data.name}
    </React.Fragment>
  )
}

export default UserName
