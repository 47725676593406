import { useEffect, useState } from 'react'
import * as React from 'react'
import { Icon } from 'semantic-ui-react'

import { Company, Position } from '../../types'
import { useTalentApi } from '../../store/mainContext'

interface Props {
  positionId: Position['_id']
  showIcon?: boolean
  style?: React.HTMLAttributes<HTMLAnchorElement>['style']
}

const PositionLink = ({ positionId, style, showIcon }: Props) => {
  const [position, setPosition] = useState<Position & { company?: Company }>()

  const api = useTalentApi()

  useEffect(() => {
    if (!positionId) return
    api
      .get<Position>(`positions/${positionId}`)
      .then(({ data }: { data: Position & { company?: Company } }) =>
        data.companyId
          ? api
              .get<Company>(`companies/${data.companyId}`)
              .then(({ data: comp }) => {
                data.company = comp
                return data
              })
          : data,
      )
      .then((data) => setPosition(data))
      .catch((e) => console.warn(e))
  }, [positionId, api])

  if (!position) {
    return null
  }

  if (position.company) {
    return (
      <a
        rel="noreferrer"
        style={style}
        href={`/positions/${positionId}`}
        target="_blank"
      >
        {showIcon && <Icon name="file alternate outline" />} {position.number} -{' '}
        {position.company.name} {position.title}
      </a>
    )
  }

  return (
    <a
      rel="noreferrer"
      style={style}
      href={`/positions/${positionId}`}
      target="_blank"
    >
      {showIcon && <Icon name="file alternate outline" />} {position.number} -
      No Company - {position.title}
    </a>
  )
}

export default PositionLink
