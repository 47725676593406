import React, { useEffect, useState } from 'react'
import { Button, Icon, Input, Modal } from 'semantic-ui-react'
import styled from 'styled-components'
import { useContractsApi } from '../../store/mainContext'
import FormField from '../form/formField'

import DateSelect from '../form/dateSelect'

const Title = styled.h2`
  font-weight: bold !important;
`

const UpdateDealModal = ({ show, contractId, onCancel, onSuccess }) => {
  const api = useContractsApi()

  const [errors, setErrors] = useState()
  const [errorMessage, setErrorMessage] = useState()

  const [deal, setDeal] = useState({ contractId })

  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    setErrors(null)
    setDeal({ contractId })
    setIsSaving(false)
    setErrorMessage(null)
  }, [contractId, show])

  const handleSave = async () => {
    setIsSaving(true)

    try {
      const { data } = await api.post('deals', deal)

      onSuccess && onSuccess(data)
    } catch ({ response }) {
      if (response.data.fields) {
        setErrors(response.data.fields)
        return
      }

      setErrorMessage(response.data.message)
    } finally {
      setIsSaving(false)
    }
  }

  if (!show) {
    return null
  }

  return (
    <Modal
      onClose={onCancel}
      open={true}
      size="mini"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <Title>Update SOW</Title>

        <p>
          <b>This action has an impact on Payroll & Invoicing</b> so be serious
          about it.
        </p>

        <FormField label="Effective Date">
          <DateSelect
            name="effectiveDate"
            style={{ width: '100%' }}
            error={errors && errors.find((x) => x === 'effectiveDate')}
            onChange={(e, target) =>
              setDeal({ ...deal, effectiveDate: target.value })
            }
            value={deal.effectiveDate && new Date(deal.effectiveDate)}
            placeholder="Effective Date"
            disabled={isSaving}
          />
        </FormField>

        <FormField label="New Position">
          <Input
            fluid
            placeholder="Position..."
            autoFocus
            value={deal.position || ''}
            onChange={(e) => setDeal({ ...deal, position: e.target.value })}
            error={errors && errors.find((x) => x === 'position')}
            disabled={isSaving}
          />
        </FormField>

        <FormField label="New Fee (Monthly)">
          <Input
            type="number"
            style={{ width: '100%' }}
            iconPosition="left"
            placeholder="Monthly Fee..."
            value={deal.monthlySalary || ''}
            onChange={(e) =>
              setDeal({ ...deal, monthlySalary: Number(e.target.value) })
            }
            error={errors && errors.find((x) => x === 'monthlySalary')}
            disabled={isSaving}
          >
            <Icon name="dollar" />
            <input />
          </Input>
        </FormField>

        <FormField label="New Rate (Monthly)">
          <Input
            type="number"
            fluid={true}
            iconPosition="left"
            placeholder="Monthly Rate..."
            value={deal.monthlyRate || ''}
            onChange={(e) =>
              setDeal({ ...deal, monthlyRate: Number(e.target.value) })
            }
            error={errors && errors.find((x) => x === 'monthlyRate')}
            disabled={isSaving}
          >
            <Icon name="dollar" />
            <input />
          </Input>
        </FormField>

        {errorMessage && <p style={{ color: 'var(--red)' }}>{errorMessage}</p>}
      </Modal.Content>

      <Modal.Actions>
        <Button basic onClick={onCancel} disabled={isSaving}>
          Nervermind...
        </Button>
        <Button color="black" onClick={handleSave} loading={isSaving}>
          <Icon name="save" /> Save
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default UpdateDealModal
