import React, { useEffect, useState } from 'react'
import { useTalentApi } from '../../store/mainContext'

import SidePanel, { Section } from '../sidepanel/sidePanel'
import TrDate from '../sidepanel/TrDate'
import TrInput from '../sidepanel/trInput'
import TrCompanySelect from '../sidepanel/trCompanySelect'
import TrContactSelect from '../sidepanel/trContactSelect'
import TrUserSelect from '../sidepanel/trUserSelect'
import TrDataSelect from '../sidepanel/trDataSelect'
import SideAddButton from '../sidepanel/sideAddButton'

import SubmissionsMenu from './submissionsMenu'
import SubmissionsHistory from './submissionsHistory'

import Period from '../period'

import RequirePowers from '../requirePowers'
import POWERS from '../../powers'
// import RemoteValue from '../remoteValues/remoteValue'
import PositionLink from '../remoteValues/positionLink'

const Submissions = ({ candidateId, positionId }) => {
  const api = useTalentApi()

  const [isLoading, setIsLoading] = useState(true)
  const [isWorking, setIsWorking] = useState(false)

  const [submissions, setSubmissions] = useState([])

  const [selectedSubmission, setSelectedSubmission] = useState(null)

  const [showHistory, setShowHistory] = useState(false)

  const refresh = React.useCallback(async () => {
    const { data: allSubmissions } = await api.get(`/submissions`, {
      params: { candidateId },
    })

    const orderedSubmissions = allSubmissions.sort(
      (a, b) => b.createdOn - a.createdOn,
    )

    setSubmissions(orderedSubmissions)
    setSelectedSubmission(orderedSubmissions[0])

    setIsLoading(false)
  }, [api, candidateId])

  useEffect(() => {
    if (!candidateId) return
    refresh()
  }, [refresh, candidateId])

  const handleAdd = async () => {
    setIsWorking(true)

    if (!positionId) {
      // TODO: ver que ahcer aca.
      setIsWorking(false)
      return
    }

    const { data: position } = await api.get(`positions/${positionId}`)

    if (!position) {
      // TODO: ver que ahcer aca.
      setIsWorking(false)
      return
    }

    const newSubmission = {
      candidateId,
      positionId,
      submittedTo: position.hiringManagerId,
      customerId: position.companyId,
    }

    await api.post(`submissions`, newSubmission)
    await refresh()
    setIsWorking(false)
  }

  const handleChanged = (name, value) => {
    const newSub = { ...selectedSubmission }
    newSub[name] = value

    setSelectedSubmission(newSub)
    setSubmissions(submissions.map((x) => (x._id === newSub._id ? newSub : x)))
  }

  const handleRemove = async () => {
    setIsWorking(true)
    await api.delete(`submissions/${selectedSubmission._id}`)
    await refresh()
    setIsWorking(false)
  }

  if (isLoading) {
    return null
  }

  if (submissions.length === 0) {
    return positionId ? (
      <SideAddButton onClick={handleAdd}>Add Submission</SideAddButton>
    ) : null
  }

  if (showHistory) {
    return (
      <SubmissionsHistory
        selected={selectedSubmission}
        submissions={submissions}
        onGoBack={() => setShowHistory(false)}
        onSelect={(submission) => {
          setSelectedSubmission(submission)
          setShowHistory(false)
        }}
      />
    )
  }

  return (
    <Section
      icon="share square outline"
      title="Submissions"
      menu={
        <SubmissionsMenu
          onAdd={handleAdd}
          onShowHistory={() => setShowHistory(true)}
          onRemove={handleRemove}
        />
      }
    >
      {isWorking ? (
        <SidePanel>
          <tbody>
            <tr>
              <td colSpan={2}>Loading...</td>
            </tr>
          </tbody>
        </SidePanel>
      ) : (
        <SidePanel key={selectedSubmission._id}>
          <tbody>
            <TrDataSelect
              name="status"
              label="Status"
              value={selectedSubmission.status}
              dataCollection="submission-status"
              url={`submissions/${selectedSubmission._id}`}
              onSuccess={handleChanged}
              placeholder="Status..."
              orderBy={(x) => x.value}
            />

            <TrDate
              name="submittedOn"
              label="Submitted on"
              placeholder="submittedOn..."
              value={selectedSubmission.submittedOn}
              url={`submissions/${selectedSubmission._id}`}
              onSuccess={handleChanged}
              render={(x) => (
                <React.Fragment>
                  {new Date(x).toLocaleDateString('en-US')} (<Period date={x} />
                  )
                </React.Fragment>
              )}
            />

            <RequirePowers powers={[POWERS.customerSOWs]}>
              <TrInput
                name="rate"
                label="Rate"
                placeholder="Rate..."
                number={true}
                render={(v) => `$ ${v}`}
                value={selectedSubmission.rate}
                url={`submissions/${selectedSubmission._id}`}
                onSuccess={handleChanged}
              />
            </RequirePowers>

            <TrDataSelect
              name="submittedAs"
              label="Submitted as"
              value={selectedSubmission.submittedAs}
              dataCollection="seniority"
              url={`submissions/${selectedSubmission._id}`}
              onSuccess={handleChanged}
              placeholder="Seniority..."
              orderBy={(x) => x.value}
            />

            <TrContactSelect
              name="submittedTo"
              label="Submitted to"
              value={selectedSubmission.submittedTo}
              url={`submissions/${selectedSubmission._id}`}
              onSuccess={handleChanged}
              placeholder="Supervisor..."
              allowAdditions={true}
              render={(x) => x.name}
            />

            <TrCompanySelect
              name="customerId"
              label="Customer"
              value={selectedSubmission.customerId}
              url={`submissions/${selectedSubmission._id}`}
              onSuccess={handleChanged}
              search
              placeholder="Customer..."
              render={(x) => x.name}
            />

            <TrUserSelect
              name="submittedBy"
              label="Submitted By"
              value={selectedSubmission.submittedBy}
              url={`submissions/${selectedSubmission._id}`}
              onSuccess={handleChanged}
              placeholder="Submitted by..."
            />

            <TrInput
              key="notes"
              name="notes"
              label="Notes"
              value={selectedSubmission.notes}
              url={`submissions/${selectedSubmission._id}`}
              onSuccess={handleChanged}
              type="textarea"
              placeholder="Notes..."
            />

            <tr>
              <th>Position</th>
              <td>
                <PositionLink positionId={positionId} showIcon={true} />
              </td>
            </tr>
          </tbody>
        </SidePanel>
      )}
    </Section>
  )
}

export default Submissions
