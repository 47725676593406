import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { Confirm, Button, Icon } from 'semantic-ui-react'
import { ErrorMessage } from '@hookform/error-message'
import useSWRMutation from 'swr/mutation'
import { useForm } from 'react-hook-form'
import * as React from 'react'
import format from 'date-fns/format'

import { currencyFormat, dateFromIsoString } from '../../utils'
import { useContractsApi } from '../../store/mainContext'
import useTimeoffCoverage from '../../hooks/useTimeoffCoverage'
import { TimeoffRequest } from '../../types'

interface Props {
  onConfirm(): Promise<void>
  onCancel(): void
  request: TimeoffRequest
}

type FormData = Pick<TimeoffRequest, 'dates'>

export default function TimeoffRequestUpdateDialog(props: Props) {
  const { request, onCancel, onConfirm } = props

  const api = useContractsApi()
  const [isPending, setIsPending] = React.useState(false)
  const [error, setError] = React.useState<Error | null>(null)

  const coverage = useTimeoffCoverage(props.request._id)

  const { trigger } = useSWRMutation<
    TimeoffRequest[],
    Error,
    string[],
    TimeoffRequest['dates']
  >(['timeoff-request', request._id], (_, { arg }) =>
    api.patch(`timeoff-requests/${request._id}`, { dates: arg }),
  )

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormData>({
    defaultValues: { dates: request.dates },
  })

  const onSubmit = (data: FormData) => {
    setError(null)
    setIsPending(true)
    trigger(data.dates)
      .then(() => onConfirm())
      .then(() => onCancel())
      .catch((err) => setError(err))
      .finally(() => setIsPending(false))
  }

  return (
    <Confirm
      header="Timeoff Request Dates"
      content={
        <div className="px-6 pb-4">
          <p>
            If the candidate was unable to consume an already approved day off
            for some reason, you can remove it from the request.
          </p>
          <p className="font-semibold">
            Uncheck the dates you want to refund to the candidate PTO balance.
          </p>
          <form onSubmit={handleSubmit(onSubmit)} id="request-update">
            {request.dates.map((date) => {
              const tx = (coverage.data || []).find(
                (item) => item.date === date && !item.coveredByPtoPolicy,
              )
              const isNotCovered = Boolean(tx)
              return (
                <div key={date} className="ml-4">
                  <label className="inline-block cursor-pointer py-1.5 text-lg">
                    <input
                      className="relative -left-0.5 -top-0.5 mr-1 h-5 w-5 cursor-pointer rounded disabled:opacity-50"
                      disabled={isPending}
                      value={date}
                      type="checkbox"
                      {...register('dates', {
                        validate: (datesArr) =>
                          datesArr.length > 0 ||
                          'At least one date must be selected',
                      })}
                    />{' '}
                    {format(dateFromIsoString(date), 'PPPP')}{' '}
                    {isNotCovered && (
                      <span className="ml-3 text-base text-red-600">
                        <Icon
                          name="warning sign"
                          size="small"
                          className="relative -top-[1px]"
                        />
                        Not covered{' '}
                        {typeof tx?.dayValue === 'number' && (
                          <span>(Cost: {currencyFormat(tx.dayValue)})</span>
                        )}
                      </span>
                    )}
                  </label>
                </div>
              )
            })}
            <div className="ml-3 mt-2 min-h-[24.5px] text-lg text-red-600">
              <ErrorMessage errors={errors} name="dates" />
            </div>
          </form>

          {error && (
            <div className="mt-2 rounded-md bg-red-100 p-4 text-lg text-red-800">
              <ExclamationCircleIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle" />
              {error.message}
            </div>
          )}
        </div>
      }
      closeOnDimmerClick
      size="tiny"
      confirmButton={
        <Button
          disabled={isPending}
          loading={isPending}
          primary
          form="request-update"
        >
          Update request
        </Button>
      }
      onCancel={onCancel}
      open
    />
  )
}
