import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  //  ButtonGroup,
  Icon,
} from 'semantic-ui-react'
import styled from 'styled-components'

import { useTalentApi } from '../../store/mainContext'
import { useHistory } from 'react-router-dom'

import PageHeader from '../../components/pageHeader/pageHeader'
import PageLoader from '../../components/pageLoader'
// import OpenPositionModal from '../../components/modals/openPositionModal'
import SearchPositions from '../../components/search/searchPositions'
// import TextArea from '../../components/form/textArea'
import PositionCard from '../../components/positionsCard'
import PositionsFilter from '../../components/filters/positionsFilter'
import StickyNotes from '../../components/stickyNotes'

const PageContent = styled.div`
  margin: 0px 50px 50px 50px;
  display: flex;
  justify-content: space-between;
`

const Left = styled.div`
  /* background-color: green; */
  /* flex-shrink: 1 0; */
  width: 100%;
`

const Right = styled.div`
  width: 350px;
  min-width: 350px;
  /* background-color: red; */
  margin-left: 20px;
  border-left: dotted 1px var(--border-grey);
  padding-left: 20px;
  /* flex-shrink: 1 0; */
`

// const FilterLabel = styled.span`
//   margin-right: 15px;
// `

// const FilterBar = styled.div`
//   display: flex;
//   align-items: center;
// `

const Positions = () => {
  document.title = 'Positions'

  const history = useHistory()

  const [filter, setFilter] = useState({ status: 'open' })

  // const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [positions, setPositions] = useState([])

  // const [showOpenPositionsModal, setShowOpenPositionsModal] = useState(false)

  const api = useTalentApi()

  const handleCreatePosition = async () => {
    const { data } = await api.post(`positions`, {
      title: 'New Position',
      status: 'open',
    })

    history.push(`/positions/${data._id}`)
  }

  const refreshPositions = useCallback(
    async (params) => {
      const { data } = await api.get(`positions/pro`, { params })
      const { data: com } = await api.get(`companies`)

      const pos = data.map((p) => ({
        ...p,
        company: com.find((c) => c._id === p.companyId),
      }))

      setPositions(pos)
      // setPositions(pos.sort((a,b) => b.lastUpdateOn - a.lastUpdateOn));
    },
    [api],
  )

  // const handlePositionCreated = async (position) => {
  //   await refreshPositions(filter);
  //   setShowOpenPositionsModal(false);
  // }
  const handlePositionUpdated = async () => {
    // const newPositions = [
    //   ...positions.filter((p) => p._id !== updated._id),
    //   updated,
    // ]
    await refreshPositions(filter)
  }

  const handleFilterChange = async (f) => {
    setFilter(f)
    await refreshPositions(f)
  }

  useEffect(() => {
    refreshPositions(filter)
    setIsLoading(false)
  }, [filter, refreshPositions])

  if (isLoading) {
    return <PageLoader />
  }

  const map = (p, index) => (
    <PositionCard
      key={p._id}
      position={p}
      index={index}
      onUpdated={handlePositionUpdated}
    />
  )

  const highPriority = positions.filter((x) => x.isHighPriority).map(map)

  const normalPriority = positions.filter((x) => !x.isHighPriority).map(map)

  return (
    <React.Fragment>
      <PageHeader
        breadcrumb={[
          { text: 'Dashboard', link: '/' },
          { text: 'Positions', link: '/positions' },
        ]}
        sub="One board to hire them all."
        title="Positions"
        search={<SearchPositions />}
        actions={
          <React.Fragment>
            <Button basic onClick={handleCreatePosition}>
              <Icon name="add" /> Open Position
            </Button>
          </React.Fragment>
        }
      ></PageHeader>

      <PageContent>
        <Left>
          {/* {JSON.stringify(filter)} */}
          <PositionsFilter onFilterChange={handleFilterChange} />

          {filter.status !== 'closed' && (
            <React.Fragment>
              {highPriority.length > 0 && (
                <React.Fragment>
                  <h2>
                    <Icon name="level up" />
                    High Priority Positions
                  </h2>
                  <div style={{ marginBottom: 50 }}>{highPriority}</div>
                </React.Fragment>
              )}

              {normalPriority.length > 0 && (
                <React.Fragment>
                  <h2>
                    <Icon name="level down" />
                    Normal Priority Positions
                  </h2>
                  <div>{normalPriority}</div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}

          {filter.status === 'closed' && (
            <React.Fragment>
              <h2>Closed Positions</h2>

              <div>
                {positions.map((p, index) => (
                  <PositionCard
                    key={p}
                    position={p}
                    index={index}
                    onUpdated={handlePositionUpdated}
                  />
                ))}
              </div>
            </React.Fragment>
          )}
        </Left>

        <Right>
          <StickyNotes tag="positions" />

          {/* <Note
      <br /><br /><br /><br />
      
      <h2>Lastest news</h2>
      
      <p><Icon name="save" color="red" /> 2 hrs ago - <b>Leandro</b> interviewed <b>Martin Huber</b> for the <b>123 - Sr. React</b> position.</p>
      
      <p>1 hrs ago - <b>Constanza</b> presented <b><a href="#">Juan Manuel Molina</a></b> for the <b>123 - Sr. React</b> position.</p>
      
    <p>2 hrs ago - <b>Leandro</b> interviewed <b>Martin Huber</b> for the <b>123 - Sr. React</b> position.</p> */}
        </Right>
      </PageContent>

      {/* <OpenPositionModal 
    show={showOpenPositionsModal} 
    onCancel={() => setShowOpenPositionsModal(false)} 
    onPositionCreated={handlePositionCreated} /> */}
    </React.Fragment>
  )
}

export default Positions
