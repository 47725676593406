import { isWeekend, isSameDay, format } from 'date-fns'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { Controller, useForm } from 'react-hook-form'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { Button, Input } from 'semantic-ui-react'
import * as React from 'react'

import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'

import { classNames, dateFromIsoString } from '../../utils'
import { Company, Holiday } from '../../types'
import { useContractsApi } from '../../store/mainContext'

type FormData = {
  date: Date
  name: string
}

interface Props {
  selectedYearHolidays: Holiday[]
  selectedYear: number
  onSuccess(): Promise<void>
  companyId: Company['_id']
}

export default function AddHolidayForm(props: Props) {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [error, setError] = React.useState('')
  const api = useContractsApi()

  // input refs to manage focus in a smart way
  const dateInput = React.useRef<SemanticDatepicker>(null)
  const textInput = React.useRef<Input>(null)

  const { handleSubmit, control, reset } = useForm<FormData>({
    defaultValues: { date: undefined, name: '' },
  })

  const onSubmit = (data: FormData) => {
    setIsSubmitting(true)
    setError('')
    api
      .post('holidays', {
        customerId: props.companyId,
        ndate: data.date.getTime(),
        year: data.date.getFullYear(),
        date: format(data.date, 'yyyy-MM-dd'),
        name: data.name.trim(),
      })
      .then(() => props.onSuccess())
      .then(() => {
        reset()
        // wait before form values to settle before tiggering a focus change
        setTimeout(() => dateInput.current?.focusOnInput(), 50)
      })
      .catch((err) => setError(err.message))
      .finally(() => setIsSubmitting(false))
  }

  return (
    <div className="mt-4">
      <form className=" flex items-end" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="date"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <div>
              <label
                htmlFor={field.name}
                className={classNames(
                  props.selectedYearHolidays.length > 0 && 'sr-only',
                  'mb-0.5 ml-0.5 block text-base font-semibold text-slate-600',
                )}
              >
                Holiday Date
              </label>
              <SemanticDatepicker
                {...field}
                onChange={(_, { value }) => {
                  field.onChange(value)
                  if (value) textInput.current?.focus()
                }}
                minDate={new Date(props.selectedYear, 0, 1)}
                maxDate={new Date(props.selectedYear, 11, 31)}
                clearOnSameDateClick={false}
                filterDate={(date) =>
                  !(
                    isWeekend(date) ||
                    props.selectedYearHolidays.some((h) =>
                      isSameDay(date, dateFromIsoString(h.date)),
                    )
                  )
                }
                clearable={false}
                showToday={false}
                datePickerOnly
                disabled={isSubmitting}
                error={!!fieldState.error}
                ref={dateInput}
              />
            </div>
          )}
        />

        <Controller
          name="name"
          control={control}
          rules={{ validate: (v) => v.trim().length > 0 }}
          render={({ field, fieldState }) => (
            <div className="ml-2 mr-2 grow">
              <label
                htmlFor={field.name}
                className={classNames(
                  props.selectedYearHolidays.length > 0 && 'sr-only',
                  'mb-0.5 ml-0.5 block text-base font-semibold text-slate-600',
                )}
              >
                Holiday Name
              </label>
              <Input
                {...field}
                fluid
                disabled={isSubmitting}
                error={!!fieldState.error}
                id={field.name}
                ref={textInput}
                /* disable 1Password */
                input={<input data-1p-ignore />}
              />
            </div>
          )}
        />

        <Button
          className="relative -top-0.5"
          disabled={isSubmitting}
          loading={isSubmitting}
          primary
          basic
          type="submit"
          icon="plus"
        />
      </form>

      {error && (
        <div className="my-6 rounded-md  bg-red-100 p-4 text-red-800">
          <ExclamationCircleIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle" />
          {error}
        </div>
      )}
    </div>
  )
}
