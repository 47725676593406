import { Icon } from 'semantic-ui-react'
import React from 'react'

import SidePanel, { Section } from '../sidepanel/sidePanel'
import { Candidate } from '../../types'
import TrDataSelect from '../sidepanel/trDataSelect'
import RemoteValue from '../remoteValues/remoteValue'
import TrRecord from '../sidepanel/trRecord'
import TrBadges from '../sidepanel/trBadges'
import TrInput from '../sidepanel/trInput'
import TrFile from '../sidepanel/trFile'
import TrDate from '../sidepanel/TrDate'

const getAge = (dateString: number): number => {
  const today = new Date()
  const birthDate = new Date(dateString)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

interface Props {
  candidate: Candidate
  onChangeSuccess(property: keyof Candidate, value: any): void
}

const CandidateSidePanel = ({ candidate, onChangeSuccess }: Props) => (
  <React.Fragment>
    <Section icon="user circle" title="Personal Information">
      <SidePanel>
        <tbody>
          <TrInput
            key="name"
            name="name"
            label="Name"
            value={candidate.name}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
          />

          <TrInput
            key="email"
            name="email"
            label="Email"
            value={candidate.email}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
          />

          <TrInput
            key="southEmail"
            name="southEmail"
            label="South Email"
            value={candidate.southEmail}
            url={`candidates/${candidate._id}`}
            readOnly
            onSuccess={onChangeSuccess}
          />

          <TrInput
            key="whatsapp"
            name="whatsapp"
            label="WhatsApp"
            value={candidate.whatsapp}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
          />

          <TrInput
            key="address"
            name="address"
            label="Address"
            value={candidate.address}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
          />

          <TrInput
            key="location"
            name="location"
            label="Location"
            value={candidate.location}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
          />

          <TrDate
            key="birthdate"
            name="birthdate"
            label="Birth Date"
            value={candidate.birthdate}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
          />

          <tr>
            <th>Age</th>
            <td>{candidate.birthdate && getAge(candidate.birthdate)}</td>
          </tr>

          <TrInput
            key="emergencyContact"
            name="emergencyContact"
            label="Emergency Contact"
            value={candidate.emergencyContact}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
            type="textarea"
            placeholder="Emergency Contact..."
          />
        </tbody>
      </SidePanel>
    </Section>

    <Section icon="id badge outline" title="Profile">
      <SidePanel>
        <tbody>
          <TrBadges
            name="badges"
            value={candidate.badges}
            onSuccess={onChangeSuccess}
          />

          <TrInput
            key="summary"
            name="summary"
            label="Summary"
            value={candidate.summary}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
            type="textarea"
            placeholder="Summary..."
          />

          <TrDataSelect
            key="seniority"
            name="seniority"
            label="Level"
            value={candidate.seniority}
            url={`candidates/${candidate._id}`}
            dataCollection="seniority"
            onSuccess={onChangeSuccess}
            placeholder="Seniority..."
            orderBy={(x) => x.value}
          />

          <TrDataSelect
            key="profiles"
            name="profiles"
            label="Profiles"
            value={candidate.profiles}
            url={`candidates/${candidate._id}`}
            dataCollection="profiles"
            onSuccess={onChangeSuccess}
            multiple
            search
            placeholder="Profiles..."
            // color="google plus"
          />

          <TrDataSelect
            key="mainTechs"
            name="mainTechs"
            label="Main Techs"
            value={candidate.mainTechs}
            url={`candidates/${candidate._id}`}
            dataCollection="techs"
            onSuccess={onChangeSuccess}
            multiple
            search
            placeholder="Main techs..."
            // color="red"
          />

          <TrDataSelect
            key="secondaryTechs"
            name="secondaryTechs"
            label="Secondary Techs"
            value={candidate.secondaryTechs}
            url={`candidates/${candidate._id}`}
            dataCollection="techs"
            onSuccess={onChangeSuccess}
            multiple
            search
            placeholder="Secondary techs..."
          />

          <TrDataSelect
            key="englishLevel"
            name="englishLevel"
            label="English Level"
            value={candidate.englishLevel}
            url={`candidates/${candidate._id}`}
            dataCollection="englishLevels"
            onSuccess={onChangeSuccess}
            render={(level) => `${level?.value} - ${level?.text}`}
            placeholder="English Levels..."
            orderBy={(x) => x.value}
          />

          <TrRecord
            key="englishSample"
            name="englishSample"
            label="English Sample"
            value={candidate.englishSample}
            url={`candidates/${candidate._id}`}
            fileUrl={`cdn/english-samples-93udw/${candidate._id}`}
            onSuccess={onChangeSuccess}
          />

          <TrInput
            key="linkedin"
            name="linkedin"
            label="LinkedIn"
            value={candidate.linkedin}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
            render={(v) => (
              <a rel="noreferrer" href={v} target="_blank">
                {v}
              </a>
            )}
          />

          <TrFile
            key="resume"
            name="resume"
            label="Original Resume"
            value={candidate.resume}
            url={`candidates/${candidate._id}`}
            fileUrl={`cdn/resumes-yk91827/${candidate._id}`}
            onSuccess={onChangeSuccess}
          />

          <TrFile
            key="southResume"
            name="southResume"
            label="South Resume"
            value={candidate.southResume}
            url={`candidates/${candidate._id}`}
            fileUrl={`cdn/resumes-south/${candidate._id}`}
            onSuccess={onChangeSuccess}
          />

          <TrInput
            key="editableResume"
            name="editableResume"
            label="Editable Resume"
            value={candidate.editableResume}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
            render={(v) => (
              <a rel="noreferrer" href={v} target="_blank">
                <Icon name="file outline" />
                Open
              </a>
            )}
          />

          <TrInput
            key="visa"
            name="visa"
            label="Visa"
            value={candidate.visa}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
            placeholder="Visa..."
          />

          <TrInput
            key="visaDueDate"
            name="visaDueDate"
            label="Visa Due Date"
            value={candidate.visaDueDate}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
            placeholder="Visa Due Date..."
          />

          <TrInput
            key="codeChallenge"
            name="codeChallenge"
            label="Code Challenge"
            value={candidate.codeChallenge}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
            render={(v) => (
              <a rel="noreferrer" href={v} target="_blank">
                {v}
              </a>
            )}
          />

          <TrInput
            key="otherLinks"
            name="otherLinks"
            label={<React.Fragment>Links</React.Fragment>}
            value={candidate.otherLinks}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
            type="textarea"
            placeholder="GitHub, Portfolio, Blog..."
          />

          <TrInput
            key="yearsInTheRole"
            name="yearsInTheRole"
            label="Years in the role"
            value={candidate.yearsInTheRole}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
            number={true}
          />

          <TrInput
            key="yearsInTheIndustry"
            name="yearsInTheIndustry"
            label="Years in the industry"
            value={candidate.yearsInTheIndustry}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
            number={true}
          />

          <TrInput
            key="salary"
            name="salary"
            label="Intended Fee"
            value={candidate.salary}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
            render={(v) => `$ ${v}`}
            number={true}
          />

          <TrInput
            key="availability"
            name="availability"
            label="Availabiltity (Weeks)"
            value={candidate.availability}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
            number={true}
          />

          <TrInput
            key="referal"
            name="referal"
            label="Referal"
            value={candidate.referal}
            url={`candidates/${candidate._id}`}
            onSuccess={onChangeSuccess}
          />

          {candidate.referalCandidateId && (
            <tr>
              <th>Referred By</th>
              <td>
                <RemoteValue
                  collection="candidates"
                  id={candidate.referalCandidateId}
                  predicate={(x) => (
                    <a
                      rel="noreferrer"
                      href={`/candidates/${x._id}`}
                      target="_blank"
                    >
                      <Icon name="user circle outline" />
                      {x.name}
                    </a>
                  )}
                />
              </td>
            </tr>
          )}

          <tr>
            <th>Added On</th>
            <td>
              {new Date(candidate.createdOn).toLocaleDateString(undefined, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </td>
          </tr>
        </tbody>
      </SidePanel>
    </Section>
  </React.Fragment>
)

export default CandidateSidePanel
