import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { Loader, Popup } from 'semantic-ui-react'
import { isPast } from 'date-fns'
import * as React from 'react'
import format from 'date-fns/format'

import { classNames, currencyFormat, dateFromIsoString } from '../../utils'
import useTimeoffCoverage from '../../hooks/useTimeoffCoverage'
import { TimeoffRequest } from '../../types'
import useTimeoffRequest from '../../hooks/useTimeoffRequest'

interface Props {
  requestId: TimeoffRequest['_id']
}

export default function TimeoffRequestDetails(props: Props) {
  const { data, isLoading, error } = useTimeoffRequest(props.requestId)
  const coverage = useTimeoffCoverage(props.requestId)

  const totalCost = (coverage.data || []).reduce(
    (sum, { dayValue }) => sum + (dayValue || 0),
    0,
  )

  return (
    <section>
      {data && (
        <div className="mt-4">
          <div className="mb-1 text-sm font-semibold uppercase text-slate-500">
            Requested {data.dates.length === 1 ? 'Date' : 'Dates'}{' '}
            <span className="ml-0.5">({data.dates.length})</span>
          </div>

          <div className="break-words">
            {data.dates.map((day, i) => {
              const tx = (coverage.data || []).find(
                (item) => item.date === day && !item.coveredByPtoPolicy,
              )
              const isNotCovered = Boolean(tx)
              return (
                <span
                  className={classNames(
                    isNotCovered
                      ? 'cursor-help text-red-600'
                      : isPast(dateFromIsoString(day))
                        ? 'text-slate-400'
                        : 'text-slate-900',
                    'whitespace-nowrap text-xl',
                  )}
                  key={day}
                >
                  <Popup
                    content={
                      <div>
                        Not covered by PTO policy
                        {typeof tx?.dayValue === 'number' && (
                          <div>Cost: {currencyFormat(tx.dayValue)}</div>
                        )}
                      </div>
                    }
                    position="top center"
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0}
                    disabled={!isNotCovered}
                    trigger={
                      <span
                        className={classNames(
                          isNotCovered &&
                            'underline decoration-red-400 decoration-dotted underline-offset-4',
                        )}
                      >
                        {format(dateFromIsoString(day), 'MMM do')}
                      </span>
                    }
                  />

                  {i < data.dates.length - 1 && (
                    <span className="mr-2 text-slate-500">,</span>
                  )}
                </span>
              )
            })}
            {totalCost > 0 && (
              <div className="-mb-2 mt-1 text-sm text-red-700">
                Total Cost: {currencyFormat(totalCost)}
              </div>
            )}
          </div>

          {data.comments ? (
            <div className="mt-7">
              <div className="text-sm font-semibold uppercase text-slate-500">
                Candidate comments
              </div>
              <div className="mt-1 text-xl text-slate-900">{data.comments}</div>
            </div>
          ) : (
            <div className="pt-6 text-base italic text-slate-500">
              Candidate left no comments about this request.
            </div>
          )}
        </div>
      )}

      {isLoading && (
        <div className="text-center">
          <Loader active inline size="small" />
        </div>
      )}

      {error && !data && (
        <div className="my-4 rounded-md  bg-red-100 p-4 text-red-800">
          <ExclamationCircleIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle" />
          {error.message}
        </div>
      )}
    </section>
  )
}
