import React, { useRef, useState, useEffect } from 'react'
import {
  //  Input,
  Button,
  //  Form,
  Progress,
  // Icon,
} from 'semantic-ui-react'
import { useTalentApi } from '../../store/mainContext'
import './trFile.css'

// import TrEditButton from "./trEditButton";
// import styled from "styled-components";
// import TrCopyButton from "./trCopyButton";
// import { timeout } from "../../utils";

const TrRecord = ({ name, label, value, onSuccess, fileUrl, url }) => {
  const api = useTalentApi()
  const [audio, setAudio] = useState(value)

  const [recording, setRecording] = useState(false)
  const [time, setTime] = useState(-1)

  const [mediaRecorder, setMediaRecorder] = useState()

  const [progress, setProgress] = useState()

  const record = React.useCallback(async () => {
    if (time === -1) {
      return
    }

    if (time > 15) {
      mediaRecorder.stop()
      setTime(-1)
      return
    }

    await new Promise((resolve) =>
      setTimeout(() => {
        setTime(time + 1)
        resolve()
      }, 1000),
    )
  }, [time, mediaRecorder])

  useEffect(() => {
    record()
  }, [record])

  const handleRecord = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    const rec = new MediaRecorder(stream)

    const chunks = []

    rec.ondataavailable = (e) => chunks.push(e.data)

    rec.onstop = async () => {
      stream
        .getTracks() // get all tracks from the MediaStream
        .forEach((track) => track.stop()) // stop each of them

      const blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' })

      // const audioUrl = window.URL.createObjectURL(blob);

      setProgress(1)

      // setAudio(audioUrl);

      const formData = new FormData()
      formData.append('file', blob)

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (e) => setProgress((e.loaded / e.total) * 100),
      }

      const { data } = await api.post(fileUrl, formData, config)

      const obj = {}
      obj[name] = data.location

      await api.patch(url, obj)

      setProgress(null)
      setRecording(false)
      setAudio(`${data.location}?d=${Date.now()}`)
      onSuccess(name, data.location)
    }

    setMediaRecorder(rec)
    setRecording(true)
    setTime(0)
    rec.start()
  }

  if (progress) {
    return (
      <tr>
        <th>{label}</th>
        <td
          style={{
            padding: 12,
          }}
        >
          <Progress
            className="uploadProgressBar"
            percent={progress}
            indicating
          />
        </td>
      </tr>
    )
  }

  if (recording) {
    return (
      <tr>
        <th>{label}</th>
        <td style={{ padding: 12 }}>
          <Button
            size="tiny"
            color="red"
            icon="circle"
            content={`00:${time.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}`}
          />
        </td>
      </tr>
    )
  }

  if (audio) {
    return (
      <tr>
        <th>{label}</th>
        <td style={{ padding: 12, display: 'flex' }}>
          {/* <audio src={audio} controls /> */}

          <AudioPlayer src={audio} />

          <Button
            icon="circle"
            basic
            size="tiny"
            color="red"
            content="Rec"
            onClick={handleRecord}
            style={{ marginLeft: 10 }}
          />
        </td>
      </tr>
    )
  }

  return (
    <tr>
      <th>{label}</th>
      <td>
        <Button
          icon="circle"
          basic
          content="Rec"
          color="red"
          size="tiny"
          style={{ marginRight: 0 }}
          onClick={handleRecord}
        />
      </td>
    </tr>
  )
}

const AudioPlayer = ({ src }) => {
  const player = useRef(new Audio(src))

  const [isPlaying, setIsPlaying] = useState(false)

  const handlePlay = () => {
    setIsPlaying(true)
    player.current.play()
  }

  const handleStop = () => {
    player.current.pause()
    player.current.currentTime = 0
    setIsPlaying(false)
  }

  player.current.onended = handleStop

  if (isPlaying) {
    return (
      <Button
        color="yellow"
        icon="stop"
        size="tiny"
        onClick={handleStop}
        content="Stop"
      />
    )
  }

  return (
    <Button
      color="green"
      icon="play"
      size="tiny"
      onClick={handlePlay}
      content="Play"
    />
  )
}

export default TrRecord
