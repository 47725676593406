import { Redirect, useParams } from 'react-router'
import { Helmet } from 'react-helmet'
import * as React from 'react'
import useSWR from 'swr'
import { z } from 'zod'

import { validateResponse } from '../../utils'
import { useContractsApi } from '../../store/mainContext'
import { payrollSchema } from '../../types'
import { useToasts } from '../../components/toasts/ToastsProvider'
import PageHeader from '../../components/pageHeader/pageHeader'
import PageLoader from '../../components/pageLoader'

export default function PayrollPage() {
  const { payrollId } = useParams<{ payrollId?: string }>()
  if (!payrollId) {
    throw new Error('`payrollId` param must be present in the URL.')
  }

  const { addToast } = useToasts()

  const api = useContractsApi()

  const payroll = useSWR(['invoice', payrollId], () =>
    api
      .get(`payrolls/${payrollId}`)
      .then(validateResponse(z.object({ payroll: payrollSchema }))),
  )

  if (payroll.isLoading) return <PageLoader />

  if (!payroll.data) {
    addToast('Payroll not found', { variant: 'danger' })
    return <Redirect to="/payrolls" />
  }

  return (
    <>
      <Helmet>
        <title>{payroll.data?.payroll.name} - Payrolls</title>
      </Helmet>

      <PageHeader
        title={payroll.data.payroll.name}
        breadcrumb={[
          { text: 'Dashboard', link: '/' },
          { text: 'Payrolls', link: '/payrolls' },
          { text: payroll.data.payroll.name },
        ]}
      />

      <div className="mx-14 mb-14">
        <pre className="bg-slate-100 p-2 text-sm">
          {JSON.stringify(payroll.data.payroll, null, 2)}
        </pre>
      </div>
    </>
  )
}
