import { Button, Confirm } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { AxiosError } from 'axios'
import { format } from 'date-fns'
import * as React from 'react'

import { useContractsApi } from '../../store/mainContext'
import InvoiceStatusLabel from './InvoiceStatusLabel'
import { useToasts } from '../toasts/ToastsProvider'
import { Invoice } from '../../types'

type Modal =
  | 'confirm-dismiss'
  | 'confirm-delete'
  | 'confirm-send'
  | 'confirm-paid'

function getSuccessMsg(status: Invoice['status']) {
  if (status === 'Waiting for approval') return 'Invoice marked for review'
  if (status === 'Dismissed') return 'Invoice Dismissed'
  if (status === 'Approved') return 'Invoice Approved'
  if (status === 'Draft') return 'Invoice converted back to Draft'
  if (status === 'Paid') return 'Invoice marked as Paid'
  if (status === 'Sent') return 'Invoice Sent'
  return 'Invoice status changed'
}

interface Props {
  isPublishing: boolean
  onSuccess(): Promise<any>
  onPublish(): Promise<any>
  invoice: Invoice
}

export default function InvoiceActions(props: Props) {
  const { invoice, onSuccess, isPublishing, onPublish } = props
  const { addToast } = useToasts()
  const history = useHistory()
  const api = useContractsApi()

  const [currentModal, setCurrentModal] = React.useState<Modal | null>(null)

  const [isChangingStatus, setIsChangingStatus] = React.useState<
    Invoice['status'] | 'delete' | null
  >(null)

  const handleStatusChange = async (status: Invoice['status']) => {
    setIsChangingStatus(status)
    return api
      .patch(`/invoices/${invoice._id}/status`, {
        status,
        ...(status === 'Sent' ? { sentOn: Date.now() } : {}),
        ...(status === 'Paid' ? { paidOn: Date.now() } : {}),
      })
      .then(() => onSuccess())
      .then(() => setCurrentModal(null))
      .then(() => addToast(getSuccessMsg(status), { variant: 'success' }))
      .catch((e: AxiosError) => {
        addToast(e.response?.data.message || e.message, { variant: 'warning' })
      })
      .finally(() => setIsChangingStatus(null))
  }

  const handleDelete = async () => {
    setIsChangingStatus('delete')
    return api
      .delete(`/invoices/${invoice._id}`)
      .then(() => addToast('Invoice deleted', { variant: 'success' }))
      .then(() => history.push('/invoices'))
      .catch((err) => addToast(err.message, { variant: 'danger' }))
      .finally(() => setIsChangingStatus(null))
  }

  const canRequestApproval = invoice.status === 'Draft'
  const canMarkAsPaid = invoice.status === 'Sent'
  const canApprove = invoice.status === 'Waiting for approval'
  const canDismiss = invoice.status === 'Sent'
  const canDelete = invoice.status === 'Draft'
  const canSend = invoice.status === 'Approved'
  const canSetAsDraft =
    invoice.status === 'Waiting for approval' || invoice.status === 'Approved'

  return (
    <div className="flex items-center justify-between">
      <div className="relative ml-0.5 grow">
        <span className="inline-block pl-0.5 pr-2 text-sm font-semibold uppercase text-slate-500 [letter-spacing:1px]">
          Status:
        </span>
        <InvoiceStatusLabel invoice={invoice} />
        {invoice.paidOn ? (
          <span className="ml-1 text-sm text-slate-500/80">
            on {format(invoice.paidOn, 'PPP')}
          </span>
        ) : (
          invoice.sentOn && (
            <span className="ml-1 text-sm text-slate-500/80">
              on {format(invoice.sentOn, 'PPP')}
            </span>
          )
        )}
        {invoice.status === 'Draft' &&
          invoice.lastUpdateOn !== invoice.createdOn && (
            <div className="absolute -mt-0.5 ml-0.5 text-xs text-slate-500/90">
              Last saved on {format(invoice.lastUpdateOn, 'Ppp')}
            </div>
          )}
      </div>

      <div className="flex min-h-[33.41px] gap-1">
        {canDelete && (
          <Button
            onClick={() => setCurrentModal('confirm-delete')}
            disabled={isChangingStatus !== null || isPublishing}
            type="button"
            icon="trash alternate outline"
            size="small"
            color="red"
            basic
          />
        )}
        {canSetAsDraft && (
          <Button
            content="Convert to Draft"
            onClick={() => handleStatusChange('Draft')}
            disabled={isChangingStatus !== null}
            loading={isChangingStatus === 'Draft'}
            type="button"
            size="small"
            basic
          />
        )}
        {canRequestApproval && (
          <Button
            content="Request Approval"
            disabled={isPublishing}
            loading={isPublishing}
            onClick={onPublish}
            type="button"
            size="small"
            primary
          />
        )}
        {canApprove && (
          <Button
            content="Approve"
            onClick={() => handleStatusChange('Approved')}
            loading={isChangingStatus === 'Approved'}
            disabled={isChangingStatus !== null}
            type="button"
            icon="checkmark"
            size="small"
            color="green"
            compact
          />
        )}
        {canSend && (
          <Button
            content="Send"
            onClick={() => setCurrentModal('confirm-send')}
            disabled={isChangingStatus !== null}
            type="button"
            icon="paper plane outline"
            color="green"
            size="small"
          />
        )}
        {canDismiss && (
          <Button
            content="Dismiss"
            onClick={() => setCurrentModal('confirm-dismiss')}
            disabled={isChangingStatus !== null}
            type="button"
            size="small"
            basic
          />
        )}
        {canMarkAsPaid && (
          <Button
            content="Mark as Paid"
            onClick={() => setCurrentModal('confirm-paid')}
            disabled={isChangingStatus !== null}
            type="button"
            icon="dollar"
            color="green"
            size="small"
          />
        )}
      </div>

      <Confirm
        header="Hey... removing the invoice?"
        content="This action cannot be undone."
        closeOnDimmerClick={isChangingStatus !== 'delete'}
        closeOnEscape={isChangingStatus !== 'delete'}
        onCancel={() => setCurrentModal(null)}
        onConfirm={handleDelete}
        open={currentModal === 'confirm-delete'}
        cancelButton={
          <Button disabled={isChangingStatus === 'delete'} content="Cancel" />
        }
        confirmButton={
          <Button
            disabled={isChangingStatus === 'delete'}
            loading={isChangingStatus === 'delete'}
            content="Sure, remove"
            primary={false}
            color="red"
          />
        }
        size="tiny"
      />

      <Confirm
        header="Hey... sending the invoice?"
        content={
          <div className="px-6 text-base">
            <div>Please note that the invoice</div>
            <ul className="list-disc space-y-2 py-4 pl-5">
              <li>will be sent as a PDF file to the customer,</li>
              <li>
                will change its status to{' '}
                <InvoiceStatusLabel invoice={{ ...invoice, status: 'Sent' }} />,
              </li>
              <li>
                will no longer be able to be converted back to{' '}
                <InvoiceStatusLabel invoice={{ ...invoice, status: 'Draft' }} />{' '}
                or deleted.
              </li>
            </ul>
          </div>
        }
        closeOnDimmerClick={isChangingStatus !== 'Sent'}
        closeOnEscape={isChangingStatus !== 'Sent'}
        onCancel={() => setCurrentModal(null)}
        onConfirm={() => handleStatusChange('Sent')}
        open={currentModal === 'confirm-send'}
        cancelButton={
          <Button disabled={isChangingStatus === 'Sent'} content="Cancel" />
        }
        confirmButton={
          <Button
            disabled={isChangingStatus === 'Sent'}
            loading={isChangingStatus === 'Sent'}
            content="Sure, send the invoice"
            primary={false}
            color="green"
          />
        }
        size="tiny"
      />

      <Confirm
        header="Hey... dismissing the invoice?"
        content={
          <div className="px-6 text-base">
            <div>Please note that the invoice</div>
            <ul className="list-disc space-y-2 py-4 pl-5">
              <li>
                will change the invoice status to{' '}
                <InvoiceStatusLabel
                  invoice={{ ...invoice, status: 'Dismissed' }}
                />
                ,
              </li>
              <li>
                will no longer be able to be converted back to{' '}
                <InvoiceStatusLabel invoice={{ ...invoice, status: 'Draft' }} />
                , marked as{' '}
                <InvoiceStatusLabel invoice={{ ...invoice, status: 'Paid' }} />{' '}
                or deleted.
              </li>
            </ul>
          </div>
        }
        closeOnDimmerClick={isChangingStatus !== 'Dismissed'}
        closeOnEscape={isChangingStatus !== 'Dismissed'}
        onCancel={() => setCurrentModal(null)}
        onConfirm={() => handleStatusChange('Dismissed')}
        open={currentModal === 'confirm-dismiss'}
        cancelButton={
          <Button
            disabled={isChangingStatus === 'Dismissed'}
            content="Cancel"
          />
        }
        confirmButton={
          <Button
            disabled={isChangingStatus === 'Dismissed'}
            loading={isChangingStatus === 'Dismissed'}
            content="Sure, dismiss the invoice"
            primary={false}
            color="green"
          />
        }
        size="tiny"
      />

      <Confirm
        header="Hey... marking as paid?"
        content={
          <div className="px-6 text-base">
            <div>Please note that the invoice</div>
            <ul className="list-disc space-y-2 py-4 pl-5">
              <li>
                will change the invoice status to{' '}
                <InvoiceStatusLabel invoice={{ ...invoice, status: 'Paid' }} />,
              </li>
              <li>
                will no longer be able to be converted back to{' '}
                <InvoiceStatusLabel invoice={{ ...invoice, status: 'Draft' }} />
                ,{' '}
                <InvoiceStatusLabel
                  invoice={{ ...invoice, status: 'Dismissed' }}
                />{' '}
                or deleted.
              </li>
            </ul>
          </div>
        }
        closeOnDimmerClick={isChangingStatus !== 'Paid'}
        closeOnEscape={isChangingStatus !== 'Paid'}
        onCancel={() => setCurrentModal(null)}
        onConfirm={() => handleStatusChange('Paid')}
        open={currentModal === 'confirm-paid'}
        cancelButton={
          <Button disabled={isChangingStatus === 'Paid'} content="Cancel" />
        }
        confirmButton={
          <Button
            disabled={isChangingStatus === 'Paid'}
            loading={isChangingStatus === 'Paid'}
            content="Sure, mark as paid"
            primary={false}
            color="green"
          />
        }
        size="tiny"
      />
    </div>
  )
}
