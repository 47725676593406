import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { Button } from 'semantic-ui-react'
import { format } from 'date-fns'
import * as React from 'react'

import useField from '../../hooks/useField'

interface Props<V> extends Partial<SemanticDatepickerProps> {
  onSave(value: number): Promise<any>
  onClear(): Promise<any>
  initialValue: V
  disabled?: boolean
  error?: string
}

export default function DateField(props: Props<number | undefined | null>) {
  const { initialValue, onClear, onSave, disabled, ...other } = props

  const field = useField<number | undefined | null>({
    initialValue,
    onClear,
    onSave,
  })

  const cancel = field.cancel

  React.useEffect(() => {
    if (disabled) cancel()
  }, [disabled, cancel])

  if (field.isEditing) {
    return (
      <form
        className="[&>.field]:!block"
        onSubmit={(e) => {
          e.preventDefault()
          field.save()
        }}
      >
        <SemanticDatepicker
          onChange={(_, { value }) => {
            field.setValue(value instanceof Date ? value.getTime() : undefined)
          }}
          clearOnSameDateClick={false}
          clearable={false}
          disabled={field.isSaving}
          datePickerOnly
          className="w-full"
          value={field.value ? new Date(field.value) : undefined}
          pointing="right"
          {...other}
        />
        <div className="mt-1">
          <Button
            content="Cancel"
            disabled={field.isSaving}
            onClick={field.cancel}
            size="mini"
            compact
            type="button"
          />
          <Button
            content="Save"
            disabled={field.isSaving}
            loading={field.isSaving}
            size="mini"
            compact
            primary
            type="submit"
          />
        </div>
        {props.error && (
          <div className="mt-0.5 text-sm text-red-600">{props.error}</div>
        )}
      </form>
    )
  }

  return field.value ? (
    <div className="relative">
      <div className="leading-[38px]">{format(field.value, 'yyyy-MM-dd')}</div>
      <div className="absolute right-0 top-1 opacity-0 group-hover:opacity-100">
        <Button
          icon={field.isCopyingToClipboard ? 'check' : 'copy'}
          disabled={field.isCopyingToClipboard}
          size="tiny"
          compact
          onClick={() =>
            field.copyToClipboard(
              field.value ? format(field.value, 'yyyy-MM-dd') : '',
            )
          }
          style={{
            backgroundColor: '#fff',
            border: 'solid 1px var(--border-grey)',
          }}
        />
        {!disabled && (
          <Button
            icon="pencil"
            size="tiny"
            compact
            onClick={field.edit}
            style={{
              backgroundColor: '#fff',
              border: 'solid 1px var(--border-grey)',
            }}
          />
        )}
      </div>
    </div>
  ) : (
    <div className="mt-[4px]">
      <button
        className="-mx-1.5 mt-1.5 px-1.5 py-1 text-[var(--primary)] hover:text-blue-900"
        onClick={field.edit}
      >
        + Add value
      </button>
      {props.error && (
        <div className="-mt-0.5 text-sm text-red-600">{props.error}</div>
      )}
    </div>
  )
}
