import * as React from 'react'
import { Button, Confirm, Modal, SemanticCOLORS } from 'semantic-ui-react'

interface Props {
  show: boolean
  title: string
  question: string
  highlight?: string
  onConfirm: () => void
  onCancel: () => void
  confirmButtonColor?: SemanticCOLORS
}

const ConfirmModal = ({
  show,
  title,
  question,
  highlight,
  onConfirm,
  onCancel,
  confirmButtonColor,
}: Props) => (
  <Confirm
    open={show}
    header={title}
    content={
      <Modal.Content>
        <p>{question}</p>

        {highlight && (
          <p className="highlight" style={{ fontSize: 17 }}>
            <b>{highlight}</b>
          </p>
        )}

        <p>Are you sure?</p>
      </Modal.Content>
    }
    onCancel={onCancel}
    closeOnDimmerClick={true}
    size="tiny"
    onConfirm={onConfirm}
    confirmButton={
      <Button negative color={confirmButtonColor ?? 'red'}>
        Yeap... Lets do it.
      </Button>
    }
    cancelButton={<Button basic>Ugh... no, no yet.</Button>}
  />
)

export default ConfirmModal
