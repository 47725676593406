import { Placeholder } from 'semantic-ui-react'
import React, { CSSProperties } from 'react'

interface Props {
  marginTop?: CSSProperties['marginTop']
  items?: number
}

const FeedLoader = ({ items = 6, marginTop = 40 }: Props) => (
  <Placeholder fluid style={{ marginTop }}>
    {new Array(items).fill('').map((_, i) => (
      <Placeholder.Header image key={i}>
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Header>
    ))}
  </Placeholder>
)

export default FeedLoader
