import useSWR from 'swr'
import { z } from 'zod'

import { Contract, Deal, dealSchema } from '../types'
import { validateResponse } from '../utils'
import { useContractsApi } from '../store/mainContext'

export default function useDeals(contracts?: Contract[]) {
  const api = useContractsApi()
  return useSWR<Deal[], Error>(
    contracts ? ['deals/in', ...contracts] : null,
    () =>
      api
        .get(`/deals/in`, {
          params: { contractId: contracts?.map(({ _id }) => _id) },
          headers: {
            'astor-sortby': 'effectiveDate',
            'astor-sortby-dir': '-1',
          },
        })
        .then(validateResponse(z.array(dealSchema))),
  )
}
