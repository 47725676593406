import * as React from 'react'

import { Candidate, Contract } from '../../types'
import TimeoffMenu, { View } from './TimeoffMenu'
import TimeoffRequests from './TimeoffRequests'
import TimeoffHolidays from './TimeoffHolidays'
import TimeoffHistory from './TimeoffHistory'
import { Section } from '../sidepanel/sidePanel'

interface Props {
  candidateId: Candidate['_id']
  contractId: Contract['_id']
}

export default function TimeoffSubsection(props: Props) {
  const [selectedView, setSelectedView] = React.useState<View>('requests')

  return (
    <Section
      icon="smile outline"
      title="Time-off"
      subsection
      menu={<TimeoffMenu onViewChange={setSelectedView} />}
    >
      {selectedView === 'requests' && (
        <TimeoffRequests
          candidateId={props.candidateId}
          contractId={props.contractId}
        />
      )}

      {selectedView === 'history' && (
        <TimeoffHistory candidateId={props.candidateId} />
      )}

      {selectedView === 'holidays' && (
        <TimeoffHolidays candidateId={props.candidateId} />
      )}
    </Section>
  )
}
