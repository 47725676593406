import useSWR from 'swr'
import { z } from 'zod'

import { Company, companySchema } from '../types'
import { validateResponse } from '../utils'
import { useTalentApi } from '../store/mainContext'

export function useCompanies() {
  const api = useTalentApi()
  return useSWR<Company[], Error>('companies', () =>
    api.get('companies').then(validateResponse(z.array(companySchema))),
  )
}

export default function useCompany(companyId: Company['_id']) {
  const api = useTalentApi()
  return useSWR<Company, Error>(['company', companyId], () =>
    api.get(`companies/${companyId}`).then(validateResponse(companySchema)),
  )
}
