import { Button, ButtonGroup, Icon, Loader, Popup } from 'semantic-ui-react'
import ExclamationCircleIcon from '@heroicons/react/24/outline/ExclamationCircleIcon'
import React, { useState } from 'react'

import {
  PositionChangeEvent,
  SmallEvent,
  Event as EventComponent,
  StatusChangeEvent,
  UserEvent,
  PositionPausedResumeEvent,
  PositionStatusChangeEvent,
  ActionEvent,
} from './eventTypes/event'

import { Candidate, Collection, Task, Event } from '../../types'
import TaskComponent from './eventTypes/task'
import useEventFeed from '../../hooks/useEventFeed'
import AddTaskModal from '../modals/addTaskModal'
import AddLogModal from '../modals/addLogModal'
import PendingTask from './pendingTask'
import FeedEditor from './feedEditor/feedEditor'
import FeedLoader from './FeedLoader'
import Note from './eventTypes/note'

type Filter = 'all' | 'tasks' | 'user-events' | 'rome-events'

const filterEvents = (currentFilter: Filter) => (item: Event | Task) => {
  const isTask =
    item.type === 'pto-review' ||
    item.type === 'pto-cancel' ||
    item.type === 'task'

  const isRomeEvent = item.type !== 'user-event' && !isTask

  if (currentFilter === 'all') return true
  if (currentFilter === 'tasks' && isTask) return true
  if (currentFilter === 'user-events' && item.type === 'user-event') return true
  if (currentFilter === 'rome-events' && isRomeEvent) return true

  return false
}

interface Props {
  relatedCollection: Collection
  relatedId: Candidate['_id']
  showEditor?: boolean
}

const Feed = ({ relatedId, relatedCollection, showEditor = true }: Props) => {
  const { data, isLoading, mutate, error, isValidating } = useEventFeed({
    relatedCollection,
    relatedId,
  })

  const [showAddLogModal, setShowAddLogModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [currentFilter, setCurrentFilter] = useState<Filter>('all')

  const handleTaskDone = (openModal = true) => {
    if (openModal) setShowAddModal(true)
    mutate()
  }

  const handleTaskCreated = () => mutate().then(() => setShowAddModal(false))

  const handleLogCreated = () => {
    setShowAddLogModal(false)
    mutate()
  }

  return (
    <React.Fragment>
      {showEditor && (
        <FeedEditor
          relatedCollection={relatedCollection}
          onSuccess={() => mutate()}
          relatedId={relatedId}
        />
      )}

      <section className="mb-12 mt-6">
        <header className="relative flex items-baseline">
          <h3 className="m-0">Scheduled Tasks</h3>
          <button
            className="ml-6 text-indigo-700 hover:text-indigo-600"
            onClick={() => setShowAddModal(true)}
            type="button"
          >
            [ + Add Task ]
          </button>
          <div className="absolute -top-1 right-0">
            <Loader
              active={isValidating && !isLoading}
              indeterminate
              size="mini"
              inline
            />
          </div>
        </header>

        {isLoading && <FeedLoader items={3} marginTop={20} />}

        {!isLoading && data?.pending?.length === 0 && (
          <div className="flex flex-col items-center px-3 py-6">
            <div className="mb-3 text-slate-500">Nothing scheduled yet</div>
            <Button basic onClick={() => setShowAddModal(true)}>
              <Icon name="add" /> Click here to add a task.
            </Button>
          </div>
        )}

        {!isLoading && data?.pending && data.pending.length > 0 && (
          <div className="mt-4">
            {data.pending.map((t) => (
              <PendingTask
                key={t._id}
                task={t}
                onChangeSuccess={mutate}
                onDone={handleTaskDone}
              />
            ))}
          </div>
        )}
      </section>

      <section className="mb-12">
        <header className="relative flex items-baseline">
          <h3 className="m-0">Events Feed</h3>
          <button
            className="ml-6 text-indigo-700 hover:text-indigo-600"
            onClick={() => setShowAddLogModal(true)}
            type="button"
          >
            [ + Add Log ]
          </button>
          <div className="grow" />
          <div>
            <span className="mr-3 text-sm font-semibold text-slate-500">
              View
            </span>
            <ButtonGroup size="mini" basic>
              <Popup
                trigger={
                  <Button
                    onClick={() => setCurrentFilter('all')}
                    active={currentFilter === 'all'}
                    icon
                  >
                    <Icon name="asterisk" />
                  </Button>
                }
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                position="top center"
                content="All events"
                size="tiny"
              />
              <Popup
                trigger={
                  <Button
                    onClick={() => setCurrentFilter('rome-events')}
                    active={currentFilter === 'rome-events'}
                    icon
                  >
                    <Icon name="user secret" />
                  </Button>
                }
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                position="top center"
                content="Admin events"
                size="tiny"
              />
              <Popup
                trigger={
                  <Button
                    onClick={() => setCurrentFilter('tasks')}
                    active={currentFilter === 'tasks'}
                    icon
                  >
                    <Icon name="tasks" />
                  </Button>
                }
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                position="top center"
                content="Tasks"
                size="tiny"
              />
              <Popup
                trigger={
                  <Button
                    onClick={() => setCurrentFilter('user-events')}
                    active={currentFilter === 'user-events'}
                    icon
                  >
                    <Icon name="user" />
                  </Button>
                }
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                position="top center"
                content="Candidate events"
                size="tiny"
              />
            </ButtonGroup>
          </div>
        </header>

        {isLoading && <FeedLoader marginTop={20} />}

        {!isLoading && data?.feed && data.feed?.length === 0 && (
          <div className="flex flex-col items-center px-3 py-6">
            <div className="mb-3 text-slate-500">Nothing logged yet</div>
            <Button basic onClick={() => setShowAddLogModal(true)}>
              <Icon name="add" /> Add a log
            </Button>
          </div>
        )}

        {error && !data && (
          <div className="my-4 rounded-md  bg-red-100 p-4 text-red-800">
            <ExclamationCircleIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle" />
            {error.message}
          </div>
        )}

        {!isLoading && data?.feed && data.feed?.length > 0 && (
          <div className="mt-6">
            {data.feed
              .filter(filterEvents(currentFilter))
              .map((feedItem, index) => {
                const isLastOne = index === data.feed.length - 1
                if (
                  feedItem.type === 'pto-review' ||
                  feedItem.type === 'pto-cancel' ||
                  feedItem.type === 'task'
                ) {
                  return (
                    <TaskComponent
                      onChangeSuccess={mutate}
                      isFirstOne={isLastOne}
                      task={feedItem}
                      key={feedItem._id}
                    />
                  )
                }

                if (
                  feedItem.type === 'pipedrive-note' ||
                  feedItem.type === 'note'
                ) {
                  return (
                    <Note
                      event={feedItem}
                      key={feedItem._id}
                      onRemoveSuccess={mutate}
                      isFirstOne={isLastOne}
                    />
                  )
                }

                if (feedItem.type === 'small-event') {
                  return (
                    <SmallEvent
                      key={feedItem._id}
                      event={feedItem}
                      isFirstOne={isLastOne}
                      userId={feedItem.createdBy}
                    />
                  )
                }

                if (
                  feedItem.type === 'paymentSetup-changed' ||
                  feedItem.type === 'account-created' ||
                  feedItem.type === 'pto-requested' ||
                  feedItem.type === 'user-event'
                ) {
                  return (
                    <UserEvent
                      key={feedItem._id}
                      event={feedItem}
                      isFirstOne={isLastOne}
                    />
                  )
                }

                if (feedItem.type === 'position-status-change') {
                  return (
                    <PositionStatusChangeEvent
                      key={feedItem._id}
                      event={feedItem}
                      isFirstOne={isLastOne}
                    />
                  )
                }

                if (feedItem.type === 'position-pause-resume') {
                  return (
                    <PositionPausedResumeEvent
                      key={feedItem._id}
                      event={feedItem}
                      isFirstOne={isLastOne}
                    />
                  )
                }

                if (feedItem.type === 'position-candidate-changed') {
                  return (
                    <PositionChangeEvent
                      key={feedItem._id}
                      event={feedItem}
                      isFirstOne={isLastOne}
                    />
                  )
                }

                if (feedItem.type === 'status-candidate-changed') {
                  return (
                    <StatusChangeEvent
                      key={feedItem._id}
                      event={feedItem}
                      isFirstOne={isLastOne}
                    />
                  )
                }

                if (feedItem.type === 'event') {
                  return (
                    <EventComponent
                      key={feedItem._id}
                      event={feedItem}
                      isFirstOne={isLastOne}
                    />
                  )
                }

                if (feedItem.type === 'action') {
                  return (
                    <ActionEvent
                      key={feedItem._id}
                      event={feedItem}
                      isFirstOne={isLastOne}
                    />
                  )
                }

                // catch-all JSON render for debugging
                return (
                  <pre
                    className="text-wrap border bg-slate-50 p-2 text-xs leading-none"
                    key={feedItem._id}
                  >
                    {JSON.stringify(feedItem, null, 2)}
                  </pre>
                )
              })}
          </div>
        )}
      </section>

      <AddTaskModal
        show={showAddModal}
        relatedId={relatedId}
        relatedCollection={relatedCollection}
        onCancel={() => setShowAddModal(false)}
        onCreated={handleTaskCreated}
      />

      <AddLogModal
        show={showAddLogModal}
        relatedId={relatedId}
        relatedCollection={relatedCollection}
        onCancel={() => setShowAddLogModal(false)}
        onCreated={handleLogCreated}
      />
    </React.Fragment>
  )
}

export default Feed
