import useSWR from 'swr'
import { z } from 'zod'

import { Candidate, TimeOffSummary, timeOffSummarySchema } from '../types'
import { validateResponse } from '../utils'
import { useContractsApi } from '../store/mainContext'

export default function useTimeoffSummary(candidateId: Candidate['_id']) {
  const api = useContractsApi()
  return useSWR<TimeOffSummary[], Error>(['timeoff-summary', candidateId], () =>
    api
      .get(`/${candidateId}/active-contract/paid-timeoff/summary`, {
        headers: {
          'astor-sortby': 'year',
          'astor-sortby-dir': '-1',
        },
      })
      .then(validateResponse(z.array(timeOffSummarySchema)))
      // TODO: remove this once API honors sort headers
      .then((summary) =>
        summary.sort((periodA, periodB) => periodB.year - periodA.year),
      ),
  )
}
