import React, { useEffect, useState } from 'react'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import { Badge, Candidate } from '../../types'
import { useTalentApi } from '../../store/mainContext'

const BgButton = styled.button<{ isActive?: boolean }>`
  text-align: center;
  padding: 0 8px;
  cursor: pointer;
  border: none;
  background: none;
  filter: ${(props) =>
    props.isActive ? 'brightness(1.1)' : 'brightness(1)'} !important;

  &:hover {
    filter: ${(props) =>
      props.isActive ? 'brightness(1.1)' : 'brightness(0.9)'} !important;
  }
`

const BgIcon = styled.i`
  font-size: 24px;
`

interface Props {
  name: 'badges'
  value: Candidate['badges']
  onSuccess(name: Props['name'], value: Props['value']): void
}

const TrBadges = ({ name, value, onSuccess }: Props) => {
  const api = useTalentApi()

  const [badges, setBadges] = useState<Badge[]>([])

  // TODO refactor into swr hook with schema validation
  useEffect(() => {
    api
      .get('badges')
      .then(({ data }) =>
        setBadges((data as Badge[]).sort((a, b) => a.order - b.order)),
      )
  }, [api])

  const toogleBadge = async (badge: Badge) => {
    const current = value || []
    const newValue = current.includes(badge._id)
      ? current.filter((id) => id !== badge._id)
      : [badge._id, ...current]
    onSuccess(name, newValue)
  }

  return (
    <tr>
      <th>Badges</th>
      <td>
        {badges.map((x) => {
          const isActive = (value || []).includes(x._id)
          return (
            <Popup
              key={x._id}
              trigger={
                <BgButton onClick={() => toogleBadge(x)} isActive={isActive}>
                  <BgIcon
                    className={x.icon}
                    style={{ color: isActive ? x.color : '#d9d9d9' }}
                  />
                </BgButton>
              }
              position="top center"
              content={x.name}
            />
          )
        })}
      </td>
    </tr>
  )
}

export default TrBadges
