import useSWR from 'swr'
import { z } from 'zod'

import { Candidate, Holiday, holidaySchema } from '../types'
import { validateResponse } from '../utils'
import { useContractsApi } from '../store/mainContext'

export function useHolidays(params?: Partial<Holiday>) {
  const api = useContractsApi()
  return useSWR<Holiday[], Error>(['holidays', params], () =>
    api
      .get(`/holidays`, {
        params,
        headers: { 'astor-sortby': 'ndate', 'astor-sortby-dir': '1' },
      })
      .then(validateResponse(z.array(holidaySchema))),
  )
}

export default function useHolidaysForCandidate(candidateId: Candidate['_id']) {
  const api = useContractsApi()
  return useSWR<Holiday[], Error>(['holidays-for-candidate', candidateId], () =>
    api
      .get(`/${candidateId}/active-contract/holidays`, {
        headers: { 'astor-sortby': 'ndate', 'astor-sortby-dir': '1' },
      })
      .then(validateResponse(z.array(holidaySchema))),
  )
}
