export enum EnvironmentMode {
  development = 'development',
  testing = 'testing',
  staging = 'staging',
  production = 'production',
}

const getString = (name: string): string | null => {
  const value =
    typeof process.env[name] === 'string' ? process.env[name]!.trim() : null
  if (!value) {
    console.warn(`Environment variable ${name} is not present`)
  }
  return value
}

/**
 * Environmental variables used throughout the application.
 */
export const ENV_VARS = {
  /**
   * Current environment mode: development | testing | staging | production
   */
  NODE_ENV: getString('NODE_ENV'),
  /**
   * This is here only to inform that the environment variable PORT is used at the file ../index.js and that the default value is 3232
   */
  PORT: 3232,
  /**
   * SWR: Revalidate on focus
   */
  REACT_APP_REVALIDATE_ON_FOCUS: getString('REACT_APP_REVALIDATE_ON_FOCUS'),
  /**
   * Talent API websocket domain
   */
  REACT_APP_TALENT_WS_DOMAIN: getString('REACT_APP_TALENT_WS_DOMAIN'),
  /**
   * If true the talent API websocket will reconnect
   */
  REACT_APP_TALENT_WS_RECONNECTION: getString(
    'REACT_APP_TALENT_WS_RECONNECTION',
  ),
  /**
   * The romebotie user id.
   *
   * Romebotie user doesn't actually exist, so let's avoid the 404 and return the fallback data in that case.
   *
   * TODO: or maybe better to just create the user in the DB?
   */
  REACT_APP_ROMEBOTIE_ID: getString('REACT_APP_ROMEBOTIE_ID'),
  /**
   * Google authenticator App client ID
   */
  REACT_APP_GOOGLE_CLIENT_ID: getString('REACT_APP_GOOGLE_CLIENT_ID'),
  /**
   * API base url
   */
  REACT_APP_API_BASE: getString('REACT_APP_API_BASE'),
}

export const inProd = () => {
  return ENV_VARS.NODE_ENV === EnvironmentMode.production
}
