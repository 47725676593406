import React, {
  MouseEventHandler,
  CSSProperties,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import { AxiosInstance } from 'axios'
import { Icon } from 'semantic-ui-react'

import { useTalentApi } from '../../store/mainContext'
import { Collection } from '../../types'
import PageLoader from '../pageLoader'
import DataTable from './dataTable'

const Footer = ({
  onShowMore,
  isLoading,
  showing,
  total,
}: {
  onShowMore: MouseEventHandler
  isLoading: boolean
  showing?: number
  total?: number
}) => {
  if (isLoading) {
    return (
      <span className="text-base">
        Showing <b>{showing}</b> of <b>{total}</b> - Loading...
      </span>
    )
  }

  if (Number(showing) === Number(total)) {
    return (
      <span className="text-base">
        <b>Showing {showing}</b> - That&apos;s all we have.
      </span>
    )
  }

  return (
    <span className="text-base">
      Showing <b>{showing}</b> of <b>{total}</b> -{' '}
      <button
        className="text-indigo-700 hover:text-indigo-600"
        onClick={onShowMore}
        type="button"
      >
        Show more...
      </button>
    </span>
  )
}

export type Column = {
  sortable?: boolean
  title: string
  field: string
  width?: CSSProperties['width']
  align?: CSSProperties['textAlign']
  render?(fieldValue: any, collectionItem?: any): ReactNode
}

interface Props {
  joinCollection?: Collection
  joinFieldName?: string
  injectedApi?: AxiosInstance
  defaultSort?: string
  collection?: Collection
  onRowClick(data: any): void
  posthook?(data?: any): Promise<any[]> | any[]
  pageSize?: number
  columns: Array<Column>
  footer?: boolean
  filter?: Record<string, any>
  pro?: boolean
}

const PagedTable = ({
  joinCollection,
  joinFieldName,
  defaultSort,
  injectedApi,
  onRowClick,
  collection = 'contacts',
  pageSize = 30,
  posthook,
  columns = [],
  filter,
  footer = true,
  pro = true,
}: Props) => {
  const [data, setData] = useState<any[]>([])
  const [total, setTotal] = useState<number>()

  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [order, setOrder] = useState<{ sortBy: string; sortByDir: '1' | '-1' }>(
    { sortBy: defaultSort || 'lastEvent', sortByDir: '-1' },
  )

  let api = useTalentApi()
  api = injectedApi || api

  const getData = React.useCallback(
    async (skip: number, limit: number) => {
      const headers = {
        'astor-limit': limit,
        'astor-skip': skip,
        'astor-sortby': order.sortBy,
        'astor-sortby-dir': order.sortByDir,
      }

      const { data: collectionData, headers: resHeaders } = await api.get<
        any[]
      >(`${collection}${pro ? '/pro' : ''}`, { params: filter, headers })

      setTotal(Number(resHeaders['astor-total']))

      if (joinCollection && joinFieldName) {
        const joinQuery: Record<string, any> = {}
        joinQuery._id = collectionData
          .filter((x) => x[joinFieldName])
          .map((x) => x[joinFieldName])

        const { data: joinData } = await api.get(`${joinCollection}/in`, {
          params: joinQuery,
        })

        for (const index in collectionData) {
          const item = collectionData[index]
          const joinId = item[joinFieldName]

          if (!joinId) return []

          item.joinedItem = joinData.find((x: any) => x._id === joinId)
        }
      }

      if (posthook) {
        const newData = await posthook(collectionData)
        return newData
      }

      return collectionData
    },
    [
      pro,
      api,
      collection,
      filter,
      joinCollection,
      joinFieldName,
      order.sortBy,
      order.sortByDir,
      posthook,
    ],
  )

  useEffect(() => {
    setIsLoading(true)
    getData(0, pageSize)
      .then((d) => setData(d))
      .finally(() => setIsLoading(false))
  }, [filter, order, pageSize, getData])

  // Gets the next page
  const showMore: MouseEventHandler = async (e) => {
    e.preventDefault()

    setIsLoadingMore(true)

    const newData = await getData(data.length, pageSize)

    setData([...newData, ...data])
    setIsLoadingMore(false)
  }

  const handleOrderChange = (sortBy: string) => {
    setOrder((prev) => ({
      sortBy,
      sortByDir:
        prev.sortBy !== sortBy ? '-1' : prev.sortByDir === '1' ? '-1' : '1',
    }))
  }

  if (isLoading) {
    return <PageLoader />
  }

  if (total === 0) {
    return (
      <div className="flex h-96 items-center justify-center text-2xl ">
        Oops... No Match, sorry. :(
      </div>
    )
  }

  return (
    <React.Fragment>
      <DataTable>
        <thead>
          <tr>
            {columns.map((h) => (
              <DataTable.Th width={h.width} align={h.align} key={h.title}>
                {h.sortable !== false && (
                  <button
                    className="whitespace-nowrap uppercase text-indigo-800 hover:text-indigo-600"
                    onClick={() => handleOrderChange(h.field)}
                    type="button"
                  >
                    {h.title}{' '}
                    {order.sortBy === h.field && (
                      <Icon
                        className={`relative -left-1 !mr-[-22.52px] ${order.sortByDir === '1' ? 'top-0.5' : '-top-0.5'}`}
                        name={order.sortByDir === '1' ? 'sort up' : 'sort down'}
                      />
                    )}
                  </button>
                )}
                {h.sortable === false && h.title}
              </DataTable.Th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((d) => (
            <tr key={d._id} onClick={() => onRowClick && onRowClick(d)}>
              {columns.map((h) => (
                <DataTable.Td key={h.field} align={h.align}>
                  {h.render ? h.render(d[h.field], d) : d[h.field]}
                </DataTable.Td>
              ))}
            </tr>
          ))}
        </tbody>

        {footer && (
          <tfoot>
            <tr>
              <DataTable.Td colspan={11}>
                <Footer
                  onShowMore={showMore}
                  isLoading={isLoadingMore}
                  showing={data.length}
                  total={total}
                />
              </DataTable.Td>
            </tr>
          </tfoot>
        )}
      </DataTable>
    </React.Fragment>
  )
}

export default PagedTable
