import isMongoId from 'validator/es/lib/isMongoId'
import { z } from 'zod'

export type ApiError = {
  message: string
  requestId?: string
}

export type PageError = Error & {
  info?: ApiError
  status?: number
}

const timestampSchema = z.number().int().min(0)

// Maybe we can define this in a more precise way
export const collectionSchema = z.enum([
  'englishLevels',
  'candidates',
  'positions',
  'profiles',
  'contacts',
  'companies',
  'invoices',
  'payrolls',
  'payments',
  'nothing',
  'timezones',
  'seniority',
  'techs',
])

export type Collection = z.infer<typeof collectionSchema>

export const badgeSchema = z.object({
  _id: z.string().refine(isMongoId),
  order: z.number(),
  name: z.string(),
  icon: z.string(),
  description: z.string(),
  color: z.string(),
})

export type Badge = z.infer<typeof badgeSchema>

export const senioritySchema = z.object({
  _id: z.string().refine(isMongoId),
  code: z.string(),
  text: z.string(),
  value: z.number(),
})

export type Seniority = z.infer<typeof senioritySchema>

export const candidateSchema = z.object({
  _id: z.string().refine(isMongoId),
  name: z.string(),
  email: z.string().email().optional(),
  southEmail: z.string().email().optional(),
  address: z.string().optional(),
  birthdate: timestampSchema.optional(),
  whatsapp: z.string().nullable().optional(), // TODO really nullable?
  status: z.string().or(z.null()).optional(), // TODO refine more
  lastStatus: z.string().nullable().optional(), // TODO add missing values
  lastStatusUpdate: z.number().optional(),
  createdOn: timestampSchema,
  createdBy: z.string().refine(isMongoId),
  lastUpdateOn: timestampSchema,
  lastUpdateBy: z.string().refine(isMongoId),
  yearsInTheRole: z.number().optional(),
  yearsInTheIndustry: z.number().optional(),
  lastEvent: z.number().optional(),
  linkedin: z.string().optional(), // should be url() but no validation on DB values
  resume: z.string().url().optional(),
  southResume: z.string().url().optional(),
  editableResume: z.string().optional(),
  location: z.string().optional(),
  emergencyContact: z.string().optional(),
  summary: z.string().optional(),
  codeChallenge: z.string().optional(),
  otherLinks: z.string().optional(),
  visa: z.string().optional(),
  visaDueDate: z.string().optional(),
  englishLevel: z.string().refine(isMongoId).optional(),
  englishSample: z.any().optional(), // TODO: refine
  seniority: senioritySchema.shape._id.optional(),
  profiles: z.array(z.string().refine(isMongoId)).optional(),
  mainTechs: z.array(z.string().refine(isMongoId)).optional(),
  secondaryTechs: z.array(z.string().refine(isMongoId)).optional(),
  badges: z.array(badgeSchema.shape._id).optional(),
  referal: z.string().optional(),
  availability: z.number().optional(),
  referalCandidateId: z.string().refine(isMongoId).optional(),
  positionId: z.string().refine(isMongoId).or(z.null()).optional(),
  salary: z.number().optional(), // A.K.A. intended fee

  offerId: z.string().refine(isMongoId).optional(), // deprecated?
  dealId: z.string().refine(isMongoId).optional(), // deprecated?
})

export type Candidate = z.infer<typeof candidateSchema>

export const contactSchema = z.object({
  _id: z.string().refine(isMongoId),
  name: z.string(),
  email: z.string().optional(),
})

export type Contact = z.infer<typeof contactSchema>

export const timezoneSchema = z.object({
  _id: z.string().refine(isMongoId),
})

export type Timezone = z.infer<typeof timezoneSchema>

export const userSchema = z.object({
  _id: z.string().refine(isMongoId),
  image: z.string().url(),
  email: z.string().email(),
  name: z.string(),
  disabled: z.boolean().optional(),
})

export type User = z.infer<typeof userSchema>

export const companySchema = z.object({
  _id: z.string().refine(isMongoId),
  name: z.string(),
  address: z.string().optional(),
  whatDoTheyDoExplained: z.string().optional(),
  website: z.string().optional(),
  linkedin: z.string().optional(),
  rateCard: z.string().optional(),
  timezoneId: timezoneSchema.shape._id.optional(),
  mainContactId: contactSchema.shape._id.optional(),
  accountManagerId: contactSchema.shape._id.optional(),
  adminManagerId: contactSchema.shape._id.optional(),
  idealDGM: z.coerce.number().optional(),
  about: z.string().optional(),
  location: z.string().optional(),
  sector: z.string().optional(),
  msa: z.string().optional(),
  status: z.string().or(z.null()).optional(), // TODO refine more
  ptoAccruedAutomaticallyAfterFirstYear: z.number().or(z.null()).optional(),
  ptoAccruedPerMonthDuringFirstYear: z.number().or(z.null()).optional(),
  ptoCarryOverLimit: z.number().or(z.null()).optional(),
  createdOn: timestampSchema,
  createdBy: userSchema.shape._id,
  lastUpdateOn: timestampSchema,
  lastUpdateBy: userSchema.shape._id,
})

export type Company = z.infer<typeof companySchema>

export const contractSchema = z.object({
  _id: z.string().refine(isMongoId),
  candidateId: candidateSchema.shape._id,
  customerId: companySchema.shape._id,
  active: z.boolean(),
  enableTaxAdvisorBenefit: z.boolean().optional(),
  startingDate: timestampSchema,
  endDate: timestampSchema.optional(),
  orgUnit: z.string().optional(),
  department: z.string().optional(),
  notes: z.string().optional(),
  ptoAccruedAutomaticallyAfterFirstYear: z.number().positive().optional(),
  ptoAccruedPerMonthDuringFirstYear: z.number().positive().optional(),
  ptoCarryOverLimit: z.number().positive().optional(),
  contact2: contactSchema.shape._id.optional(), // check if ok
  contact3: contactSchema.shape._id.optional(), // check if ok
  hiringManagerId: contactSchema.shape._id.optional(), // check if ok
  supervisorId: contactSchema.shape._id.optional(), // check if ok
  offerLetter: z.string().optional(), // check if ok
  agreement: z.string().optional(), // check if ok
  passport: z.string().optional(), // check if ok
  passport2: z.string().optional(), // check if ok
  w8ben: z.string().optional(), // check if ok
  w8ben1: z.string().optional(), // check if ok
  terminatedBy: z.string().or(z.null()).optional(), // seems backend does not enforce user
  terminationDetails: z.string().optional(),
  terminationFiledBy: userSchema.shape._id.optional(), // repeated on Deal
  terminationFiledOn: timestampSchema.optional(), // repeated on Deal
  terminationReason: z.string().optional(), // repeated on Deal
  createdOn: timestampSchema,
  createdBy: userSchema.shape._id,
  lastUpdateOn: timestampSchema,
  lastUpdateBy: userSchema.shape._id,
})

export type Contract = z.infer<typeof contractSchema>

export const customerSowSchema = z.object({
  _id: z.string().refine(isMongoId),
  monthlyRate: z.number(),
  sow: z.string().optional(),
  createdOn: timestampSchema,
  createdBy: userSchema.shape._id,
  lastUpdateOn: timestampSchema,
  lastUpdateBy: userSchema.shape._id,
})

export type CustomerSow = z.infer<typeof customerSowSchema>

export const dealSchema = z.object({
  _id: z.string().refine(isMongoId),
  contractId: contractSchema.shape._id,
  customerSOWId: customerSowSchema.shape._id,
  position: z.string(),
  sow: z.string().optional(), // same as customerSowId? deprecated?
  contractorSOW: z.string().optional(),
  active: z.boolean(),
  monthlySalary: z.number(),
  effectiveDate: timestampSchema,
  endDate: timestampSchema.optional(), // really?
  createdOn: timestampSchema,
  createdBy: userSchema.shape._id,
  lastUpdateOn: timestampSchema,
  lastUpdateBy: userSchema.shape._id,
  terminationFiledBy: userSchema.shape._id.optional(), // repeated on Contract
  terminationFiledOn: timestampSchema.optional(), // repeated on Contract
})

export type Deal = z.infer<typeof dealSchema>

export const apiDateSchema = z.string().regex(/^\d{4}-\d{2}-\d{2}$/)

export type ApiDate = z.infer<typeof apiDateSchema>

export const timeOffStatusSchema = z.enum([
  'Waiting for approval',
  'Approved',
  'Canceled',
  'Rejected',
])

export const timeoffRequestSchema = z.object({
  _id: z.string().refine(isMongoId),
  contractId: contractSchema.shape._id,
  status: timeOffStatusSchema,
  dates: z.array(apiDateSchema),
  comments: z.string().optional(),
  createdOn: timestampSchema,
  createdBy: userSchema.shape._id,
  lastUpdateOn: timestampSchema,
  lastUpdateBy: userSchema.shape._id,
})

export type TimeoffRequest = z.infer<typeof timeoffRequestSchema>

export const timeoffCoverageSchema = z.object({
  date: apiDateSchema,
  coveredByPtoPolicy: z.boolean(),
  period: z.number(),
  dayValue: z.number().optional(),
})

export type TimeoffCoverage = z.infer<typeof timeoffCoverageSchema>

export const timeOffSummarySchema = z.object({
  year: z.number().positive(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  accrued: z.number().min(0),
  consumed: z.number().min(0),
  carriedOverFromLastYear: z.number().min(0),
  totalInPeriod: z.number().min(0),
  balance: z.number(),
  daysOff: z
    .array(
      z.object({
        ndate: z.number().positive(),
        status: timeOffStatusSchema,
      }),
    )
    .optional(),
})

export type TimeOffSummary = z.infer<typeof timeOffSummarySchema>

export const timeOffBalanceSchema = z.object({
  available: z.number(),
  renewalDate: z.coerce.date(),
  accrued: z.number(),
  consumed: z.number(),
  carriedOverFromLastYear: z.number(),
})

export type TimeOffBalance = z.infer<typeof timeOffBalanceSchema>

export const timeOffTransactionSchema = z.object({
  _id: z.string().refine(isMongoId),
  amount: z.number().positive(),
  contractId: contractSchema.shape._id,
  timeOffRequestId: timeoffRequestSchema.shape._id.optional(),
  detail: z.string().optional(),
  type: z.enum(['credit', 'debit']),
  date: timestampSchema,
  status: timeOffStatusSchema,
})

export type TimeOffTransaction = z.infer<typeof timeOffTransactionSchema>

export const eventSchema = z
  .object({
    _id: z.string().refine(isMongoId),
    relatedCollection: collectionSchema,
    relatedId: z.string(),
    type: z
      .enum([
        'position-status-change',
        'position-pause-resume',
        'position-candidate-changed',
        'status-candidate-changed',
        'user-event',
        'pipedrive-note',
        'note',
        'pto-requested',
        'account-created',
        'paymentSetup-changed',
        'event',
        'small-event',
        'action',
      ])
      .catch('event'),
    title: z.string().optional(),
    description: z.string().nullable().optional(),
    userName: z.string().optional(),
    userImage: z.string().url().optional(),
    isNowPaused: z.boolean().optional(),
    context: z.object({ dates: z.array(apiDateSchema).optional() }).optional(),
    fromStatus: z.string().optional(), // TODO refine better once Position type is defined
    toStatus: z.string().optional(), // TODO refine better once Position type is defined
    status: z
      .array(
        z.string(), // TODO refine better once Position type is defined
      )
      .optional(),
    positions: z
      .array(
        z.string(), // TODO refine better once Position type is defined
      )
      .optional(),
    fromPositionId: z.string().optional(), // TODO refine better once Position type is defined
    toPositionId: z.string().optional(), // TODO refine better once Position type is defined
    info: z.any().optional(),
    createdOn: timestampSchema,
    createdBy: userSchema.shape._id,
    lastUpdateOn: timestampSchema,
    lastUpdateBy: userSchema.shape._id,
  })
  .passthrough() // TODO remove eventually.

export type Event = z.infer<typeof eventSchema>

export const taskSchema = z.object({
  _id: z.string().refine(isMongoId),
  dueDate: timestampSchema,
  assignedToId: userSchema.shape._id,
  subject: z.string(),
  details: z.string().optional(),
  type: z.enum(['task', 'pto-review', 'pto-cancel']),
  isDone: z.boolean().optional(),
  doneBy: userSchema.shape._id.optional(),
  doneOn: timestampSchema.optional(),
  requireDoneNote: z.boolean().optional(),
  doneNote: z.string().optional(),
  relatedCollection: collectionSchema.optional(),
  relatedId: z.string().refine(isMongoId).optional(),
  lastUpdateBy: userSchema.shape._id,
  lastUpdateOn: timestampSchema,
  createdBy: userSchema.shape._id,
  createdOn: timestampSchema,
  timeOffRequestId: timeoffRequestSchema.shape._id.optional(),
  candidateNote: z.string().optional(),
})

export type Task = z.infer<typeof taskSchema>

export const techSchema = z.object({
  _id: z.string().refine(isMongoId),
})

export type Tech = z.infer<typeof techSchema>

export const profileSchema = z.object({
  _id: z.string().refine(isMongoId),
})

export type Profile = z.infer<typeof profileSchema>

export const positionSchema = z.object({
  _id: z.string().refine(isMongoId),
  title: z.string(),
  description: z.string().optional(),
  number: z.number(),
  openings: z.number().optional(),
  salary: z.number().optional(),
  minYears: z.number().optional(),
  status: z.enum(['open', 'closed']),
  companyId: companySchema.shape._id.optional(),
  isPaused: z.boolean().optional(),
  isHighPriority: z.boolean().optional(),
  sharePublicly: z.boolean().optional(),
  shareInSlack: z.boolean().optional(),
  hiringManagerId: contactSchema.shape._id.optional(),
  secondContact: contactSchema.shape._id.optional(),
  keywords: z.string().optional(),
  lastEvent: timestampSchema.optional(),
  openOn: timestampSchema.optional(),
  openBy: userSchema.shape._id.optional(),
  closedOn: timestampSchema.optional(),
  closedBy: userSchema.shape._id.optional(),
  requesterId: userSchema.shape._id.optional(),
  techsRequired: z.array(techSchema.shape._id).optional(),
  profilesRequired: z.array(profileSchema.shape._id).optional(),
  lastUpdateBy: userSchema.shape._id,
  lastUpdateOn: timestampSchema,
  createdBy: userSchema.shape._id,
  createdOn: timestampSchema,
})

export type Position = z.infer<typeof positionSchema>

export const holidaySchema = z.object({
  _id: z.string().refine(isMongoId),
  customerId: companySchema.shape._id,
  date: apiDateSchema,
  ndate: z.number().positive().optional(),
  name: z.string(),
  year: z.number().positive().min(2010),
})

export type Holiday = z.infer<typeof holidaySchema>

export const funnelColumnSchema = z.object({
  id: z.string(),
  title: z.string(),
  rotting: z.number().optional(),
})

export type FunnelColumn = z.infer<typeof funnelColumnSchema>

export const funnelSchema = z.object({
  _id: z.string().refine(isMongoId),
  order: z.number(),
  name: z.string(),
  title: z.string(),
  sub: z.string(),
  collection: collectionSchema,
  cardType: collectionSchema.or(z.enum(['candidate'])), // it seems there are a few inconsistencies with data here
  columns: z.array(funnelColumnSchema),
  createdBy: userSchema.shape._id.optional(),
  createdOn: timestampSchema.optional(),
  lastUpdateBy: userSchema.shape._id,
  lastUpdateOn: timestampSchema,
})

export type Funnel = z.infer<typeof funnelSchema>

export const invoiceStatusSchema = z.enum([
  'Waiting for approval',
  'Dismissed',
  'Approved',
  'Draft',
  'Sent',
  'Paid',
])

export type InvoiceStatus = z.infer<typeof invoiceStatusSchema>

export const invoiceSchema = z.object({
  _id: z.string().refine(isMongoId),
  date: timestampSchema,
  status: invoiceStatusSchema,
  amount: z.number().or(z.null()),
  name: z.string().optional(),
  number: z.string().or(z.null()).optional(),
  customerId: companySchema.shape._id.or(z.null()).optional(),
  dueDate: timestampSchema.or(z.null()).optional(),
  period: z.string().optional(),
  periodStartDate: timestampSchema.or(z.null()).optional(),
  periodEndDate: timestampSchema.or(z.null()).optional(),
  sentOn: timestampSchema.or(z.null()).optional(),
  paidOn: timestampSchema.or(z.null()).optional(),
  createdOn: timestampSchema,
  createdBy: userSchema.shape._id,
  lastUpdateOn: timestampSchema,
  lastUpdateBy: userSchema.shape._id,
})

export type Invoice = z.infer<typeof invoiceSchema>

export const invoiceDetailSchema = z.object({
  _id: z.string().refine(isMongoId),
  invoiceId: invoiceSchema.shape._id,
  type: z.string().optional(),
  detail: z.string().optional(),
  amount: z.number().or(z.null()).optional(),
})

export type InvoiceDetail = z.infer<typeof invoiceDetailSchema>

export const payrollCreatedLogSchema = z.object({
  step: z.literal('payroll_created'),
  data: z.object({ userName: z.string() }),
  message: z.string(),
  date: timestampSchema,
})

export const payrollUpdatedLogSchema = z.object({
  step: z.literal('update_payroll'),
  data: z.object({ payrollId: z.string().refine(isMongoId) }),
  message: z.string(),
  date: timestampSchema,
})

export const payrollPaymentsStartLogSchema = z.object({
  step: z.literal('payments_start'),
  data: z.object({ contractsLength: z.number().positive() }),
  message: z.string(),
  date: timestampSchema,
})

export const payrollPaymentsEndLogSchema = z.object({
  step: z.literal('payments_end'),
  data: z.object({ contractsLength: z.number().positive() }),
  message: z.string(),
  date: timestampSchema,
})

export const payrollLogSchema = z.discriminatedUnion('step', [
  payrollPaymentsStartLogSchema,
  payrollPaymentsEndLogSchema,
  payrollCreatedLogSchema,
  payrollUpdatedLogSchema,
])

export type PayrollLog = z.infer<typeof payrollLogSchema>

export const paymentStartLogSchema = z.object({
  step: z.literal('payment_start'),
  data: z.object({
    candidateId: candidateSchema.shape._id,
    contractId: contractSchema.shape._id,
    payrollId: z.string().refine(isMongoId),
    dealId: dealSchema.shape._id,
  }),
  message: z.string(),
  date: timestampSchema,
})

export const paymentSuccessLogSchema = z.object({
  step: z.literal('payment_success'),
  data: z.array(
    z.object({
      _id: z.string().refine(isMongoId),
      candidateId: candidateSchema.shape._id,
      contractId: contractSchema.shape._id,
      payrollId: z.string().refine(isMongoId),
      bankAccount: z.string().refine(isMongoId).or(z.null()).optional(),
      amount: z.number(),
      description: z.string(),
      date: timestampSchema,
      type: z.enum(['credit', 'discount']).optional(),
      observations: z.array(z.string()).optional(),
      status: z
        .enum(['Stalled', 'Waiting for approval', 'Approved'])
        .optional(),
      createdOn: timestampSchema,
      createdBy: userSchema.shape._id,
      lastUpdateOn: timestampSchema,
      lastUpdateBy: userSchema.shape._id,
    }),
  ),
  message: z.string(),
  date: timestampSchema,
})

export const paymentLogSchema = z.discriminatedUnion('step', [
  paymentSuccessLogSchema,
  paymentStartLogSchema,
])

export type PaymentLog = z.infer<typeof paymentLogSchema>

export const payrollStatusSchema = z.enum(['Waiting for approval', 'Completed'])

export const payrollSchema = z.object({
  _id: z.string().refine(isMongoId),
  name: z.string(),
  periodStart: timestampSchema,
  periodEnd: timestampSchema,
  dueDate: timestampSchema,
  status: payrollStatusSchema,
  payrollLogs: z.array(payrollLogSchema),
  paymentLogs: z.record(candidateSchema.shape._id, z.array(paymentLogSchema)),
  createdOn: timestampSchema,
  createdBy: userSchema.shape._id,
  lastUpdateOn: timestampSchema,
  lastUpdateBy: userSchema.shape._id,
})

export type Payroll = z.infer<typeof payrollSchema>

export const accountStatusSchema = z.enum([
  'Waiting for approval',
  'Approved',
  'Rejected',
])

export const commonAccountSchema = z.object({
  _id: z.string().refine(isMongoId),
  candidateId: candidateSchema.shape._id,
  alias: z.string(),
  status: accountStatusSchema,
  createdOn: timestampSchema,
  lastUpdateOn: timestampSchema,
})

export const accountSchema = z.discriminatedUnion('type', [
  commonAccountSchema.extend({
    type: z.literal('payoneer'),
  }),
  commonAccountSchema.extend({
    type: z.literal('international'),
    accountCurrency: z.string().optional(),
    accountCountry: z.string().optional(),
    bankSwift: z.string().optional(),
    bankName: z.string().optional(),
    bankAddress: z.string().optional(),
    beneficiary: z.string(),
    address: z.string().optional(),
    accountNumber: z.union([z.string(), z.number()]),
  }),
  commonAccountSchema.extend({
    type: z.literal('usa'),
    routingNumber: z.string().optional(),
    bankName: z.string().optional(),
    bankAddress: z.string().optional(),
    beneficiary: z.string(),
    address: z.string().optional(),
    accountNumber: z.union([z.string(), z.number()]),
  }),
])

export type Account = z.infer<typeof accountSchema>

export const paymentSetupSchema = z.union([
  z.object({
    fixed: z.object({
      account: commonAccountSchema.shape._id,
      amount: z.literal('all'),
    }),
  }),
  z.object({
    fixed: z.object({
      account: commonAccountSchema.shape._id,
      amount: z.number(),
    }),
    rest: commonAccountSchema.shape._id,
  }),
])

export type PaymentSetup = z.infer<typeof paymentSetupSchema>
