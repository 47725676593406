import React, { useState } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { format } from 'date-fns'

import PagedTable, { Column } from '../../components/dataTable/pagedTable'
import { useContractsApi } from '../../store/mainContext'
import InvoiceStatusLabel from '../../components/invoices/InvoiceStatusLabel'
import InvoicesFilters from '../../components/filters/InvoicesFilters'
import type { Filter } from '../../components/filters/InvoicesFilters'
import { useToasts } from '../../components/toasts/ToastsProvider'
import RemoteValue from '../../components/remoteValues/remoteValue'
import { Invoice } from '../../types'
import PageHeader from '../../components/pageHeader/pageHeader'

const columns: Column[] = [
  {
    title: 'Customer',
    field: 'customerId',
    sortable: false,
    render: (customerId: Invoice['customerId']) =>
      customerId ? (
        <RemoteValue
          collection="companies"
          id={customerId}
          predicate={(v) => v.name}
          showLoading
        />
      ) : (
        <span className="text-slate-400">Not set</span>
      ),
  },
  {
    title: 'Invoice #',
    field: 'number',
    align: 'right',
  },
  {
    title: 'Amount',
    field: 'amount',
    align: 'right',
    render: (amount: number) => {
      const options = { style: 'currency', currency: 'USD' }
      const numberFormat = new Intl.NumberFormat('en-US', options)
      return numberFormat.format(amount)
    },
  },
  {
    title: 'Status',
    align: 'center',
    width: '1%',
    field: 'status',
    render: (_, invoice: Invoice) => <InvoiceStatusLabel invoice={invoice} />,
  },
  {
    title: 'Date Issued',
    width: '1%',
    field: 'date',
    align: 'center',
    render: (date: Invoice['date']) => (date ? format(date, 'dd/MM/yyyy') : ''),
  },
  {
    title: 'Due Date',
    width: '1%',
    field: 'dueDate',
    align: 'center',
    render: (date: Invoice['dueDate']) =>
      date ? format(date, 'dd/MM/yyyy') : '',
  },
  {
    title: 'Sent On',
    width: '1%',
    field: 'sentOn',
    render: (date: Invoice['sentOn']) =>
      date ? format(date, 'dd/MM/yyyy') : '',
  },
  {
    title: 'Paid On',
    width: '1%',
    field: 'paidOn',
    render: (date: Invoice['paidOn']) =>
      date ? format(date, 'dd/MM/yyyy') : '',
  },
]

const Invoices = () => {
  const [isCreating, setIsCreating] = useState(false)
  const [filter, setFilter] = useState<Filter>({})

  const contractsApi = useContractsApi()
  const { addToast } = useToasts()
  const history = useHistory()

  const handleNewInvoice = async () => {
    setIsCreating(true)
    return contractsApi
      .post<Invoice>('/invoices', {})
      .then((res) => history.push(`/invoices/${res.data._id}`))
      .catch((err) =>
        addToast(err.response?.data.message || err.message, {
          variant: 'danger',
        }),
      )
      .finally(() => setIsCreating(false))
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Invoices</title>
      </Helmet>

      <PageHeader
        title="Invoices"
        sub="One screen to bill them all."
        breadcrumb={[{ text: 'Dashboard', link: '/' }, { text: 'Invoices' }]}
        actions={
          <Button
            disabled={isCreating}
            loading={isCreating}
            onClick={handleNewInvoice}
            content="New Invoice"
            icon="add"
            basic
          />
        }
      />

      <div className="mx-14 mb-14">
        <div className="flex items-baseline justify-between">
          <InvoicesFilters onChange={setFilter} value={filter} />
          <Button size="tiny" icon basic disabled>
            <Icon name="download" />
          </Button>
        </div>

        <PagedTable
          injectedApi={contractsApi}
          defaultSort="lastUpdateOn"
          collection="invoices"
          onRowClick={(row: Invoice) => history.push(`/invoices/${row._id}`)}
          columns={columns}
          filter={filter}
          footer
        />
      </div>
    </React.Fragment>
  )
}

export default Invoices
