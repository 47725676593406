import useSWR from 'swr'
import { z } from 'zod'

import { Contract, TimeoffRequest, timeoffRequestSchema } from '../types'
import { validateResponse } from '../utils'
import { useContractsApi } from '../store/mainContext'

export default function useTimeoffRequests(
  contractId: Contract['_id'],
  params?: Partial<Record<keyof TimeoffRequest, any>>,
) {
  const api = useContractsApi()
  return useSWR<TimeoffRequest[], Error>(['timeoff-requests', contractId], () =>
    api
      .get(`/timeoff-requests/pro`, {
        params: { contractId, ...params },
        headers: {
          'astor-sortby': 'createdOn',
          'astor-sortby-dir': '-1',
        },
      })
      .then(validateResponse(z.array(timeoffRequestSchema))),
  )
}
