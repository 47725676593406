import React, { useState } from 'react'

import { Button, Icon } from 'semantic-ui-react'
import NoteCreator from './noteCreator'
import ShowIf from '../../showIf'
import TaskCreator from './taskCreator'

const FeedEditor = ({ relatedId, relatedCollection, onSuccess }) => {
  const [active, setActive] = useState('Note')

  return (
    <div>
      <Button.Group
        basic
        className="w-full overflow-x-auto [&::-webkit-scrollbar]:hidden"
      >
        <Button active={active === 'Note'} onClick={() => setActive('Note')}>
          <Icon name="bookmark outline" />
          Note
        </Button>
        <Button active={active === 'Task'} onClick={() => setActive('Task')}>
          <Icon name="tasks" /> Task
        </Button>
        <Button
          active={active === 'Files'}
          disabled={true}
          onClick={() => setActive('Files')}
        >
          <Icon name="file outline" /> Files
        </Button>
        <Button
          active={active === 'Email'}
          disabled={true}
          onClick={() => setActive('Email')}
        >
          <Icon name="mail outline" /> Email
        </Button>
        <Button
          active={active === 'Slack'}
          disabled={true}
          onClick={() => setActive('Slack')}
        >
          <Icon name="slack hash" /> Slack
        </Button>
      </Button.Group>

      <ShowIf if={active === 'Note'}>
        <NoteCreator
          relatedCollection={relatedCollection}
          relatedId={relatedId}
          onSuccess={onSuccess}
        />
      </ShowIf>

      <ShowIf if={active === 'Task'}>
        <TaskCreator
          relatedCollection={relatedCollection}
          relatedId={relatedId}
          onSuccess={onSuccess}
        />
      </ShowIf>
    </div>
  )
}

export default FeedEditor
