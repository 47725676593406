import {
  ShieldExclamationIcon,
  ShieldCheckIcon,
  ClockIcon,
} from '@heroicons/react/24/outline'
import { Button, Icon } from 'semantic-ui-react'
import * as React from 'react'

import useCopyToClipboard from '../../hooks/useCopyToClipboard'
import UsaBankIcon from '../UsaBankIcon'
import { Account } from '../../types'

function Cell({ label, value }: { label: string; value: string }) {
  const { copyToClipboard, isCopyingToClipboard } = useCopyToClipboard()
  return (
    <div className="group -mx-2 rounded-md px-2 py-1">
      <div className="block font-semibold text-slate-700">{label}</div>
      <div className="flex items-baseline gap-4 text-slate-800">
        <div className="whitespace-pre">{value}</div>
        <Button
          className="opacity-0 group-hover:opacity-100"
          icon={isCopyingToClipboard ? 'check' : 'copy'}
          disabled={isCopyingToClipboard}
          size="mini"
          compact
          onClick={() => copyToClipboard(value)}
          style={{
            margin: 0,
            position: 'relative',
            top: -0.5,
            backgroundColor: '#fff',
            border: 'solid 1px var(--border-grey)',
          }}
        />
      </div>
    </div>
  )
}

interface Props {
  account: Account
}

export default function AccountDetails({ account }: Props) {
  return (
    <div>
      <div className="space-y-6 text-slate-700">
        <div className="grid grid-cols-2 gap-12">
          <Cell
            label="Account Alias"
            value={account.type === 'payoneer' ? 'Payoneer' : account.alias}
          />
          <div className="-mx-2 rounded-md px-2 py-1">
            <div className="mb-1 block font-semibold text-slate-700">
              Status
            </div>
            {
              {
                Rejected: (
                  <ShieldExclamationIcon className="relative top-[-1px] -ml-1 mr-1 inline-block h-6 w-6 text-red-500" />
                ),
                Approved: (
                  <ShieldCheckIcon className="relative top-[-1px] -ml-1 mr-1 inline-block h-6 w-6 text-green-500" />
                ),
                'Waiting for approval': (
                  <ClockIcon className="relative top-[-1px] -ml-1 mr-1 inline-block h-6 w-6 text-amber-500" />
                ),
              }[account.status]
            }
            {account.status}
          </div>
        </div>
      </div>

      {account.type !== 'payoneer' && (
        <div className="mt-3 grid grid-cols-2 gap-12">
          <div className="-mx-2 rounded-md px-2 py-1">
            <div className="mb-1 block font-semibold text-slate-700">
              Account Type
            </div>
            {
              {
                usa: (
                  <>
                    <UsaBankIcon className="relative top-[1px] -ml-0.5 mr-1 inline-block w-7 text-slate-600" />
                    USA Bank Account
                  </>
                ),
                payoneer: 'Payoneer',
                international: (
                  <>
                    <Icon
                      name="globe"
                      color="grey"
                      size="large"
                      className="relative left-[-2px]"
                    />
                    International Bank Account
                  </>
                ),
              }[account.type]
            }
          </div>
        </div>
      )}

      {account.type !== 'payoneer' && <hr className="mt-4" />}

      {account.type !== 'payoneer' && (
        <div className="mt-3 grid grid-cols-2 gap-12">
          <Cell
            label="Account Number"
            value={account.accountNumber.toString()}
          />

          {account.type === 'international' && account.bankSwift && (
            <Cell label="Bank SWIFT" value={account.bankSwift} />
          )}

          {account.type === 'usa' && account.routingNumber && (
            <Cell label="Routing Number" value={account.routingNumber} />
          )}
        </div>
      )}

      {account.type === 'international' && (
        <div className="mt-3 grid grid-cols-2 gap-12">
          {account.accountCountry && (
            <Cell label="Account Country" value={account.accountCountry} />
          )}

          {account.accountCurrency && (
            <Cell label="Currency" value={account.accountCurrency} />
          )}
        </div>
      )}

      {account.type !== 'payoneer' && <hr className="mt-4" />}

      {account.type !== 'payoneer' && (
        <div className="mt-3 grid grid-cols-2 gap-12">
          <Cell label="Bank Name" value={account.bankName || ''} />
          {account.bankAddress && (
            <Cell label="Bank Address" value={account.bankAddress} />
          )}
        </div>
      )}

      {account.type !== 'payoneer' && <hr className="mt-4" />}

      {account.type !== 'payoneer' && (
        <div className="mt-3 grid grid-cols-2 gap-12">
          <Cell label="Beneficiary" value={account.beneficiary} />
          <Cell label="Address" value={account.address || ''} />
        </div>
      )}
    </div>
  )
}
