import { Confirm, Button, ConfirmProps } from 'semantic-ui-react'
import * as React from 'react'

import TimeoffReviewConfirmation from './TimeoffReviewConfirmation'
import { Task } from '../types'

interface Props {
  onCandidateNoteChange(newValue: string): void
  onConfirm: ConfirmProps['onConfirm']
  onCancel: ConfirmProps['onCancel']
  open: ConfirmProps['open']
  isPending?: boolean
  task: Task
  mode: 'approve' | 'reject'
}

export default function TimeoffReviewConfirmationDialog(props: Props) {
  const { isPending, task, mode, onCandidateNoteChange, ...other } = props

  return (
    <Confirm
      header="Are you sure?"
      content={
        <div className="px-6 pb-4">
          <TimeoffReviewConfirmation
            onCandidateNoteChange={onCandidateNoteChange}
            candidateNote={task.candidateNote}
            doneNote={task.doneNote}
            isPending={isPending}
            mode={mode}
            task={task}
          />
        </div>
      }
      closeOnDimmerClick={!isPending}
      closeOnEscape={!isPending}
      size="tiny"
      cancelButton={<Button disabled={isPending} content="Cancel" />}
      confirmButton={
        <Button
          disabled={isPending || (task.candidateNote || '').length < 4}
          primary={false}
          color={mode === 'approve' ? 'green' : 'red'}
          loading={isPending}
        >
          Yes, {mode === 'approve' ? 'approve' : 'reject'} the request
        </Button>
      }
      {...other}
    />
  )
}
