import React, { useState } from 'react'
import {
  Button,
  // ButtonGroup,
  Confirm,
  Dropdown,
  DropdownDivider,
  DropdownItem,
  Icon,
  // Label,
  // Menu,
  Modal,
  // Popup,
} from 'semantic-ui-react'
import styled from 'styled-components'
import ContactName from './remoteValues/contactName'
import Period from './period'
import { useTalentApi } from '../store/mainContext'
import DateFormat from './dateFormat'
// import { Link, useHistory } from 'react-router-dom'
import UserName from './remoteValues/userName'
import PositionDetails from './positionDetails'

const Card = styled.div`
  border: solid 1px var(--border-grey);
  margin-top: 20px;
  padding: 15px 20px;
  border-radius: 4px;

  width: 100%;

  &:hover {
    box-shadow: var(--active-shadow) !important;
    border-color: var(--primary);
  }
`

const CardOpen = styled.div`
  border: solid 1px var(--border-grey);
  margin-top: 20px;
  padding: 15px 20px;
  border-radius: 4px;
  /* background-color: var(--bg-grey); */

  width: 100%;

  /* box-shadow: var(--active-shadow)!important;
  border-color: var(--primary);
   */
`

const CardContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  /* background-color: #FFf; */
  position: relative;
  cursor: pointer;
`

const Title = styled.div`
  /* font-weight: bold; */
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 10px;
  width: 300px;
  color: var(--primary);
`

const Sub = styled.div`
  font-size: 15px;
  color: #666;
  margin-top: 5px;
`

const Number = styled.h1`
  font-size: 25px;
  margin: 0;
  font-weight: bolder !important;
`

const Widget = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`

const CompanyName = styled.p`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 5px !important;
`

const RemoveOption = styled(DropdownItem)`
  color: var(--red) !important;
`

const SharedPopup = styled.div`
  position: absolute;
  top: -30px;
  right: 15px;
  background-color: #fff;
  border-left: solid 1px var(--border-grey);
  border-right: solid 1px var(--border-grey);
  border: solid 1px var(--border-grey);
  border-radius: 2px;
  padding: 5px 10px;
  font-size: 12px;
`

const PositionCard = ({ position, onUpdated }) => {
  // const history = useHistory()

  const [showDetails, setShowDetails] = useState(false)
  const [showConfirmClose, setShowConfirmClose] = useState(false)

  const api = useTalentApi()

  const handleTooglePriority = async () => {
    const update = { isHighPriority: !position.isHighPriority }
    await api.patch(`positions/${position._id}`, update)

    onUpdated({
      ...position,
      ...update,
    })
  }

  const handleClosePosition = async () => {
    const update = { status: 'closed' }
    await api.patch(`positions/${position._id}`, update)

    onUpdated({
      ...position,
      ...update,
    })

    setShowConfirmClose(false)
  }

  const handlePositionReopen = async () => {
    const update = { status: 'open' }
    await api.patch(`positions/${position._id}`, update)

    onUpdated({
      ...position,
      ...update,
    })
  }

  const handleTooglePause = async () => {
    const update = {
      isPaused: !position.isPaused,
    }

    await api.patch(`positions/${position._id}`, update)

    onUpdated({
      ...position,
      ...update,
    })
  }

  if (position.status === 'closed') {
    return (
      <Card>
        <CardContainer>
          <div>
            <Title>
              {position.number} -{' '}
              {position.company ? position.company.name : '[No Company]'} -{' '}
              {position.title}
            </Title>
            <Sub>
              <b>HM:</b>{' '}
              <ContactName
                id={position.hiringManagerId}
                noContact="No Hiring Manager."
              />
            </Sub>
            <Sub>
              <b>Open On:</b> <DateFormat date={position.openOn} /> -{' '}
              <b>Closed On:</b> <DateFormat date={position.closedOn} /> by{' '}
              <UserName id={position.closedBy} short />
            </Sub>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button color="black" onClick={handlePositionReopen}>
              Re-open
            </Button>
          </div>
        </CardContainer>
      </Card>
    )
  }

  const pausedStyle = position.isPaused && {
    backgroundColor: `#fffdf7`,
    borderColor: '#e4dec8',
  }

  const WhatCard = showDetails ? CardOpen : Card

  return (
    <React.Fragment>
      <div>
        <WhatCard style={pausedStyle || {}}>
          <CardContainer onClick={() => setShowDetails(!showDetails)}>
            {/* <Card onClick={() => window.open(`/positions/${position._id}`, '_blank')} style={pausedStyle || {}}> */}
            {!position.sharePublicly && (
              <SharedPopup>
                This position is{' '}
                <b className="red">
                  not being shared publicly&nbsp;
                  <Icon name="rss" />
                </b>
              </SharedPopup>
            )}

            <div>
              <CompanyName>
                {position.company ? position.company.name : '[No Company]'}
              </CompanyName>
              <Title>
                {position.number} - {position.title}{' '}
                {position.isPaused && '(Paused)'}
              </Title>
              <Sub>
                <b>HM:</b>{' '}
                <ContactName
                  id={position.hiringManagerId}
                  noContact="No Hiring Manager."
                />
              </Sub>
              <Sub>
                <b>Open On:</b> <DateFormat date={position.openOn} />
              </Sub>
            </div>

            <Widget>
              <Number>
                <Period date={position.openOn} daysSince />
              </Number>
              <Sub>
                Days <br />
                since open
              </Sub>
            </Widget>

            <Widget>
              <Number>0</Number>
              <Sub>
                Candidates <br />
                To Review
              </Sub>
            </Widget>

            <Widget>
              <Number>0</Number>
              <Sub>
                Candidates <br />
                Scheduled
              </Sub>
            </Widget>

            <Widget>
              <Number>0</Number>
              <Sub>
                Candidates <br />
                Interviewed
              </Sub>
            </Widget>

            <Widget>
              <Number>0</Number>
              <Sub>
                Candidate <br />
                Presented
              </Sub>
            </Widget>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Dropdown
                trigger={
                  <Button icon basic>
                    <Icon name="ellipsis horizontal" />
                  </Button>
                }
                icon={null}
                pointing="top left"
              >
                <Dropdown.Menu style={{ zIndex: 9999 }}>
                  <DropdownItem
                    onClick={() =>
                      window.open(`positions/${position._id}`, '_blank')
                    }
                  >
                    View details
                  </DropdownItem>
                  <DropdownDivider />
                  {position.isHighPriority && (
                    <DropdownItem onClick={handleTooglePriority}>
                      <Icon name="arrow down" />
                      Set as <b className="highlight">Normal</b> Priority
                    </DropdownItem>
                  )}

                  {!position.isHighPriority && (
                    <DropdownItem onClick={handleTooglePriority}>
                      <Icon name="arrow up" />
                      Set as <b className="highlight">High</b> Priority
                    </DropdownItem>
                  )}

                  <DropdownDivider />
                  {position.isPaused && (
                    <DropdownItem onClick={handleTooglePause}>
                      <Icon name="play" />
                      Resume
                    </DropdownItem>
                  )}
                  {!position.isPaused && (
                    <DropdownItem onClick={handleTooglePause}>
                      <Icon name="pause" />
                      Pause
                    </DropdownItem>
                  )}

                  <RemoveOption onClick={() => setShowConfirmClose(true)}>
                    <Icon name="stop" />
                    <b>Close</b>
                  </RemoveOption>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </CardContainer>
          <PositionDetails show={showDetails} position={position} />
        </WhatCard>
      </div>

      <Confirm
        open={showConfirmClose}
        header={'Hey...Closing this position?'}
        content={
          <Modal.Content>
            <p>Are you sure you close this position:</p>

            <p className="highlight" style={{ fontSize: 17 }}>
              <b>
                {position.number} -{' '}
                {position.company ? position.company.name : '[No Company]'} -{' '}
                {position.title}
              </b>
            </p>

            <p>Are you sure?</p>
          </Modal.Content>
        }
        onCancel={() => setShowConfirmClose(false)}
        closeOnDimmerClick={true}
        size="tiny"
        onConfirm={handleClosePosition}
        confirmButton={<Button negative>Yeap...close.</Button>}
        cancelButton={<Button basic>Ugh... no, no yet.</Button>}
      />
    </React.Fragment>
  )
}

export default PositionCard
