import React, { useState } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

import { DataSelect } from '../form/form'
import RemoteValue from '../remoteValues/remoteValue'

const FilterPopup = styled.div`
  background-color: #fff;
  padding: 15px;
  border: solid 1px var(--border-grey);
  position: absolute;
  top: 35px;
  left: 0px;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgb(34 36 38 / 15%);
  width: 400px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-shrink: 1;
  z-index: 1;
`

const DataFilter = ({
  name,
  collection,
  label,
  onFilterChange,
  optionRender,
  optionOrderBy,
}) => {
  const [val, setVal] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [filter, setFilter] = useState([])

  const [disableOnBlur, setDisableOnBlur] = useState(true)

  const handleOnChange = (e, select) => {
    setFilter(select.value)
  }

  const handleApply = () => {
    setVal(filter)
    handleClose()

    const filterValue = {}
    filterValue[name] = { $in: filter }

    onFilterChange && onFilterChange(filterValue)
  }

  const handleClose = () => {
    setDisableOnBlur(true)
    setIsOpen(false)
    setFilter([])
  }

  const handleOpen = () => {
    setDisableOnBlur(true)
    setIsOpen(true)
    setFilter([])
  }

  const handleRemoveFilter = () => {
    setFilter([])
    setVal([])

    const filterValue = {}
    filterValue[name] = null

    onFilterChange && onFilterChange(filterValue)
  }

  const handleOnBlur = () => {
    if (disableOnBlur) return
    handleClose()
  }

  if (val.length === 1) {
    return (
      <Button
        size="tiny"
        color="black"
        icon
        labelPosition="right"
        onClick={handleRemoveFilter}
      >
        <Icon name="remove" />
        <b>
          <RemoteValue
            collection={collection}
            id={val[0]}
            predicate={optionRender}
          />
        </b>
      </Button>
    )
  }

  if (val.length > 0) {
    return (
      <Button
        size="tiny"
        color="black"
        icon
        labelPosition="right"
        onClick={handleRemoveFilter}
      >
        <Icon name="remove" />
        {val.map((x, i) => (
          <React.Fragment key={x}>
            {i > 0 && ', '}
            <RemoteValue
              collection={collection}
              predicate={optionRender}
              id={x}
            />
          </React.Fragment>
        ))}
      </Button>
    )
  }

  return (
    <div
      className="relative"
      onMouseEnter={() => setDisableOnBlur(true)}
      onMouseLeave={() => setDisableOnBlur(false)}
    >
      <Button
        size="tiny"
        active={isOpen}
        basic
        onClick={handleOpen}
        disabled={isOpen}
      >
        <b>{label}</b>
      </Button>

      {isOpen && (
        <FilterPopup
          onBlur={handleOnBlur}
          onMouseEnter={() => setDisableOnBlur(true)}
          onMouseLeave={() => setDisableOnBlur(false)}
        >
          <DataSelect
            name={name}
            url={collection}
            placeholder={label}
            multiple
            style={{ width: `100%`, marginRight: 10 }}
            value={filter}
            onChange={handleOnChange}
            render={optionRender}
            orderBy={optionOrderBy}
            search
            open
          />

          <Button size="tiny" basic onClick={handleClose} icon="cancel" />
          <Button
            size="tiny"
            color="black"
            onClick={handleApply}
            icon="check"
          />
        </FilterPopup>
      )}
    </div>
  )
}

export default DataFilter
