import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { Button, Icon, Modal } from 'semantic-ui-react'
import React, { useState } from 'react'
import { AxiosError } from 'axios'
import styled from 'styled-components'

import TimeoffReviewConfirmation from '../TimeoffReviewConfirmation'
import { Task, TimeoffRequest } from '../../types'
import TimeoffRequestDetails from '../feeds/TimeoffRequestDetails'
import { useContractsApi } from '../../store/mainContext'

const Title = styled.h2`
  font-weight: bold !important;
`

const Label = styled.label`
  font-weight: bold;
  margin: 20px 0 2px 0;
  display: block;
`

const Editable = styled.textarea`
  border: solid 1px var(--border-grey);
  padding: 15px;
  font-size: 17px;
  line-height: 1.4em;
  border-radius: 4px;
  overflow: auto;
  max-height: 400px;
  resize: none;
  width: 100%;
  display: block;

  &::placeholder {
    color: #999;
  }

  &:focus {
    outline: none;
    border: solid 1px var(--primary);
    box-shadow: var(--active-shadow);
  }
`

type Props = {
  taskToFullfil: Task
  onMarkAsDone(task: Task): Promise<any>
  onCancel(): void
}

const CompleteTaskModal = ({
  taskToFullfil,
  onMarkAsDone,
  onCancel,
}: Props) => {
  const contractsApi = useContractsApi()
  const [error, setError] = useState<AxiosError | null>(null)
  const [doneNote, setDoneNote] = useState('')
  const [candidateNote, setCandidateNote] = useState('')
  const [mode, setMode] = useState<'fulfill' | 'approve' | 'reject'>('fulfill')

  const [isPending, setIsPending] = useState(false)

  const handleMarkAsDone = () => {
    setIsPending(true)
    onMarkAsDone({ ...taskToFullfil, doneNote }).finally(() =>
      setIsPending(false),
    )
  }

  const handlePtoReview = (status: TimeoffRequest['status']) => {
    if (!taskToFullfil.timeOffRequestId) return
    setIsPending(true)
    contractsApi
      .patch(`timeoff-requests/${taskToFullfil.timeOffRequestId}`, {
        candidateNote,
        doneNote,
        status,
      })
      .then(() => onMarkAsDone({ ...taskToFullfil, doneNote }))
      .catch(setError)
      .finally(() => setIsPending(false))
  }

  const canMarkAsDone = doneNote.length >= 4 && !isPending

  if (!taskToFullfil) return null

  return (
    <Modal onClose={onCancel} open size="small">
      <Modal.Content>
        <Title>{mode === 'fulfill' ? 'Fullfill Task' : 'Are you sure?'}</Title>

        {mode === 'fulfill' && (
          <div>
            <Label>Task</Label>
            {taskToFullfil.subject}
          </div>
        )}

        {taskToFullfil.timeOffRequestId
          ? mode === 'fulfill' && (
              <div className="mt-4 rounded-md border border-slate-200 bg-slate-100 px-4 pb-4 pt-1">
                <TimeoffRequestDetails
                  requestId={taskToFullfil.timeOffRequestId}
                />
              </div>
            )
          : taskToFullfil.details && (
              <div>
                <Label>Details</Label>
                <div
                  dangerouslySetInnerHTML={{ __html: taskToFullfil.details }}
                />
              </div>
            )}

        {mode === 'fulfill' && (
          <div>
            <Label>Provide a note to mark this task as done:</Label>
            <Editable
              placeholder="Say something smart..."
              autoFocus
              disabled={isPending}
              onChange={(e) => setDoneNote(e.target.value)}
              autoCorrect="off"
              autoComplete="off"
              autoCapitalize="off"
              spellCheck="false"
              required
              value={doneNote}
              rows={3}
            />
          </div>
        )}

        {(mode === 'approve' || mode === 'reject') && (
          <TimeoffReviewConfirmation
            onCandidateNoteChange={setCandidateNote}
            candidateNote={candidateNote}
            doneNote={doneNote}
            task={taskToFullfil}
            mode={mode}
          />
        )}

        {error?.response && (
          <div className="my-4 rounded-md  bg-red-100 p-4 text-red-800">
            <ExclamationCircleIcon className="-mt-0.5 mr-1 inline-block h-5 w-5 align-middle" />
            {error.response.data.message}
          </div>
        )}
      </Modal.Content>

      <Modal.Actions style={{ textAlign: 'right' }}>
        {mode === 'fulfill' ? (
          <Button basic onClick={onCancel}>
            Nevermind...
          </Button>
        ) : (
          <Button basic onClick={() => setMode('fulfill')}>
            <Icon name="chevron left" style={{ marginLeft: -10 }} />
            Back
          </Button>
        )}

        {taskToFullfil.type === 'pto-review' && mode === 'fulfill' && (
          <>
            <Button
              onClick={() => setMode('approve')}
              disabled={!canMarkAsDone}
              color="green"
            >
              Approve
            </Button>
            <Button
              onClick={() => setMode('reject')}
              disabled={!canMarkAsDone}
              color="red"
            >
              Reject
            </Button>
          </>
        )}

        {mode === 'approve' && (
          <Button
            loading={isPending}
            onClick={() => handlePtoReview('Approved')}
            disabled={candidateNote.length < 4 || isPending}
            color="green"
          >
            Yes, approve the request
          </Button>
        )}

        {mode === 'reject' && (
          <Button
            loading={isPending}
            onClick={() => handlePtoReview('Rejected')}
            disabled={doneNote.length < 4 || isPending}
            color="red"
          >
            Yes, reject the request
          </Button>
        )}

        {taskToFullfil.type !== 'pto-review' && (
          <Button
            loading={isPending}
            disabled={doneNote.length < 4 || isPending}
            color="black"
            onClick={handleMarkAsDone}
          >
            <Icon name="save" /> Mark as done!
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  )
}

export default CompleteTaskModal
