import {
  Button,
  Dropdown,
  Icon,
  Input,
  InputProps,
  Modal,
} from 'semantic-ui-react'
import React, { MouseEventHandler, useEffect, useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import styled from 'styled-components'

import { useContractsApi, useTalentApi } from '../../store/mainContext'
import DataSelect from '../form/dataSelect'
import FormField from '../form/formField'
import { useUser } from '../../pages/session/hooks'
import UserSelect from '../form/userSelect'
import DateSelect from '../form/dateSelect'
import ShowIf from '../showIf'
import Check from '../form/check'
import {
  Collection,
  Company,
  Contact,
  Invoice,
  Position,
  Task,
} from '../../types'
import TimeoffRequestDetails from '../feeds/TimeoffRequestDetails'
import { currencyFormat } from '../../utils'

const Title = styled.h2`
  font-weight: bold !important;
`

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const Column = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 20px;
`

const Editable = styled(ContentEditable)`
  border: solid 1px var(--border-grey);
  padding: 15px;
  font-size: 17px;
  line-height: 1.4em;
  padding-bottom: 30px;
  border-radius: 4px;
  height: 100px;
  max-height: 100px;
  overflow: auto;

  &:focus {
    outline: none;
    border: solid 1px var(--primary);
    box-shadow: var(--active-shadow);
  }
`

interface Props {
  show: boolean
  onCancel(): void
  onCreated(t: Task): Promise<any>
  taskToEdit?: Task | null
  onEdited?(t: Partial<Task>): Promise<any>
  relatedCollection?: Collection
  relatedId?: string
}

const AddTaskModal = ({
  show,
  onCancel,
  onCreated,
  taskToEdit,
  onEdited,
  relatedCollection,
  relatedId,
}: Props) => {
  const editor = useRef<ContentEditable>(null)

  const user = useUser()
  const api = useTalentApi()
  const contractsApi = useContractsApi()

  const [task, setTask] = useState<Partial<Task>>({
    relatedCollection: 'nothing',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [editMode, setEditMode] = useState(false)

  const [isSaving] = useState(false)

  const userCanAccessInvoices = user.hasPower('invoices')

  useEffect(() => {
    if (!show) {
      return
    }

    setErrors([])

    if (!taskToEdit) {
      setEditMode(false)

      const newTask: Partial<Task> = {
        dueDate: new Date(new Date().toDateString()).getTime(),
        assignedToId: user._id,
        relatedId,
        relatedCollection,
      }

      setTask(newTask)
      return
    }

    setTask({ ...taskToEdit })
    setEditMode(true)
  }, [show, relatedCollection, relatedId, taskToEdit, user._id])

  const handleQuickActionClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    setTask({
      ...task,
      subject: e.currentTarget.innerHTML.replace('<br>', ' '),
    })

    if (editor && editor.current) {
      editor.current.el.current.focus()
    }
  }

  if (!show) {
    return null
  }

  const handleOnChange: InputProps['onChange'] = (_: any, target) => {
    const newTask = { ...task }

    if (target.name === 'dueDate') {
      newTask[target.name as 'dueDate'] = new Date(
        new Date(target.value).toLocaleDateString('en-US'),
      ).getTime()
    } else {
      newTask[target.name as keyof Task] = target.value || target.checked
    }

    if (target.value !== '') {
      setErrors(errors.filter((x) => x !== target.name))
    }

    setTask(newTask)
  }

  const handleCreate = async () => {
    setIsLoading(true)

    try {
      const { data } = await api.post<Task>(`tasks`, task)
      onCreated && (await onCreated(data))
    } catch (err: any) {
      setErrors(err.response?.data?.fields)
    }

    setIsLoading(false)
  }

  const handleEdit = async () => {
    setIsLoading(true)

    try {
      const updated = { ...task }
      delete updated._id

      await api.patch(`tasks/${task._id}`, updated)
      onEdited && (await onEdited(task))
    } catch (err: any) {
      setErrors(err.response?.data?.fields)
    }

    setIsLoading(false)
  }

  return (
    <Modal
      onClose={onCancel}
      open={true}
      size="large"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        {!editMode && <Title>Whats the next step?</Title>}
        {editMode && <Title>Edit Task</Title>}

        <div>
          {task.type !== 'pto-review' && (
            <div style={{ marginBottom: 20 }}>
              <p>Quick Actions:</p>
              <Button.Group basic style={{ width: `100%` }} disabled={isSaving}>
                <Button disabled={isSaving} onClick={handleQuickActionClick}>
                  Send
                  <br />
                  Feedback
                </Button>

                <Button disabled={isSaving} onClick={handleQuickActionClick}>
                  Reschedule
                  <br />
                  Interview
                </Button>

                <Button disabled={isSaving} onClick={handleQuickActionClick}>
                  Send
                  <br />
                  Challenge
                </Button>

                <Button disabled={isSaving} onClick={handleQuickActionClick}>
                  Prepare to
                  <br />
                  Send
                </Button>

                <Button disabled={isSaving} onClick={handleQuickActionClick}>
                  Send to
                  <br />
                  the Customer
                </Button>

                <Button disabled={isSaving} onClick={handleQuickActionClick}>
                  Schedule
                  <br />
                  with Customer
                </Button>

                <Button disabled={isSaving} onClick={handleQuickActionClick}>
                  Decide
                  <br />
                  what to do
                </Button>

                <Button disabled={isSaving} onClick={handleQuickActionClick}>
                  Follow
                  <br />
                  up
                </Button>
              </Button.Group>
            </div>
          )}
          <FormField
            label="Subject *"
            help="Specify an action, something to do."
            error={errors.indexOf('subject') !== -1}
          >
            <Input
              fluid
              error={errors.indexOf('subject') !== -1}
              name="subject"
              autoFocus
              onChange={handleOnChange}
              placeholder="Task Subject..."
              value={task.subject || ''}
            />
          </FormField>

          {task.timeOffRequestId ? (
            <div className="rounded-md border border-slate-200 bg-slate-100 px-4 pb-4 pt-1">
              <TimeoffRequestDetails requestId={task.timeOffRequestId} />
            </div>
          ) : (
            <FormField
              label="Details *"
              error={errors.indexOf('details') !== -1}
            >
              <Editable
                onChange={(e) =>
                  setTask({ ...task, details: e.target.value.trim() })
                }
                html={task.details || ''}
                ref={editor}
              />
            </FormField>
          )}

          <ShowIf if={!relatedId}>
            <div style={{ marginTop: 15, marginBottom: 5 }}>
              Related To{' '}
              <Dropdown
                value={task.relatedCollection}
                inline
                disabled={task.type === 'pto-review'}
                options={[
                  { key: 'nothing', value: 'nothing', text: 'Nothing' },
                  {
                    key: 'candidates',
                    value: 'candidates',
                    text: 'a Candidate',
                  },
                  { key: 'contacts', value: 'contacts', text: 'a Contact' },
                  { key: 'companies', value: 'companies', text: 'a Company' },
                  {
                    key: 'positions',
                    value: 'positions',
                    text: 'a Position',
                  },
                ].concat(
                  userCanAccessInvoices
                    ? { key: 'invoices', value: 'invoices', text: 'an Invoice' }
                    : [],
                )}
                onChange={(e, t) =>
                  setTask({ ...task, relatedCollection: t.value as Collection })
                }
              ></Dropdown>
            </div>

            <FormField>
              {task.relatedCollection === 'candidates' && (
                <DataSelect
                  name="relatedId"
                  search
                  placeholder="Candidate..."
                  url="candidates"
                  fluid
                  onChange={handleOnChange}
                  render={(x) => `${x.name} (${x.email})`}
                  value={task.relatedId}
                  disabled={task.type === 'pto-review'}
                />
              )}

              {task.relatedCollection === 'contacts' && (
                <DataSelect
                  name="relatedId"
                  search
                  placeholder="Contact..."
                  url="contacts"
                  fluid
                  onChange={handleOnChange}
                  render={(x: Contact) => `${x.name} (${x.email})`}
                  value={task.relatedId}
                />
              )}

              {task.relatedCollection === 'companies' && (
                <DataSelect
                  name="relatedId"
                  search
                  placeholder="Company..."
                  url="companies"
                  fluid
                  onChange={handleOnChange}
                  render={(x: Company) => x.name}
                  value={task.relatedId}
                />
              )}

              {task.relatedCollection === 'positions' && (
                <DataSelect
                  name="relatedId"
                  search
                  placeholder="Position..."
                  url="positions"
                  fluid
                  onChange={handleOnChange}
                  render={(x: Position) => `${x.number} - ${x.title}`}
                  value={task.relatedId}
                  filter={{ status: 'open' }}
                />
              )}

              {task.relatedCollection === 'invoices' &&
                userCanAccessInvoices && (
                  <DataSelect
                    name="relatedId"
                    search
                    placeholder="Invoice..."
                    url="invoices/pro"
                    fluid
                    onChange={handleOnChange}
                    render={(x: Invoice) =>
                      `${x.number} (${currencyFormat(x.amount || 0)})`
                    }
                    value={task.relatedId}
                    injectedApi={contractsApi}
                    // filter={{ status: 'open' }}
                  />
                )}
            </FormField>
          </ShowIf>

          <Container>
            <Column>
              <FormField
                label="Assigned To *"
                error={errors.indexOf('assignedToId') !== -1}
              >
                <UserSelect
                  name="assignedToId"
                  search
                  placeholder="Assigned To..."
                  fluid
                  onChange={(userId) =>
                    setTask((t) => ({ ...t, assignedToId: userId }))
                  }
                  value={task.assignedToId}
                  error={errors.indexOf('assignedToId') !== -1}
                />
              </FormField>
            </Column>

            <Column>
              <FormField
                label="Due Date *"
                error={errors.indexOf('dueDate') !== -1}
              >
                <DateSelect
                  clearOnSameDateClick={false} // required or would send `null` when clicking same day
                  error={errors.indexOf('dueDate') !== -1}
                  name="dueDate"
                  pointing="top left"
                  fluid
                  onChange={handleOnChange}
                  value={task.dueDate && new Date(task.dueDate)}
                />
              </FormField>
            </Column>

            <Column>
              {taskToEdit?.type !== 'pto-review' && (
                <Check
                  text="Requires fulfillment note"
                  value={task.requireDoneNote}
                  onChange={() =>
                    setTask({ ...task, requireDoneNote: !task.requireDoneNote })
                  }
                />
              )}
            </Column>
          </Container>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={onCancel} disabled={isLoading}>
          Nevermind...
        </Button>
        {!editMode && (
          <Button
            color="black"
            onClick={handleCreate}
            loading={isLoading}
            disabled={isLoading}
          >
            <Icon name="save" /> Let&apos;s do it!
          </Button>
        )}
        {editMode && (
          <Button
            color="black"
            onClick={handleEdit}
            loading={isLoading}
            disabled={isLoading}
          >
            <Icon name="save" /> Save Changes!
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  )
}

export default AddTaskModal
