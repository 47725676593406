import React from 'react'
import { Droppable } from 'react-beautiful-dnd'

const Column = ({ id, title, children }) => {
  const getStyle = (isDragging) => {
    if (!isDragging) {
      return
    }

    return {
      backgroundColor: '#fff',
    }
  }

  return (
    <div className="fx fn-step">
      <div>
        <h2>{title}</h2>
      </div>

      <Droppable key={id} droppableId={id}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getStyle(snapshot.isDraggingOver)}
            {...provided.droppableProps}
          >
            {children}
          </div>
        )}
      </Droppable>
    </div>
  )
}

export default Column
