import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { useTalentApi } from '../../store/mainContext'

import PageLoader from '../../components/pageLoader'
import { Button, Confirm, Icon } from 'semantic-ui-react'
import { useHistory, useParams } from 'react-router'
import Editor from '@monaco-editor/react'

import ConfirmModal from '../../components/modals/confirmModal'
import RequirePowers from '../../components/requirePowers'

const Header = styled.div`
  display: flex;
  padding: 15px;
  align-content: center;
  justify-content: space-between;

  background-color: #fff;
`

const CodeEditor = styled(Editor)`
  border: solid 1px var(--border-grey);
  border-radius: 4px;

  &:focus {
    border-color: red !important;
  }
`

const FunnelEdit = () => {
  const history = useHistory()

  const { funnelName } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [funnel, setFunnel] = useState()

  const [value, setValue] = useState('')

  const [showConfirm, setShowConfirm] = useState(false)

  const [creating, setCreating] = useState(false)

  const api = useTalentApi()

  useEffect(() => {
    setIsLoading(true)
    api
      .get('/funnels', { params: { name: funnelName } })
      .then(({ data }) => {
        const funnelConfig = data[0]
        if (!funnelConfig) return

        setFunnel(funnelConfig)

        const newVal = { ...funnelConfig }
        delete newVal._id
        delete newVal.lastUpdateBy
        delete newVal.lastUpdateOn
        delete newVal.name
        delete newVal.cardType
        setValue(JSON.stringify(newVal, null, 2))
      })
      .catch((e) => console.warn(e))
      .finally(() => setIsLoading(false))
  }, [api, funnelName])

  const handleCreate = async () => {
    setCreating(true)
    await api.post('/funnels', {
      order: 99,
      name: funnelName,
      title: funnelName,
      sub: 'Say something...',
      collection: 'candidates',
      cardType: 'candidates',
      columns: [
        {
          id: `${funnelName}.dealIn`,
          title: 'Deal In',
          rotting: 5,
        },
      ],
    })

    resetStorage()

    document.location.reload()
  }

  const resetStorage = () => {
    const token = window.localStorage.getItem('token')
    const user = window.localStorage.getItem('user')

    window.localStorage.clear()

    window.localStorage.setItem('token', token)
    window.localStorage.setItem('user', user)
  }

  const handleSave = async () => {
    let newValue

    try {
      newValue = JSON.parse(value)

      resetStorage()
    } catch (e) {
      alert('Error parsing the JSON!')
      return
    }

    delete newValue._id
    await api.patch(`funnels/${funnel._id}`, newValue)

    history.goBack()
  }

  if (isLoading) {
    return <PageLoader />
  }

  if (!funnel) {
    return (
      <div style={{ padding: `25px 25px 0px 25px` }}>
        <p>
          Funnel <b>{funnelName}</b> does not exits.{' '}
        </p>

        <RequirePowers>
          <p>
            Click{' '}
            <Button
              loading={creating}
              size="tiny"
              color="green"
              onClick={handleCreate}
            >
              Here
            </Button>{' '}
            to create it.
          </p>
        </RequirePowers>
      </div>
    )
  }

  return (
    <React.Fragment>
      <Header>
        <Button basic onClick={() => history.goBack()}>
          <Icon name="angle left" /> Cancel
        </Button>

        <Button color="black" onClick={() => setShowConfirm(true)}>
          <Icon name="save" /> Save
        </Button>
      </Header>

      <CodeEditor
        defaultLanguage="json"
        height="calc(100vh - 66px)"
        theme="vs-dark"
        // scrollbarVisibility={1}
        options={{
          // minimap:{ enabled: false},
          lineNumbers: 'off',
          padding: { top: 10 },
          wordWrap: 'on',
          fontSize: 16,
          // readOnly: isSaving
        }}
        value={value}
        onMount={(editor) => {
          setTimeout(() => {
            editor.getAction('editor.action.formatDocument').run()
          }, 10)
        }}
        onChange={(code) => setValue(code)}
      />

      <ConfirmModal
        show={showConfirm}
        question={`You are about to modify ${funnel.title} funnel.`}
        onCancel={() => {
          setShowConfirm(false)
        }}
        onConfirm={handleSave}
      />

      <Confirm show={showConfirm} />
    </React.Fragment>
  )
}

export default FunnelEdit
