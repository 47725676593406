import React, { ReactNode } from 'react'
import { Placeholder } from 'semantic-ui-react'
import useSWR from 'swr'

import { useTalentApi } from '../../store/mainContext'
import { Collection } from '../../types'

interface Props {
  showLoading?: boolean
  collection?: Collection
  predicate?: (input: any) => ReactNode
  id?: string
}

const RemoteValue = ({ collection, id, showLoading, predicate }: Props) => {
  const api = useTalentApi()

  const { data, isLoading } = useSWR(['remote', collection, id], () => {
    return collection && id
      ? api.get(`${collection}/${id}`).then((res) => res.data)
      : null
  })

  predicate = predicate || ((x) => x.text)

  if (isLoading && showLoading) {
    return (
      <Placeholder className="-mt-1.5">
        <Placeholder.Line length="long" className="m-0" />
      </Placeholder>
    )
  }

  if (!data) return null

  return <React.Fragment>{predicate(data)}</React.Fragment>
}

export default RemoteValue
