import React, { useState } from 'react'

import { Candidate, Contract, Deal } from '../../types'
import { Divider, Section } from '../sidepanel/sidePanel'
import ContractSubsections from './contract'
import AddContractModal from '../modals/addContractModal'
import ContractsHistory from './contractsHistory'
import DealSubsections from './deal'
import SideAddButton from '../sidepanel/sideAddButton'
import ContractsMenu from './ContractsMenu'
import RequirePowers from '../requirePowers'
import useContracts from '../../hooks/useContracts'
import { useUser } from '../../pages/session/hooks'
import useDeals from '../../hooks/useDeals'
import useSow from '../../hooks/useSow'
import POWERS from '../../powers'

interface Props {
  onSelectedContractChange(newContract: Contract): void
  selectedContract: Contract | null
  candidate: Candidate
}

const Contracts = (props: Props) => {
  const { onSelectedContractChange, selectedContract, candidate } = props

  const [showAddContractModal, setShowAddContractModal] = useState(false)
  const [selectedDeal, setSelectedDeal] = useState<Deal>()
  const [showHistory, setShowHistory] = useState(false)

  const user = useUser()

  const { data: allContracts, isLoading, mutate } = useContracts(candidate._id)
  const { data: allDeals, mutate: mutateDeals } = useDeals(allContracts)

  const { data: selectedSow } = useSow(
    user.hasPower(POWERS.customerSOWs)
      ? selectedDeal?.customerSOWId
      : undefined,
  )

  React.useEffect(() => {
    const selContract =
      allContracts?.find((c) => !c.endDate) || allContracts?.[0]
    if (selContract) {
      onSelectedContractChange(selContract)

      const selDeal = allDeals?.filter(
        (d) => d.contractId === selContract._id,
      )?.[0]
      if (selDeal) setSelectedDeal(selDeal)
    }
  }, [allContracts, allDeals, onSelectedContractChange])

  const handleContractChanged = (name: keyof Contract, value: any) => {
    if (!selectedContract) return
    onSelectedContractChange({ ...selectedContract, [name]: value })
  }

  // TODO: Refactor updates triggered from lower-level componentes
  const refresh = () => Promise.all([mutate(), mutateDeals()])

  const handleContractAdded = () => {
    setShowAddContractModal(false)
    refresh()
  }

  if (isLoading) {
    return null
  }

  if (allContracts?.length === 0) {
    return (
      <RequirePowers powers={[POWERS.createContract]}>
        <SideAddButton onClick={() => setShowAddContractModal(true)}>
          Add Contract 2.0
        </SideAddButton>
        <AddContractModal
          show={showAddContractModal}
          candidate={candidate}
          onCancel={() => setShowAddContractModal(false)}
          onSuccess={handleContractAdded}
        />
      </RequirePowers>
    )
  }

  if (!selectedContract || !selectedDeal) {
    return null
  }

  if (showHistory) {
    return (
      <ContractsHistory
        selectedDeal={selectedDeal}
        contracts={allContracts}
        deals={allDeals}
        onGoBack={() => setShowHistory(false)}
        onSelect={({ contract, deal }: { contract: Contract; deal: Deal }) => {
          onSelectedContractChange(contract)
          setSelectedDeal(deal)
          setShowHistory(false)
        }}
      />
    )
  }

  return (
    <Section
      icon="handshake outline"
      title="Contract Information"
      menu={
        <ContractsMenu
          candidate={candidate}
          contracts={allContracts || []}
          selectedContract={selectedContract}
          onShowHistory={() => setShowHistory(true)}
          onDealUpdated={refresh}
          onContractAdded={refresh}
          onContractTerminated={refresh}
          onContractRemoved={refresh}
        />
      }
    >
      <DealSubsections
        onDealChanged={refresh}
        onCustomerSOWChanged={refresh}
        deal={selectedDeal}
        key={selectedDeal._id}
        customerSOW={selectedSow}
      />

      <Divider />

      <ContractSubsections
        candidate={candidate}
        contract={selectedContract}
        onContractChanged={handleContractChanged}
      />
    </Section>
  )
}

export default Contracts
